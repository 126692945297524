import { useState, useEffect, FC, useMemo } from 'react';
import { useRouter } from 'next/router';
import cssClass from 'classnames';

import { Image } from '../../atoms';
import { findSavedProperty, getRenditionPath, handleClickHotelDetails } from './helper';
import { BrandedHotelHeaderProps } from './HotelHeaderV2.types';
import { StyledSection } from './HotelHeaderV2.styles';

import {
  addSubDirectoryPrefix,
  createImageSizes,
  getHotelDetailURL,
  getHotelReviewURL,
  getViewPortValue,
  getWindowSession,
  isBulgariFlowFn,
  useHotelMapUrl,
} from '../../utils';
import { PropertyRating } from '../../molecules';

import { constants, TRACKING_CONSTANT, URL_CONSTANTS, CUSTOM_EVENTS_CONSTANTS } from '../../constants';

const { ON_SHOW_HOTEL_DETAIL } = CUSTOM_EVENTS_CONSTANTS;
const { TARGET_BLANK, HOTEL_DETAILS_ID, CN_RATING_TOOLTIP, TRUE } = constants;
const { PROPERTY_URL, SIGNIN_URL, RLM_URL, CACHE_MARRIOTT_URL } = URL_CONSTANTS;
const {
  HEADER_TILE,
  HEADER_PROPERTY_NAME,
  HEADER_HOTEL_INFO,
  HEADER_ADDRESS_LINK,
  HEADER_PHONE_NO,
  HEADER_RATINGS,
  INTERNAL_LINK,
} = TRACKING_CONSTANT;

export const BrandedHotelHeader: FC<BrandedHotelHeaderProps> = props => {
  const {
    componentId,
    seoNickname,
    hotelName,
    fileReference,
    latitude,
    longitude,
    currency,
    hotelAddress,
    phone,
    showReviews,
    stars,
    hotelReviews,
    reviewsLabel,
    isLoading,
    hotelDetailsLabel,
    savedLabel,
    saveLabel,
    propertyId,
    userData,
    brandCode,
    showSaveProperty,
    handleClick,
    phoneText,
    starsOutOfText,
    uniqueHotelLogo,
    reviewUrl,
    isRlmOrPostBookingPage,
    isMax,
    disableHotelHeaderAndReviewLinks,
    disableHotelReviewLinks,
    locale,
    disableReviews,
    city = '',
    dacHotelLogo,
    isDACLogo,
    propertyCodes,
  } = props;
  // update logo url if dacLogo or ctsLogo/aemBrandlogo
  const hotelLogo = isDACLogo ? dacHotelLogo : uniqueHotelLogo;
  const isBulgariFlow = isBulgariFlowFn();
  const { HIGH } = constants;
  const router = useRouter();
  const [hotelSave, setHotelSave] = useState({ addClass: 'icon-heart-outline', showSavedLabel: false });
  const hotelMapUrl = useHotelMapUrl({ locale, latitude, longitude, currency, hotelAddress, hotelName });

  const isDesktopView = getViewPortValue(constants.BREAKPOINT_DESKTOP - 1) === constants.DESKTOP;
  const sessionObject = getWindowSession();
  const isAuthenticated = sessionObject?.authenticated;
  let hotelReviewUrl = getHotelReviewURL({ brandCode, locale, marshaCode: propertyId }); //Property specific logic
  hotelReviewUrl =
    hotelReviewUrl ||
    addSubDirectoryPrefix(reviewUrl && seoNickname ? reviewUrl.replace('{property-name}', seoNickname) : ''); //Generic logic for all properties

  let hotelDetailUrl = useMemo(
    () =>
      getHotelDetailURL({
        brandCode,
        locale,
        marshaCode: propertyId,
        location: city?.toLocaleLowerCase(),
        isbulgariFlow: isBulgariFlow,
        bulgariPropertyCodes: propertyCodes?.bulgariPropertyCodes,
      }), //Property specific logic
    [brandCode, locale, propertyId, city]
  );
  const hotelDetailURLHQV = hotelDetailUrl;

  hotelDetailUrl = hotelDetailUrl || addSubDirectoryPrefix(`${PROPERTY_URL}${seoNickname}`); //Generic logic for all properties

  /*Generating css clasname from clasname module*/
  const sectionClassName = cssClass('container t-background-color', { hide: isLoading });
  const reviewsClassName = cssClass(
    't-font-weight-r icon-star-fill icon-height t-foreground-color custom_click_track',
    { 'mr-4 mr-lg-3': isRlmOrPostBookingPage && showSaveProperty },
    {
      ' disableLinks': disableHotelReviewLinks,
    }
  );
  const hotelLogoClassName = cssClass('hotel-logo', { 'dac-logo': isDACLogo });

  const propertyDetailsClassName = cssClass('custom_click_track', {
    ' disableLinks': disableHotelHeaderAndReviewLinks,
  });

  const disableHotelLogoClassName = cssClass('t-font-weight-r d-none d-lg-inline-block logo-wrapper', {
    ' disableLinks': disableHotelHeaderAndReviewLinks,
  });

  const seoPropertyUrl = disableHotelHeaderAndReviewLinks ? '' : addSubDirectoryPrefix(hotelDetailUrl);
  const propertyReviewUrl = disableHotelReviewLinks ? '' : addSubDirectoryPrefix(hotelReviewUrl);

  useEffect(() => {
    const saveProperty = router?.query?.['saveProperty'];
    if (saveProperty && userData?.savedProperties) {
      handleSaveProperty({}, saveProperty === TRUE);
    }
  }, [userData]);

  useEffect(() => {
    if (isRlmOrPostBookingPage && userData?.savedProperties?.length) {
      const { savedProperties } = userData;
      if (findSavedProperty(propertyId, savedProperties) > -1) {
        setHotelSave({ ...hotelSave, addClass: 'icon-heart-fill', showSavedLabel: true });
      } else {
        setHotelSave({ ...hotelSave, addClass: 'icon-heart-outline', showSavedLabel: false });
      }
    }
  }, [userData?.savedProperties]);

  const imageSize = createImageSizes(fileReference?.renditions);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSaveProperty = async (event: any, saveProperty?: boolean) => {
    if (isAuthenticated && userData?.savedProperties) {
      event?.preventDefault?.();
      const propertyList = userData.savedProperties.map(property => ({
        id: property['id'],
      }));
      handleClick(propertyList, setHotelSave, hotelSave, saveProperty);
    }
  };

  const getReturnUrl = () => {
    const currentUrl = new URL(window.location.href); // Create a new URL object
    currentUrl.searchParams.set('saveProperty', `${!hotelSave.showSavedLabel}`);
    return encodeURIComponent(currentUrl.href);
  };

  return (
    <StyledSection className={sectionClassName} data-component-name={componentId}>
      {isDesktopView && (
        <a href={seoPropertyUrl} aria-label={hotelName} className={disableHotelLogoClassName} target={TARGET_BLANK}>
          {hotelLogo ? (
            <Image
              url={hotelLogo}
              title={hotelName}
              appliedClass={hotelLogoClassName}
              fetchpriority={HIGH}
              isLazy={!isDACLogo}
            />
          ) : (
            <Image
              url={`${fileReference?.dynamic ? CACHE_MARRIOTT_URL : ''}${fileReference?.damPath}`}
              title={hotelName}
              size={imageSize}
              renditions={getRenditionPath(fileReference?.renditions, fileReference?.dynamic)}
              dynamic={fileReference?.dynamic}
              appliedClass={hotelLogoClassName}
              fetchpriority={HIGH}
            />
          )}
        </a>
      )}
      <div className="property-details">
        <a
          href={seoPropertyUrl}
          target={TARGET_BLANK}
          className={propertyDetailsClassName}
          data-custom_click_track_value={`${HEADER_TILE}|${HEADER_PROPERTY_NAME}|${INTERNAL_LINK}`}
        >
          <h3 className="hotel-name mb-3">{hotelName}</h3>
        </a>
        {hotelName && (
          <>
            {isRlmOrPostBookingPage && !isBulgariFlow && (
              <a
                href="#"
                className="mr-4 mr-lg-3 icon-city icon-height t-font-weight-r t-foreground-color hqvLink custom_click_track"
                data-custom_click_track_value={`${HEADER_TILE}|${HEADER_HOTEL_INFO}|${INTERNAL_LINK}`}
                title={hotelName}
                onClick={e =>
                  handleClickHotelDetails({
                    e: e,
                    id: HOTEL_DETAILS_ID,
                    key: ON_SHOW_HOTEL_DETAIL,
                    hotelReviewUrl: hotelReviewUrl,
                    hotelDetailUrl: hotelDetailURLHQV,
                    disableReviews: disableReviews,
                  })
                }
              >
                <span className="ml-1 d-none d-lg-inline-block option-label">{hotelDetailsLabel}</span>
              </a>
            )}
            <a
              className="mr-4 mr-lg-3 t-font-weight-r icon-location icon-height t-foreground-color custom_click_track"
              target={TARGET_BLANK}
              rel="noopener noreferrer"
              href={hotelMapUrl}
              aria-label={hotelAddress}
              data-custom_click_track_value={`${HEADER_TILE}|${HEADER_ADDRESS_LINK}|${INTERNAL_LINK}`}
            >
              <p className="ml-1 d-none d-lg-inline-block mb-0 address">{hotelAddress}</p>
            </a>
            {phone && (
              <a
                href={`tel:${phone}`}
                aria-label={phoneText}
                className="mr-4 d-lg-none icon-phone icon-height t-foreground-color custom_click_track"
                data-custom_click_track_value={`${HEADER_TILE}|${HEADER_PHONE_NO}|${INTERNAL_LINK}`}
              >
                <span className="sr-only">{phone}</span>
              </a>
            )}
            {phone && (
              <div className="d-none d-lg-inline-block icon-phone icon-height t-foreground-color">
                <span dir="ltr" className="mr-3 ml-1 phone">
                  {phone}
                </span>
              </div>
            )}
            {showReviews && !isMax && !disableReviews ? (
              <PropertyRating
                locale={locale}
                className={reviewsClassName}
                clickTrackValue={`${HEADER_TILE}|${HEADER_RATINGS}|${INTERNAL_LINK}`}
                propertyReviewUrl={propertyReviewUrl}
                reviewsText={reviewsLabel}
                propertyReviews={hotelReviews}
                propertyRatingStars={stars}
                starsOutOfText={starsOutOfText}
                tooltipText={CN_RATING_TOOLTIP}
              />
            ) : null}
            {isRlmOrPostBookingPage && showSaveProperty && (
              <a
                className={`d-inline-block icon-height t-font-weight-r t-foreground-color ${hotelSave.addClass}`}
                href={`${addSubDirectoryPrefix(SIGNIN_URL)}?returnTo=${getReturnUrl()}`}
                onClick={handleSaveProperty}
                data-testid="save-property"
                aria-label={hotelSave.showSavedLabel ? savedLabel : saveLabel}
              >
                <span className="ml-1 d-none d-lg-inline-block option-label">
                  {hotelSave.showSavedLabel ? savedLabel : saveLabel}
                </span>
              </a>
            )}
          </>
        )}
      </div>
    </StyledSection>
  );
};

// This file manages exports from the base atom, molecule, or organism.
import { EditableComponent, EditableComponentProps } from '@adobe/aem-react-editable-components';

// Use named rather than default exports.
import { AccountStaticWrapper } from './AccountStaticWrapper';
import { AccountStaticWrapperProps } from './AccountStaticWrapper.types';

export const AccountStaticWrapperConfig = {
  emptyLabel: 'accountwrapper',
  isEmpty: () => true,
  resourceType: `mi-aem-account/components/content/accountwrapper`,
};

type editableCompAccountStaticWrapperProps = EditableComponentProps & AccountStaticWrapperProps;

export const AccountStaticWrapperEditable = (props: editableCompAccountStaticWrapperProps) => (
  <EditableComponent config={AccountStaticWrapperConfig} {...props}>
    <AccountStaticWrapper {...props} />
  </EditableComponent>
);

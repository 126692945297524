export const constants = {
  ORDER_TYPE: 'order',
  CART_TYPE: 'cart',
  COUNT: 'count',
  DEFAULT: 'default',
  DEFAULT_COUNTRY: 'US',
  DEFAULT_CURRENCY: 'USD',
  COUNTRY_CODE_USA: 'USA',
  DEFAULT_LANGUAGE: 'en-US',
  TRADITIONAL_CHINESE_LOCALE: 'zh_TW',
  INDONESIA_DEFAULT_LOCALE: 'in_ID',
  INDONESIA_LOCALE: 'id-ID',
  IS_LOCALE_THAI: 'th',
  IS_LOCALE_ARABIC: 'ar',
  IS_LOCALE_HEBREW: 'he',
  IS_LOCALE_VIETNAMESE: 'vi',
  BUDDHIST_YEAR_VALUE: 543,
  // OTP validation modal constants
  INITIAL_TIME: 60,
  CANCEL_OTP: 'cancelOTP',

  /* The key that would be saved in the session storage
    for storing the rewards number of CN inline enrollment */
  CN_INLINE_ENROLLMENT_REWARDS_NUMBER: 'cnInlineEnrollmentRewardsNumber',

  /* The key that would be saved in the session storage
    for the potential error triggered by calling /cnInlineEnrollment */
  CN_ENROLLMENT_ERROR_MESSAGE: 'cnEnrollmentErrorMessage',

  OTP_VALIDATION_CANCEL_ID: 'showOTPValidationCancelModal',
  OTP_VALIDATION_ID: 'showOTPValidationModal',
  OTP_VALIDATION_SUCCESSFUL_ID: 'showOTPValidationSuccessfulModal',
  PASSWORD: 'password',
  TEXT: 'text',
  // Quert Fall back constants
  DEFAULT_FALLBACK_START_DATE: '2023-01-15',
  DEFAULT_FALLBACK_END_DATE: '2023-01-20',
  DEFAULT_FALLBACK_LATITUDE: 40.752132,
  DEFAULT_FALLBACK_LONGITUDE: -73.981665,
  DOWNSIZE_PARAM: '?output-quality=70&interpolation=progressive-bilinear&downsize=',
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
  IMAGE_SIZE: {
    desktop: '1440:480',
    tablet: '768:*',
    mobile: '*:*',
  },
  VIEWPORT_SIZE: {
    desktop: '1200px',
    tablet: '768px',
    mobile: '576px',
  },
  HOTEL_IMAGE_SIZE: {
    desktop: '459:258',
    tablet: '285:285',
    mobile: '*:187',
  },
  CLASS_IDENTIFIER: 'mobile-height',
  SNA_STATUS: 'alertSNA',
  CREDIT_CARD_NUMBER: 'creditCardNumber',
  //member enrollment status
  AA_VALID_MEMBER: 'ValidAaaMemberNumber',
  AAA_VALID_MEMBER: 'ValidAaaMemberNumber',
  MEMBER_ENROLLMENT_ERROR: 'memberEnrollmentError',
  MEMBER_ENROLLMENT: 'memberEnrollment',
  ENROLLMENT_SUCCESS: 'enrollmentSuccess',
  ENROLLMENT_FAILURE: 'enrollmentFailure',
  DUPLICATE_EMAIL: 'duplicateEmail',
  DUPLICATE_PHONE: 'duplicatePhone',
  MATCH_KEY_ERROR: 'matchKeyError',
  MATCH_KEY: 'mrprogram.matchkey.error',
  MATCH_KEY_ERR_MSG: 'conf.res.link.failure.enroll.matchkeyerror.msg',
  SUCCESS: 'success',
  ACTIVE: 'active',
  STATUS: 'status',
  BOOKING_ERROR: 'bookingError',
  GRAPHQL_ERROR: 'graphQlError',
  REDEMPTION_ERROR: 'redemptionerror',
  SELECT_ERROR: 'selectError',
  ADULT_ONLY_ERROR: 'isadultonlyerror',
  MAX_GUEST_COUNT_ERROR: 'guestCountError',
  RESTRICT_MODIFICATION: 'restrictModification',
  AVAILIBILITY_ERROR: 'availabilityError',
  PREARRIVAL_VERIFICATION: 'prearrivalVerification',
  WARNING: 'warning',
  PROFILE_NOT_FOUND_CODES: ['profileNotFoundInMarshaErrorText', 'userErrorCode'],
  PROFILE_NOT_FOUND: 'profileNotFound',
  GUEST_ROOM_FIELD: 'rooms.guests',
  UNAVAILABLE_ROOM_ERRORS: ['427', 'dates', 'queries.start-date'],
  ERROR: 'ERROR',
  SNA_TYPE: 'sna',
  SNA_STATUS_CODE_REQUEST: 'requested',
  SNA_STATUS_CODE_GUARANTEED: 'guaranteed',
  SNA_STATUS_CODE_PENDING: 'pending',
  SNA_STATUS_CODE_DENIED: 'denied',
  SNA_STATUS_CODE_REMOVED: 'removed',
  SNA_STATUS_CODE_FORFEITED: 'forfeited',
  SNA_STATUS_CODE_CANCELLED: 'cancelled',
  SNA_STATUS_CODE: 'statusCode',
  SNA_CANCEL_SUCCESS_IDENTIFIER: 'cancelSNASuccessIdentifier',
  ROOM_PREF_GUARANTABLE: 'guarantable',
  ROOM_UPGRADE_TYPE: 'roomUpgrade',
  ROOM_DETAILS_TYPE: 'room_details',
  ROOM_IMAGES_TYPE: 'room_images',
  ROOM_UPGRADE_DETAILS_ID: 'roomUpgradeDetails',
  RATE_DETAILS_ID: 'showRateDetailModal',
  RESORT_FEE_MODAL: 'showResortFeeModal',
  ROOM_DETAILS_ID: 'showRoomDetailModal',
  CONFIRM_CHANGES_MODAL: 'showConfirmChangesModal',
  CLOSE_RLM_MODAL: 'closeRLMModal',
  CLOSE_ROOM_RATE_DETAILS_MODAL: 'closeRoomRateDetailsModal',
  HOTEL_DETAILS_ID: 'showHotelDetailModal',
  SESSION_TIMEOUT_ID: 'showSessionTimeOutModal',
  IS_SESSION_TIMEOUT: 'isSessionTimeout',
  ROOM_POOL_CODE: 'roomPoolCode',
  CHECKED_IN_HOUSE: 'CHECKED_IN_HOUSE',
  PRE_ARRIVAL: 'PRE_ARRIVAL',
  RATE_CODE: 'rateCode',
  ROOM_NAME: 'roomName',
  ITEM_RATE_NAME: 'itemRateName',
  SELECTED_TAB: 'selectedTab',
  CURRENTLY_SELECTED_TAB: 'currentlySelectedTab',
  SHOW_TAXES_AND_FEES: 'showTaxesAndFees',
  SHOW_TAXES_AND_FEES_CHECKED: 'checked',
  SHOW_TAXES_AND_FEES_UNCHECKED: 'unchecked',
  PAGE_TYPE: 'pageType',
  SUPPRESS_OCJ_ON_ACTION: 'suppressOCJFromReloading',
  SEARCH_RESULT_POSITION: 'search_result_position_selected',
  SNA_CUTOFF_HOUR: 14,
  SELECTED_ROOM_IMAGES: 'selected_room_images',
  DUPLICATE_EMAIL_ERROR: 'email.duplicate.marriott.account.already.exists',
  RESERVATION_NOT_FOUND: 'reservation.not.found',
  INVALID_CONFO_NUMBER: 'confirmation.number.format.invalid',
  DUPLICATE_PHONE_ERROR: 'phone.duplicate.marriott.account.already.exists',
  DUPLICATE_EMAIL_PHONE_ERROR: 'email.phone.duplicate.marriott.account.already.exists',
  NAME_REVERSE_COUNTRY_LIST: [null],
  TI_LINK_ARIA_LABEL: 'Allianz Travel Insurance',
  MEMBER_CARD: 'memberCard',
  CHECK_IN_ELIGIBLE: 'checkInEligible',
  CHECK_IN_MODIFIABLE: 'checkInModifiable',
  CHECK_OUT_ELIGIBLE: 'checkOutEligible',
  CITY_NAME: 'cityname',
  PHYSICAL_CHECKED_IN: 'PhysicalCheckedIn',
  OPTION_WIDGET_MENU: 'Option Widget Menu',
  ENV_DEV: 'development',
  OCJ_MODAL: 'ocj-modal',
  IMG_ALT_TEXT: 'Title',
  IFRAME: 'IFRAME',
  SLIDE_PREV_BUTTON_LABEL: 'Previous slide',
  SLIDE_NEXT_BUTTON_LABEL: 'Next slide',
  PARTNER_SITES: ['/ritz', '/bulgari'],
  PARAM_ANOTHER_ROOM: 'isRepeatBooking=true',
  BONVOY_BANNER: 'bonvoyBanner',
  MINICARD: 'miniCard',
  RESCONFO_TI_BANNER: 'resConfoTIBanner',
  REDEMPTION_ALERT: 'redemptionAlert',
  COBRAND_NON_POINTS_MATH: 'cobrandNonPointsMath',
  CLIENT_ID: 'mcom_rand',
  GUEST_ROOMS_CATEGORY: 'guest-rooms',
  HOTEL_VIEW_CATEGORY: 'hotel-view',
  SUITES_CATEGORY: 'suites',
  SERVICES_CATEGORY: 'services',
  SNA_ROOM_CATEGORIES: ['HOTEL_VIEW', 'GUEST_ROOMS', 'SUITES', 'SERVICES'],
  ASPECT_RATIO_CLASSIC: 'CLASSIC',
  ASPECT_RATIO_WIDE: 'WIDE',
  FIRST_NIGHT_PER_UNIT_RATE_MODE: 'FIRST_NIGHT_PER_UNIT',
  SUITE_NIGHT: 'suite-night',
  SUITE_NIGHT_AWARD: 'Suite Night Award',
  SNA_REQUEST_STATUS: 'On Request',
  SNA_CODE: 'N2',
  PREFEREENCE_REQUEST_STATUS: 'request-only,requested,on request,noted',
  PREFEREENCE_DENIED_STATUS: 'denied,not offered,invalid-date',
  PREFEREENCE_GUARANTEED_STATUS: 'guaranteed,guaranteeable',
  HEADER_GQL_OP_Name: 'graphql-operation-name',
  HEADER_GQL_OP_Signature: 'graphql-operation-signature',
  MOBILE_NUMBER: 'mobile-number',
  COUNTRY_CODE: 'countryCode',
  US: 'United States (1)',
  ENGLISH: 'English',
  DEFAULT_DIALING_CODE: '1',
  USA: 'USA (1)',
  LANGUAGE: 'language',
  DSP: 'DSP',
  SHARE_BY_SMS: 'ShareBySMS',
  SHARE_BUTTON: 'shareButton',
  PRINT_BUTTON: 'printButton',
  ADD_TO_CALENDAR_BUTTON: 'addToCalendarButton',
  SEND_TO_CONCOR_BUTTON: 'sendToConcur',
  GOOGLE_CALENDAR: 'googleCal',
  RESERVATION_TEXT: 'text=Reservation at',
  RESERVATION_AT: 'Reservation at',
  STARTS: 'Starts',
  ENDS: 'Ends',
  CONFIRMATION_NUMBER: 'Confirmation number',
  SESSION_VALIDATION_FAILED_OR_EXPIRED: 'SESSION_VALIDATION_FAILED_OR_EXPIRED',
  CART_VALIDATION_FAILED_OR_EXPIRED: 'CART_VALIDATION_FAILED_OR_EXPIRED',
  LOOKUP_RESERVATION_FOR_AUTH_FLOW_302_TO_MYTRIPS: 'LOOKUP_RESERVATION_FOR_AUTH_FLOW_302_TO_MYTRIPS',
  ZCERTIFICATE_RATE_PLAN: '11.RPT',
  SUPPRESSED_RATE: '##.##',
  EMAIL: 'EMAIL',
  UPGRADE_TO_TEXT: 'Upgrade to',
  FREE_FIFTH_NIGHT_AVAILABLE: 'free5thNight',
  POINTS_ADVANCE: 'pointsAdvanceAlertMessage1',
  POINTS_ADVANCE_WITH_FREE_NIGHT: 'pointsAdvanceAlertMessage2',
  CARD_TEXT: 'card',
  REDEMPTION_FALLBACK: 'There was an issue loading your Stay Details. Check your email for confirmation!',
  SMOKE_FREE: 'smoke-free',
  EVENT_DRIVEN_DATALAYER: 'eventdriven',
  PETS_ALLOWED: 'pets-allowed',
  PHOENIX_REQUESTID: 'PHOENIX_REQUESTID',
  BONVOY_MEMBER: 'Bonvoy Member',
  HOTEL_MESSAGE: 'hotelmessage',
  HOTEL_MESSAGE_MGP: 'hotelmessagemgp',
  ALERT: 'alert',
  UPCOMINGRES_ALERT: 'upcomingResAlert',
  TA_LOGIN_ALERT: 'TALoginAlert',
  TA_ROOM_RESTRICT: 'restrictRoomTA',
  ALERTS: 'alerts',
  RATE_DETAILS: 'Rate Details',
  ROOM_DETAILS: 'Room Details',
  RES_SIGNIN_MODAL: 'res-signin-modal',
  PRINT_MODAL: 'printmodal',
  PRINT_MODAL_UXL_STATUS: 'printUXLStatus',
  OCJ_UXL_STATUS: 'updateOCJUXLStatus',
  JOIN_NOW_BANNER: 'joinNowBanner',
  CHECK_IN_BANNER: 'checkinBanner',
  SONDER_PAV_BANNER: 'sonderPAVBanner',
  TRAVEL_INSURANCE_BANNER: 'travelInsuranceBanner',
  PREFERENCE_PLUS: 'preference-plus',
  TARGET_BLANK: '_blank',
  PARKING_TYPES: ['On-site parking', 'Valet Parking', 'Off-site parking'],
  STAY: 'stay',
  LOADING: 'Lodaing...',
  ROOM_LOCATION: 'roomLocation',
  ROOM_FEATURES: 'roomFeatures',
  ACCESSIBILITY: 'accessibility',
  BED_CATEGORY: 'bed',
  APPLY_TO_ALL: 'Apply to all rooms',
  STICKY_BAR_TIMER: 900,
  FIVE_MINUTE_TIMER: 300,
  FIFTEEN_MINUTES_TIMER: 900000,
  THIRTY_MINUTES_TIMER: 1800000,
  PREF_ERROR: 'prefError',
  USA_COUNTRY_CODE_VALUE: '1',
  CN_COUNTRY_CODE_VALUE: '86',
  PAGE_LOADED: 'Page Loaded',
  RRD_COMPONENT_COUNT: 8,
  GI_COMPONENT_COUNT: 1,
  CONFIRMATION_COMPONENT_COUNT: 11,
  TAGP_UNAUTHORIZED_CODE: 'travelAgentUnauthorizedErrorText',
  TAGP_UNAUTHORIZED_MODIFY_ERROR_CODE: 'tagpUnauthorizedModifyErrorText',
  TAGP_ERROR_CODES: [
    'E_BAD_HSSMMP_ID',
    'E_BAD_IATAN_VER',
    'E_TOO_MANY_NIGHTS',
    'E_BAD_IATA',
    'E_NO_CERTS_LEFT',
    'E_MAX_BOOKINGS',
    'E_MAX_BOOK_ARRIVAL',
    'E_DOUBLE_CLICK',
    'E_REPEATED_HOTEL',
    'E_FRAUD_ALERT',
  ],
  TAGP_MODIFY_ERROR_CODES: [
    'E_ARRIVAL_FORMAT',
    'E_BAD_HSSMMP_ID',
    'E_BAD_IATAN_VER',
    'E_BAD_CONF_NO',
    'E_TOO_MANY_NIGHTS',
    'E_ALREADY_CANCELLED',
    'E_CHANGED_HOTEL',
    'E_GNR_DATE_FORMAT',
    'E_MOD_DISALLOW',
  ],
  TAGP_ERROR: 'tagperror',
  NOT_AVAILABLE_TAG: '<p>NA</p>',
  NOT_AVAILABLE: 'NA',
  TI_ACCEPT: 'ti_accept',
  TI_REJECT: 'ti_reject',
  TI_NO_SELECT: 'NOSELECT',
  TI_OPT_IN: 'OPTIN',
  TI_OPT_OUT: 'OPTOUT',
  TI_NONE_SITE: 'None',
  GUEST_TYPE: 'guest',
  MOBILE_APP: 'Mobile Site',
  DESKTOP_APP: 'Desktop App',
  POINT_TYPE: 'POINTS',
  CERTIFICATES: 'CERTIFICATES',
  POINTS_PURCHASE: 'POINTS_PURCHASE',
  CASH_TYPE: 'CASH',
  VISA_CHECKOUT_BTN_IMG_ANALYTICS: '{"sendNow":"true", "description":"Visa Checkout button"}',
  CLICK_TO_PAY_VARIATION: '1.0',
  SITE_NAME: 'marriott.com',
  MI: 'mi',
  BOLD_CARD: 'Bold',
  BOUNDLESS_CARD: 'Boundless',
  SUCCESS_MESSAGE: 'successMessage',
  CARD_TYPES: {
    M: [51, 52, 53, 54, 55, 22, 23, 24, 25, 26, 27], //Mastercard
    V: [4], //Visa
    A: [34, 37], //Amex
    D: [30, 36, 38, 39], //DinersClub
    R: [60110, 60112, 60113, 60114, 601174, 601177, 601186, 601187, 601188, 601189, 60119, 644, 65], //Discover
    J: [352, 353, 354, 355, 356, 357, 358], //JCB
    UP: [62], //UnionPay
  },
  EXTENDED_LANGUAGE: [
    'tr_TR',
    'pl_PL',
    'zh_TW',
    'th_TH',
    'id_ID',
    'el_GR',
    'nl_NL',
    'vi_VN',
    'da_DK',
    'sv_SE',
    'fi_FI',
    'nb_NO',
    'he_IL',
  ],
  CERT_TYPE_PROMOTION: 'promotion',
  LIMIT_ERROR: 'PURCHASE_LIMIT_ERROR',
  INFO_MSG: 'FNA_COUNT_INFO',
  PREPAY: 'Prepay',
  MARRIOTT_EMAIL_OPTIN: 'marriottEmailoptIn',
  MARRIOTT_THIRD_PARTY_EMAIL_OPTIN: 'thirdPartyEmailOptIn',
  AGREE_BUTTON: '#agree-button',
  LEGAL_CONSENT_CONTAINER: '.legalConsent-component',
  OUTSIDE_CONTAINER: '.outside-container',
  INLINE_ENROLL_CONTAINER: '.inline-enrollment-wrapper',
  PRIVACY_TYPE: 'agreeall',
  REQUIRED_TYPE: 'requiredConsent',
  OPTIONAL_TYPE: 'optionalConsent',
  REQUIRED_CONSENT_LABEL: 'requiredConsentLabel',
  EXTERNAL_SITE: 'external-site',
  MEMBER_PROFILE_INFO: 'memberProfileInfo',
  ICON_EXTERNAL_SITE: 't-icon-external-site',
  ICON_EXTERNAL_ARROW: 'icon-external-arrow',
  CONSENT_ID: 'consentId',
  sectionContentLabelRaw: 'rewards-enrollment@kr.consent.gst.info.understand.resv.success',
  sectionContentLabelRawJoin: 'rewards-enrollment@kr.consent.check.box.2.intro',
  DEFAULT_STRING: 'default-string',
  REDEEM_PURCHASE_POINTS: 'REDEEM_PURCHASE_POINTS',
  ATTACH_CERTIFICATE: 'ATTACH_CERTIFICATE',
  CARD_ELEMENT_ID: 'payUsingDebitCredit',
  BILLING_ZIP_CODE: 'billingZipCode',
  CHECKBOX: 'checkbox',
  EXACT_MATCH: 'exact-match',
  SECONDARY_BUTTON_CSS: 'm-button-secondary',
  CO_BRAND_CREDITCARD: 'coBrandCreditCard',
  PASSWORD_COMP: 'Password_Comp',
  POLITE: 'polite',
  OFF: 'off',
  TAB: 'Tab',
  ENTER_KEY: 13,
  CONFIRM_PASSWORD: 'confirmPassword',
  ACCESSIBILITY_ALERTS: 'accessibilityAlerts',
  TRAVEL_INSURANCE_ALERT: 'travelInsuranceAlert',
  PRICE_UPDATE_ALERT: 'priceUpdateAlert',
  ROOM_UPDATE_ALERT: 'roomUpdateAlert',
  FOREIGN_CURRENCY_ALERT: 'foreignCurrencyAlert',
  MODIFY_RATE_TAB: 'ModifyRatetab',
  RATE_TAB: 'Ratetab',
  STANDERED_TAB: 'standerdTab',
  REMEMBERED_STATE: 'remembered',
  SPECIAL: 'special',
  CLUSTER: 'cluster',
  REDEMPTION: 'redemption',
  PACKAGES: 'packages',
  STANDARD: 'standard',
  PRE_PAY: 'prepay',
  PRE_PAY_CN: '预付',
  REGULAR: 'REGULAR',
  MEMBER: 'MEMBER',
  CODE_39E: '39E',
  VILLAS_RESIDENCES: 'villasResidences',
  GUEST_ROOMS: 'guestRooms',
  SENIOR: 'SENIOR',
  DATA_LAYER_STANDARD: 'Rate Tab:Standard Rates',
  DATA_LAYER_PREPAY: 'Rate Tab:Prepay and Save',
  DATA_LAYER_PACKAGES: 'Rate Tab:Room Packages',
  DATA_LAYER_REDEMPTION: 'Rate Tab:Redeem Points',
  DATA_LAYER_SPECIAL: 'Rate Tab:Special Rates',
  DATA_LAYER_VILLAS_RESIDENCES: 'Rate Tab:Villas And Residences',
  DATA_LAYER_GUEST_ROOMS: 'Rate Tab:Guest Rooms',
  GOV: 'GOV',
  NOW_LABEL: 'now',
  GROUP: 'GROUP',
  CORPORATE: 'CORP',
  USA_SMS_CODE: 'United States (1)',
  DEALS_AND_PACKAGES: 'dealsAndPackages',
  WAS_PRICE_THRESHOLD_PERCENTANGE: 3.3,
  STRING_TYPE: 'string',
  BOOLEAN_TYPE: 'boolean',
  REMEMBERED: 'remembered',
  POINTS: 'Points',
  CASH_PLUS_POINTS: 'Cash+Points',
  USER_DEFINED: 'User Defined',
  CASH_OPTIMIZED: 'Cash Optimized',
  POINTS_OPTIMIZED: 'Points Optimized',
  AUTHENTICATED: 'authenticated',
  SELECTED_CREDIT_CARD: 'selectedCreditCard',
  RATES_VIEWED: 'viewedRates',
  UNAUTHENTICATED: 'unauthenticated',
  error: 'error',
  OCJ_ERROR: 'ocjError',
  DEFAULT_ERR_MSG_KEY: 'defaultErrorText',
  HIDE_ERROR_BANNER: 'hideErrorBanner',
  RESTRICT_SUCCESS_MSG_LOAD: 'isFirstLoadOfSuccessMsg',
  RESTRICT_ERROR_MSG_LOAD: 'isFirstLoadOfErrMsg',
  HOTEL_FEATURES_QUERY_ATTRIBUTES: [
    'BUS.71',
    'BUS.72',
    'HAC.50',
    'HAC.72',
    'HAC.88',
    'HAC.138',
    'HAC.141',
    'HAC.142',
    'HAC.249',
    'HAC.250',
    'HAC.251',
    'HAC.252',
    'RMA.112',
  ],
  ICON_MAPPER: {
    '1': 'icon-all-suite-room1x',
    '2': 'icon-room-service',
    '3': 'icon-sheet-set',
    '4': 'icon-robe',
    '5': 'icon-lounge-access',
    '6': 'icon-dining',
    '7': 'icon-kitchen',
    '8': 'icon-bed',
    '9': 'icon-wifi',
    '10': 'icon-maid-service',
    '11': 'icon-events',
    '12': 'icon-accessibility',
    HH: 'icon-concierge',
    PT: 'icon-parking',
    SA: 'icon-slippers',
    A4: 'icon-slippers',
  },
  // Hotel Header
  ACCESS_TYPE: 'access-type',
  REMEMBER_ME_STATE: 'rememberMeState',
  ROOM: 'room',
  DATE: 'date',
  DATE_ROOM: 'date_room',
  MODIFIED_TYPE: 'modifiedType',
  CN_RATING_TOOLTIP: '评分是基于客户完成住宿后在满意度调查中提供的客户评分整理得出的。',
  LOGO_L: 'logo_L',

  //Hotel Brand Codes
  BULGARI_BRAND_CODE: 'BG',
  BULGARI_HOST: 'bulgarihotels',
  MARRIOTT_BRAND_CODE: 'MC',
  SONDAR_BRAND_CODE: 'SN',
  SONDER_BRAND_CODE: 'SN',

  // Hotel Logo CTS API constants
  CTS_LOGO_PAYLOAD: {
    NAVIDS: ['33955158', '3073373715'],
    TAXONOMIES: 'MARSHA Codes',
    TYPES: ['ADT_IMAGE'],
  },

  // Axios Methods
  GET: 'GET',
  POST: 'POST',

  // ViewPoint BreakPoint
  MAX_VIEWPORT_MOBILE: 600,
  BREAKPOINT_TABLET: 768,
  BREAKPOINT_DESKTOP: 992,
  BREAKPOINT_DESKTOP_RRD_GI: 990,
  BREAKPOINT_MAX_DESKTOP: 1025,
  BREAKPOINT_LARGE_DESKTOP: 1200,

  // Locale
  DEFAULT_LANG: 'en',
  DEFAULT_LOCALE: 'en_US',
  DE_LOCALE: 'de_DE',
  CN_LOCALE: 'zh_CN',
  IS_LOCALE_IT: 'it_IT',
  IS_LOCALE_JP: 'ja_JP',
  IS_LOCALE_CN: 'zh_CN',
  LOCALE_CN: 'zh-CN',
  IS_LOCALE_EL: 'el_GR',
  SHOW_SMS_LOCALES: ['it_IT', 'zh_TW', 'ko_KR', 'ar_AE', 'ja_JP', 'ru_RU', 'pt_BR', 'en_AU'],
  OCJ_LOCALIZED_ENROLLMENT_LOCALES: ['ja_JP', 'ko_KR', 'ru_RU'],
  SHOW_TAX_DEFAULT_LOACALES: ['ja_JP', 'en_GB', 'nl_NL', 'he_IL', 'zh_CN'],
  CREDITCARD_VIEW_EXLN_LOCALE: ['id_ID', 'tr_TR', 'pl_PL', 'nl_NL', 'fi_FI'],
  ENROLLMENT_CHECKBOX_ENABLED_LOCALES: ['en_GB', 'fr_FR', 'de_DE'],

  // Concur
  CONCUR_ACCEPTED_LOCALES: ['US', 'GB', 'DE', 'IT', 'FR', 'ES', 'JP', 'KR', 'BR', 'RU'],
  CONCUR: 'CONCUR',

  // Date Formats
  DAY_DATE_FORMAT: 'ddd, MMM DD, YYYY',
  DAY_MONTH_FORMAT: 'dddd, MMMM DD, YYYY',
  DATE_MONTH_FORMAT: 'dddd, MMM DD, YYYY',
  DATE_FORMAT: 'MM/DD/YYYY',
  TIME_FORMAT: 'hh:mm a',
  MONTH_DATE_FORMAT: 'MMMM Do, YYYY',
  YEAR_MONTH_FORMAT: 'YYYY-MM-DD',
  DAY_DATE_WITHOUT_YEAR_FORMAT: 'ddd, MMM Do',
  DAY_DATE_SHORT_FORMAT: 'ddd, MMM D',

  // Page Types
  RRD_PAGE: 'rrd',
  GI_PAGE: 'gi',
  HYBRID_PAGE: 'rrdgi',
  RLM_PAGE: 'rlm',
  ADF_PAGE: 'adf',
  CONFIRMATION_PAGE: 'confirmation',
  UPCOMING_RESERVATION_PAGE: 'upcomingReservation',
  CONFIRM_RESERVATION_CANCELLATION: 'confirmReservationCancellation',
  MODIFY_RLM: 'modifyRateListMenu',
  UPGRADE_RESERVATION_PAGE: 'upgradeReservation',
  LOOKUP_RESERVATION: 'lookupReservation',
  RES_LOOKUP_OVERLAY: 'resLookupOverlay',
  RLM_AND_POSTBOOKING_PAGES: [
    'rlm',
    'upcomingReservation',
    'confirmReservationCancellation',
    'modifyRateListMenu',
    'upgradeReservation',
    'availability',
  ],
  // Res Sign in Modal
  RESTRICT_SIGNIN_AUTO_LOAD: ['rlm', 'upcomingReservation', 'modifyRateListMenu'],
  SIGNIN_MODAL_OPEN_EVENT: 'accountModalOpen',
  SIGNIN_MODAL_CLOSE_EVENT: 'accountModalClose',
  SIGNIN_MODAL_TYPE_POINTS: 'points-rate',
  SIGNIN_MODAL_TYPE_MEMBER: 'member-rate',
  SIGNIN_MODAL_TYPE_NON_MEMBER: 'non-member-rate',
  DTT_SIGNIN_MODAL_TYPE_MEMBER: 'dtt-member-rate',
  DTT_SIGNIN_MODAL_TYPE_NON_MEMBER: 'dtt-non-member-rate',
  DTT_SIGNIN_MODAL_TYPE_POINTS: 'dtt-points-rate',
  SIGNIN_SUCCESS_EVENT: 'SIGN_IN_SUCCESS_',
  SUCCESS_SIGNIN_EVENT: 'successSignInEvent',

  // Credit Card
  AMEX_CODE: 'A',
  DINERS_CODE: 'D',
  CVV_AMEX_LENGTH: 4,
  CVV_DEFAULT_LENGTH: 3,
  ZIP_CODE_LENGTH: 5,
  ZIP_CODE_LENGTH_MIN: 5,
  ZIP_CODE_LENGTH_MAX: 9,
  POSTAL_CODE_LENGTH: 6,
  CC_ZIP_CODE_LENGTH_MAX: 23,

  RATE_CODES: ['31.RPT', '29.RPT', '30.RPT', '33.RPT', '32.RPT'],
  RATE_PLAN_CODES: ['29.RPT', '30.RPT'],
  ESTIMATED_TAX_CODE: 'total-taxes-per-quantity',
  TOTAL_CASH_CODE: 'grand-total',
  CASH_CODE: 'sub-total-per-quantity',
  CASH_UPGRADE_CODE: 'points-plus-cash-upgrade-per-unit',
  CASH_WITH_POINTS: 'cash-and-points-per-unit',
  CASH_WITH_POINTS_MGP: 'RateAmountCashAndPoints',
  POINTS_CODE: 'points-per-quantity',

  // Housekeeping codes
  HK_FREQUENCY: 'Every Other Day',
  HOUSEKEEPING: 'HK',
  HK_PLAN_A: 'A',
  HK_PLAN_B: 'B',
  HK_LOOKUPID_KEY_BASELINE: '2071',
  HK_LOOKUPID_KEY_FREQUENCY: '2069',
  HK_PLANB_PARTICIPATION: 'YES - NO SCHEDULED SERVICE',
  HK_PLANA_PARTICIPATION: 'YES - HOUSEKEEPING PROVIDED',
  HK_NOT_PARTICIPATING: 'NOT PARTICIPATING',

  // Consent Codes
  APAC_COUNTRY: `['CN', 'VN', 'JP', 'KR']`,
  DEFAULT_APAC_COUNTRY_TITLE: `['CN', 'VN']`,
  DEFAULT_APAC_COUNTRY: `['CN', 'VN', 'JP']`,
  CONSENT_PAYLOAD: `['CN', 'VN', 'KR']`,

  // default consent modal button class
  CL_ITEM_LINK: 'cl__item-link',
  M_BUTTON_S: 'm-button-s',

  // Locales Codes
  JAPAN_COUNTY: 'JP',
  CHINA_COUNTRY: 'CN',
  SOUTHKOREA_COUNTRY: 'KR',
  VIETNAM_COUNTRY: 'VN',
  RUSSIA_COUNTRY: 'RU',

  // GI UserName Fields
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  LATIN_FIRST_NAME: 'latinFirstName',
  LATIN_LAST_NAME: 'latinLastName',

  // GI Address Fields
  LINE_ITEM1: 'line1',
  LINE_ITEM2: 'line2',
  CITY: 'city',
  LATIN_LINE_ITEM1: 'latinLine1',
  LATIN_LINE_ITEM2: 'latinLine2',
  LATIN_CITY: 'latinCity',

  // Locales Specific
  APAC_LOCALE: ['JP', 'KR', 'CN', 'RU'],
  CHINA_LOCALE: 'CN',
  LOCALE_LANGUAGE: {
    JP: 'JAPANESE',
    KR: 'KOREAN',
    CN: 'CHINESE',
    RU: 'RUSSIAN',
  },

  // Codes
  MRM: 'MRM',
  S9R: 'S9R',
  AAA: 'AAA',
  ALLOWED_ZIP_CODE: ['US', 'CA'],
  NOT_ALLOWED_STATE_FIELD: ['DE', 'FR', 'MX', 'ES'],
  SECONDARY_EMAIL: 'SE',
  PRIMARY_EMAIL: 'PE',
  PREF_PLUS_TYPE: 'P',
  ALLOWED_CVV_CNTRY: ['US', 'CA'],
  CANADA_COUNTRY_CODE: 'CA',
  AUSTRALIA_COUNTRY_CODE: 'AU',
  USA_COUNTRY_CODE: 'US',
  FR_COUNTRY_CODE: 'FR',
  AMEX_CARD_CODE: 'A',
  BILLING_ZIP_CNTRY_LIST: ['US', 'CA'],
  INDIA_COUNTRY_CODE: 'IN',
  CHINA_COUNTRY_CODE: 'CN',
  JAPAN_COUNTRY_CODE: 'JP',
  MEXICO_COUNTRY_CODE: 'MX',
  SPAIN_COUNTRY_CODE: 'ES',
  HONGKONG_MOBILE_CODE: '852',
  CHINA_MOBILE_CODE: '86',
  US_MOBILE_CODE: '1',
  FR_MOBILE_CODE: '33',
  ASCENDING_DIRECTION: 'ASC',
  CANCELLATION_CP: 'CP',
  CANCELLATION_CF: 'CF',
  HOLDING_AD: 'AD',
  GUARANTEE_GP: 'GP',
  AR: 'AR',
  RULE_CODE_DE: 'DE',
  RULE_CODE_VZ: 'VZ',
  OCJ_PROHIBITED_COUNTRY_CODES: ['CU', 'KP', 'SY', 'IR'],
  ENHANCED_OPT_IN_COUNTRY_CODE: ['GB', 'FR', 'DE', 'AE', 'SA'],

  TAIWAN_COUNTRY_CODE: 'TW',
  TAIWAN_COUNTRY_VALUE: 'Taiwan',
  TAIWAN_COUNTRY_UPDATED_VALUE: 'Taiwan, China',
  // Room Preferences Codes
  PREFERENCES_NOTED_CODE: ['I2', 'N4'],
  PREFERENCES_ERS_CODE: ['5CAT005', '2CAT002', '12CAT12', '9CAT009', '11CAT11'],
  CHARGEABLE_CODES: ['C2', 'R1', 'P8'],
  FEATHER_CODES: ['V9', 'B5'],
  PILLOW_CODES: ['V9', 'F2', 'Y2'],
  PET_POLICY_CODE: 'P8',
  CRIB_CODE: 'C2',
  ROLLAWAY_BED_CODE: 'R1',
  FEATHER_PILLOW_CODE: 'V9',
  SMOKING_PREF_CODES: ['N3', 'S7'],

  //Dynamic Model Routing Header reference codes
  ROUTING_CONFIG: 'routingConfig',
  APPLICATION_NAME: 'book',
  EXPERIENCE_SEGMENT: ['Not Logged In'],
  BULGARI_EXPERIENCE_SEGMENT: 'Bulgari',
  SERVER_TARGET_MBOX_NAME: 'phoenix_book_server_call',
  SERVER_TARGET_MBOX_RLM_NAME: 'target-Rlm-redesign',
  SERVER_TARGET_MBOX_INDEX: 0,
  SERVER_TARGET_MBOX_CLIENT: 'marriottinternationa',
  SERVER_TARGET_MBOX_VERSION: '2.5.0',

  // event for TI placement
  TI_PLACEMENT: 'tiPlacement',

  // ERS filters code
  LOCATION_CODES_LIST: ['7CAT007', '6CAT006', '8CAT008'],
  FILTERS_CODE: ['1CAT001', '4CAT004', '3CAT003', '10CAT10', '7CAT007', '5CAT005', 'A5'],
  LOCATION_CODE: '7CAT007',
  ROOM_CODE: '1CAT001',
  SUITE_CODES: ['REXS0007', 'RSUI0006', 'RJSU0007'],
  BED_CODE: '4CAT004',

  //Suites Room Codes
  SUITES_ROOM_CODES: [
    'RSUI',
    'RSMU',
    'RLSU',
    'RBSU',
    'RPSU',
    'RCSU',
    'REXS',
    'RHSU',
    'RJSU',
    'RPRE',
    'RBRS',
    'RBTS',
    'ROBS',
    'RTBS',
    'RHBS',
    'RFBS',
    'RVBS',
    'RLGS',
  ],

  //Configured Room Codes
  CONFIGURED_ROOM_CODES: [
    'RFLT',
    'RETR',
    'REST',
    'RCHA',
    'RBUN',
    'RBIL',
    'PSOM',
    'SROM',
    'PROM',
    'RVPR',
    'RAPR',
    'ROOM',
    'RSTU',
    'RBLL',
    'RPAR',
    'RSUI',
    'RSMU',
    'RLSU',
    'RBSU',
    'RPSU',
    'RCSU',
    'REXS',
    'RHSU',
    'RJSU',
    'RPRE',
    'RBRS',
    'RBTS',
    'ROBS',
    'RTBS',
    'RHBS',
    'RFBS',
    'RVBS',
    'RLGS',
    'RSAP',
    'RLAP',
    'RPAP',
    'RVIL',
    'RPVT',
    'RLVL',
    'RSVL',
    'RPVI',
    'RCND',
    'RTWN',
    'RCTT',
    'RCLN',
    'RRES',
    'RMAN',
    'RTCL',
  ],
  NEXT_PUBLIC_RECENTLY_VIEWED_CONFIG: {
    maxCachedPropertiesLimit: 10,
    maxCachedPropertiesTab: 2,
    maxCachedPropertiesDesktop: 3,
    maxCachedPropertiesMobile: 2,
    maxCachedDaysLimit: 60,
  },
  RESORT_FEE_TYPE: 'resortFeeDescription',
  DESTINATION_FEE_TYPE: 'destinationFeeDescription',
  SURCHARGE_FEE_TYPE: 'surchargeOrdinanceCostDescription',

  //villasRoomGuestTabsTypes
  VAR: 'VAR',
  GR: 'GR',

  // TA specific
  TA_CODES_LIST: ['H77', '7TA', 'YX3'],
  TA_PLAN_AGENT: 'planAgent',
  TA_FAM_AGENT: 'famAgent',
  TA_AGENT: 'travelAgent',
  PLANTASTIC_CODE: 'YX3',
  FAMTASTIC_CODE: 'H77',
  TRAVEL_AGENT_CODE: '7TA',
  PLANTASTIC_PRODUCTS: ['MPTP', 'MPTU', 'MPTV'],
  FAMTASTIC_PRODUCTS: ['HSSA', 'HSSF', 'HSSM', 'HSSN', 'FMHZ', 'ZZHO'],
  TRAVEL_AGENT_PRODUCTS: ['TASK', 'TASV', 'TVAM', 'TVAG', 'TVRV', 'VTAM', 'VTAN', 'YTGG'],
  TAGP_RATE_NAME: {
    H77: 'famTastic',
    YX3: 'planTastic',
    '7TA': 'travelAgent',
  },
  TAGP_CODES: {
    H77: '1',
    YX3: '1',
    '7TA': '2',
  },
  TRAVEL_AGENT_RATE_PLANS: {
    H77: 'H77',
    YX3: 'YX3',
    '7TA': 'SEVEN_TA',
  },

  // StarLuminous Specific
  SL_CODES_LIST: ['S72', 'L72', 'A72'],

  DOM_DETECTOR_IFRAME_MESSAGE: 'frame:notification',
  HEADER_REFRESH: 'ev:refreshHeader',

  KOREAN_LOCALE: 'ko_KR',
  KOREAN_CURRENCY: 'KRW',
  // Locale in which mentioned currency has no decimal placements
  NO_DECIMAL_LOCALE: {
    ko_KR: 'KRW',
  },

  INVALID_TRAVEL_DATES: 'invalidTravelDatesErrorText',
  SUCCESS_MSG: 'successMsg',
  CANCEL_SUCCESS_MSG: 'cancelSuccessMsg',
  CANCEL_FAILURE_MSG: 'failureMessage',

  //Email radio buttons
  PRIMARY_EMAIL_RADIO: 'primary',
  ALTERNATE_EMAIL_RADIO: 'alternate',

  INVALID_USER_INPUT: 'UNPROCESSABLE_ENTITY',
  INVALID_BONVOY_MEMBER_NUMBER: 'Profile Not Found In Marsha',

  HIGH: 'high',
  LOW: 'low',

  SMS: 'sms',
  NONE: 'NONE',

  //ota specific message types
  ADDITIONAL_CHARGES_MESSAGE: 'additionalCharges',
  MODIFY_RESERVATION_MESSAGE: 'modifyReservation',

  // for SNA validation
  SNA_ALERT: 'snaAlert',

  MI_APP: 'mi-app',
  MI_SS: 'mi-ss',

  ARRIVAL_CODE: 'arrival',
  ACCESSIBILITY_CODES: ['MRIS0002', 'MTUB0002'],

  // When user redirected from 3rd party urls
  AFFILIATE_COOKIE: 'Affiliate',

  // link to redirect from reviews section if going to reviews page
  viewHotelReviewsLink: '/hotels/travel/',

  // link to redirect from reviews section if going to HWS Landing Page
  viewHotelWebsiteLink: '/hotels/travel/',

  // part of link to enable redirection to HWS reviews section
  hotelsSublink: '/hotels/',

  // part of link to enable redirection to HWS reviews section
  reviewsSubLink: '/reviews/',

  // to pass as an argument to redirection hook
  reviewsRedirectionVarValue: 'reviews',

  HWSRedirectionVarValue: 'hws',

  // Icon brand logo in Res sum tile css - fontSize and linHeight in px mapping for brandCode
  BRAND_ICON_RS_CONFIG: {
    cy: 14.81,
    ge: 46.85,
    ar: 64,
    fp: 65.15,
    fi: 26.94,
    ox: 33.45,
    pr: 41.8,
    mc: 64.18,
    eb: 17.64,
    xe: 31.2,
    xf: 32,
    mg: 32,
    wh: 13.65,
    sn: 65.33,
  },
  // Icon brand logo in Hotel Header modal css - fontSize and linHeight in px mapping for brandCode
  BRAND_ICON_HH_CONFIG: {
    xe: 31.2,
    xf: 32,
    wh: 13.65,
    sn: 65.33,
  },

  //Nav Call header keys
  X_DEVICE_TRACKING_TOKEN: 'X-Device-Tracking-Token',
  X_PAYMENT_TRACKING_TOKEN: 'X-Payment-Tracking-ID',
  CONTENT_TYPE_KEY: 'Content-Type',
  CONTENT_TYPE_VALUE: 'application/json',
  X_REQUESTED_BY: 'X-Requested-By',
  TARGET_COOKIE_NAME: 'PHX_BOOK_SST_TRACKING',
  PHX_TARGET_COOKIE_NAME: 'phx_book_sst_tracking',

  // ENV variables Headers
  REQUEST_ID: 'REQUEST_ID',
  ACCEPT_LANGUAGE: 'ACCEPT_LANGUAGE',

  // Platform var
  PHOENIX: 'PHOENIX',
  ARIES: 'ARIES',

  IS_REPEAT_BOOKING: 'isRepeatBooking',
  REPEAT_BOOKING_VALUE: true,
  COUNTRY_CODE_NEWZEALAND: 'NZ',
  COUNTRY_CODE_AUSTRALIA: 'AU',
  CREDIT_CARD_ALERT: 'creditCardAlert',
  TRUE: 'true',
  FALSE: 'false',
  // This constant is to include active session user country
  ACTIVE_SESSION_COUNTRY_FULL_PRICE: ['UK', 'NL', 'IL'],
  USER_ADDRESS_FORM_VALIDATION_COUTIRIES: {
    AllFields: ['US', 'CA'],
    AddressCity: ['AU', 'MX', 'ES', 'DE', 'JP'],
    City: ['CN'],
    AddressCityCode: ['FR'],
    AddressCityState: ['CN-AUTH'],
  },
  USER_ADDRESS_FORM_VALIDATION: {
    AllFields: {
      addressLine1: true,
      city: true,
      state: true,
      code: true,
    },
    AddressCity: {
      addressLine1: true,
      city: true,
      state: false,
      code: false,
    },
    City: {
      addressLine1: false,
      city: true,
      state: false,
      code: false,
    },
    AddressCityCode: {
      addressLine1: true,
      city: true,
      state: false,
      code: true,
    },
    AddressCityState: {
      addressLine1: true,
      city: true,
      state: true,
      code: false,
    },
  },
  EXTERIOR: 'exterior',
  HIDEMEMBERSHIPNUMBER: 'hideMembershipNumber',
  OLD_RECIPE: 'oldRecipe',
  COMPLETE: 'complete',
  SELECT_BTN: 'Select Button',
  QUICKBOOK: 'Quickbook',
  MEMBER_RATE: 'Member',
  NON_MEMBER_RATE: 'NonMember',
  GROUP_MEMBER_RATE: 'groupMember',

  THE_RITZ_CARLTON_CODE: 'RZ',
  // API Headers
  ACCEPT_LANG: 'accept-language',
  NO_CERTIFICATE: 'NO_CERTIFICATE',
  LIST_OF_LOCALE_LOGIN_IS_DISBALED: ['zh-TW'],
  //MGP constants
  RATE_MODES: {
    CASH_ONLY: 'HotelRoomRateDetailsRateModesCash',
    POINTS_ONLY: 'HotelRoomRateDetailsRateModesPoints',
    CASH_AND_POINTS: 'HotelRoomRateDetailsRateModesCashAndPoints',
    POINTS_PLUS_CASH_UPGRADE: 'HotelRoomRateDetailsRateModesPointsPlusCashUpgrade',
  },
  SOURCE_OF_RATE_DSP: 'DSP',
  gcv: 'amcv_664516d751e565010a490d4c@adobeorg',

  // For res lookup
  CONFIRMATION_NUMBER_MAXLENGTH: 11,
  RTL_LANG_LIST: `:lang(he-IL), :lang(ar-AE)`,
  CHECK_IN_DATE: 'checkInDate',
  CONFO_NUMBER: 'confirmationNumber',

  SMB_BOOKING: 'WWWSP',
  COMPLIMENTARY: 'COMPLIMENTARY',
  REWARDS_REDEMPTION_CLUSTER_CODES: ['MRW', 'P17', '39E', 'MW1'],
  EARN_WHILE_YOU_SLEEP: 'earnWhileYouSleep',
  JOIN_AND_SAVE: 'joinAndSaveReservation',
  IS_TAGP_COOKIE: 'isTagpCookie',
  INFO: 'info',
  IS_RSBRAND_MODAL_COOKIE: 'RSBrandModalCookieChecked',
  STUDIO_RES: 'studioRes',
  RSBRAND_MODAL_COOKIE_TIMEOUT: 129600,
  RS_BRAND_CODE: 'RS',

  ARABIC_PERIODS: {
    MORNING: 'صباحًا',
    NOON: 'ظهرًا',
    AFTERNOON: 'عصرًا',
    EVENING: 'مساءً',
  },
  NRP_RLM_FLYOUT_ID: 'showNRPRlmFlyoutModal',
};

export const URL_CONSTANTS = {
  HTTPS_STRING: 'https://',
  ITEM_PATH_ROOT: 'root/responsivegrid',
  ITEM_PATH_EXP_F: 'root',
  ITEM_PATH_HEADER: 'root/header-responsivegrid',
  ITEM_PATH_FOOTER: 'root/footer-responsivegrid',
  BASE_IMG_URL:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNiOAMAANUAz5n+TlUAAAAASUVORK5CYII=',
  CONSENT_URL: 'https://6364284c8a3337d9a2f29525.mockapi.io/getConsent/KR',
  PROPERTY_URL: '/hotels/travel/',
  PROPERTY_LOCATION_URL: '/hotels/maps/travel/',
  HOTEL_MAP_POST_URL: 'directPageRequest=true/',
  PLAY_SERVICE_PATH: 'https://phoenix-bookdev1.ose-dev39-red.aws-use1.cloud.marriott.com',
  VISA_CHECKOUT_PAYMENT_URL: '/phoenix-payment/v1/processVisaCheckoutPayment',
  GUEST_INFO_REDIRECT_URL: '/reservation/availabilitySearch.mi?method=edit',
  RLM_GI_REDIRECT_URL: '/reservation/rateListMenu.mi?showAllRates=true',
  RLM_REDIRECT_URL: '/reservation/rateListMenu.mi?marshaTimedOut=true',
  LOOKUP_REDIRECT_URL: '/reservation/lookupReservation.mi?fromOverlay=true',
  LOOKUP_OVERLAY_URL: '/resLookupOverlay.mi?overlay=true',
  CONFIRM_CANCELLATION_URL: '/reservation/confirmReservationCancellation.mi',
  MODIFY_RLM_PAGE_URL: '/reservation/modifyRateListMenu.mi',
  UPCOMING_RES_PAGE_URL: '/reservation/upcomingReservation.mi',
  GUEST_INFO_PUBLIC_URL: '/reservation/guestInfo.mi',
  RRD_PAGE_URL: '/reservation/reviewDetails.mi',
  HYBRID_PAGE_URL: '/reservation/reviewguestinformation.mi',
  CONFIRMATION_PAGE_URL: '/reservation/confirmation.mi',
  HQV_URL: '/search/hotelQuickView.mi',
  RLM_URL: '/reservation/rateListMenu.mi',
  ADF_URL: '/search/availabilityCalendar.mi',
  RATE_LIST_MENU_URL: 'rateListMenu',
  SAVE_PROPERTY_URL: '/loyalty/myAccount/saveProperties.mi',
  SIGNIN_OVERLAY: '/signInOverlay.mi',
  RES_SIGNIN_OVERLAY: '/reservation/signInOverlay.mi',
  SIGNIN_ONLY_OVERLAY: '/reservation/signInOverlay-signInOnly.mi',
  SIGNIN_URL: '/sign-in.mi',
  BUY_POINT_URL: '/loyalty/redeem/topUpPoints.mi',
  BUY_POINT_V2_URL: '/loyalty/redeem/topUpModifyResPoints.mi',
  CONFIRMATION_URL: 'confirmation',
  RRD_URL: 'reviewDetails',
  RRD_GI_URL: 'reviewguestinformation',
  GUEST_INFO_URL: 'guestInfo',
  IFRAME_CARD_URL: 'creditcardforreviewdetails',
  UPCOMING_RES_URL: 'upcomingReservation',
  UPCOMING_RES_IFRAME: 'creditcardforupcomingres',
  UPCOMING_RES_CANCELLATION: 'confirmReservationCancellation',
  MODIFY_RLM: 'modifyRateListMenu',
  UPGRADE_RES_URL: 'upgradeReservation',
  EXPIRED_RES_URL: 'expiredSession',
  REDIRECT_PAGE_URL: '800Page',
  AVAILABILITY: 'availability',
  CALENDAR_URL: 'https://calendar.google.com/calendar/r/eventedit?',
  EMAIL_URL: 'phoenix-email/v1/createTemplateAndSendEmail',
  DATALAYER_FALLBACK_URL: '/aries-common/v1/dataLayer.comp',
  GOOGLE_MAP_URL: 'https://www.google.com/maps/search/?api=1&query=',
  BAIDU_MAP_API_URL: 'http://api.map.baidu.com/marker?location=',
  BAIDU_MAP_URL: 'https://map.baidu.com/?autoOpen=true&latlng=',
  HOTEL_REVIEWS_URL_PATH: '/hotels/hotel-reviews/',
  ERROR_PAGE_URL: '/reservation/800Page.mi',
  EXPIRED_SESSION_URL: '/reservation/expiredSession.mi',
  SAVE_TO_TRIPS_URL: '/reservation/saveToMyTrips.mi',
  EARN_WHILE_YOU_SLEEP_LINK: '/reservation/earnWhileYouSleep.mi',
  TI_REDIRECT_STATIC_LINK: 'https://etravelprotection.com/marriott1',
  MI_FOUNDATION_JS: '/aries/dd578d02c/standards/js/mi-foundation.js',
  MI_CORE_JS: '/aries/e40f6404963a1913580c/components/mi-core/mi-core.js',
  MI_COMMON_JS: '/aries/ee81380581baa484d0f3/components/mi-common/mi-common.js',
  MI_VENDOR_JS: '/aries/c5932cf546474c4c6ca7/components/mi-vendor/mi-vendor.js',
  RLM_NAV_API_URL: '/phoenix-reservation/v1/selectRate',
  UNSUCCESSFULL_URL: '/reservation/unsuccessfulSell.mi',
  SEARCH_FAIL_URL: '/search/findHotels.mi?unsuccessfulSell=true',
  AVAILABILITY_URL: '/reservation/availability.mi?isSearch=false&showErrors=true',
  UNAVAILABILITY_URL: '/reservation/productsUnavailable.mi?propertyCode=',
  POINTS_LOGIN_RLM_URL: '/reservation/ersSignInOverlayRedemptionAndMemberRate.mi',
  LOGIN_MODIFY_RLM: '/reservation/ersModifySignInOverlayRedemptionAndMemberRate.mi',
  AVAILABILITY_CALENDAR_URL: '/reservation/availabilitySearch.mi?isRateCalendar=true&isSearch=true&currency=',
  GET_SELECT_RATE: '/mi/phoenix-reservation/v1/selectRate',
  OTHER_RATES_BASE_URL: '/reservation/availabilitySearch.mi',
  CHECK_IN_NOW_URL: '/reservation/check-in-details.mi',
  CACHE_CLEAR_URL: '/data/v1/purgeConsumersAPICache',
  ROOM_CANCELLATION: '/phoenix-reservation-black/v1/confirmCancelReservation',
  CTS_API_URL: '/cts/v1/queries/index',
  CACHE_MARRIOTT_URL: 'https://cache.marriott.com',
  CREATE_ACCOUNT_URL: '/loyalty/createAccount/createAccountPage1.mi',
  JOIN_SAVE_OCJ_URL: '/reservation/joinAndSaveReservation.mi',
  MODIFY_RLM_NAV_API_URL: '/phoenix-reservation/v1/updateReservationChanges',
  MODIFY_SELECT_RATE: '/phoenix-reservation/v1/modifySelectRate',
  ERS_ROOM_POOL_URL: '/reservation/ersViewRoomPool.mi',
  ERS_RATE_RULE_URL: '/reservation/ersViewRateRules.mi',
  THE_RITZ_CARLTON_URL: 'http://www.ritzcarlton.com',
  BULGARI_HOTEL_URL: 'https://www.bulgarihotels.com',
  TRC_HOTEL_REVIEW_PATH: '/hotels.hotel-reviews',
  MI_ASSETS: '/mi/assets',
  SUBMIT_LOOKUP_RESERVATION: '/phoenix-reservation/v1/submitReservationLookup',
  AVAILABILITY_PAGE_URL: '/reservation/availability.mi?isSearch=false&showErrors=true&propertyCode=',
  FIND_RESERVATION_LIST: '/loyalty/findReservationList.mi',
  FETCH_HOTEL_HEADER_CUSTOMER_DATA: '/phoenix-reservation/v1/fetchHotelHeaderCustomerData',
};

export const CUSTOM_EVENTS_CONSTANTS = {
  ON_SHOW_RATE_DETAIL: 'showRateDetailModal',
  ON_SHOW_ROOM_DETAIL: 'showRoomDetailModal',
  ON_SHOW_HOTEL_DETAIL: 'showHotelDetailModal',
  ON_SHOW_SESSION_TIMEOUT: 'showSessionTimeOutModal',
  ON_GRAPHQL_ERROR: 'UXLcallError',
  ON_SHOW_CONFIRM_CHANGES: 'showConfirmChangesModal',
  ON_CLOSE_RLM_MODAL: 'closeRLMModal',
  PTS_ON_TRANSACTION_SUCCESS: 'pts-on-transaction-success',
  PTS_ON_RECEIPT: 'pts-on-receipt',
  ON_SHOW_RESORT_FEE_MODAL: 'showResortFeeModal',
  ON_SHOW_AAA_MODAL: 'showAAAModal',
  ON_SHOW_LOOKUP_FORM: 'showFindReservationModal',
  MARRIOTT_ORIGIN_EVENT: 'marriott.com',
  BULGARI_ORIGIN_EVENT: 'bulgarihotels',
  LOCALHOST_ORIGIN_EVENT: 'http://localhost:4200',
  PERF_ENV_ORIGIN_EVENT: 'mi-perftest',
  ON_SHOW_OTP_VALIDATION_CANCEL_MODAL: 'showOTPValidationCancelModal',
  ON_SHOW_OTP_VALIDATION_MODAL: 'showOTPValidationModal',
  ON_CLOSE_OTP_VALIDATION_MODAL: 'closeOTPValidationModal',
  ON_SHOW_OTP_VALIDATION_SUCCESSFUL_MODAL: 'showOTPValidationSuccessfulModal',
  ON_SHOW_ROOM_RATE_DETAILS_MODAL: 'showRoomRateDetailsModal',
  ON_CLOSE_ROOM_RATE_DETAILS_MODAL: 'closeRoomRateDetailsModal',

  /* The event that would be triggered when rewards number is responded from /cnInlineEnrollment */
  CN_INLINE_ENROLLMENT_REWARDS_NUMBER_UPDATED: 'cnInlineEnrollmentRewardsNumberUpdated',
  /* The event that would be triggered for showing page level cn inline enrollment errors */
  SHOW_CN_ENROLLMENT_ERROR: 'showCNEnrollmentError',

  // event for TI placement
  TI_PLACEMENT: 'tiPlacement',

  ON_NRP_LIST_RESET: 'nrpListReset',
  ON_NRP_LIST_CHANGE: 'nrpListChange',
  OPEN_CYS_MODAL: 'openCYSModal',
};

export const REGEX_CONSTANTS = {
  LOWER_CASE_PATTERN: '(.*[a-z].*)',
  UPPER_CASE_PATTER: '(.*[A-Z].*)',
  SPECIAL_CHAR_PATTERN: '[\\d$!#&@?%=_]',
  ALLOWED_CHAR_PATTERN: /^[a-zA-Z0-9@!#$&?%=_]*$/gm,
  LENGTH_PATTERN: '^(?=.{8,20}$).*',
  WHITESPACE_PATTERN: /[\s,]/g,
  VISA_REGEX: `^[0-9 | s,*]{19}?$`, //includes 3 white space
  DINERS_REGEX: `(^[0-9 | s,*]{19}?$)|(^[0-9 | s,*]{17}?$)`,
  AMEX_REGEX: `^[0-9 | s,*]{17}?$`, //includes 2 white space
  CVV__DEFAULT_REGEX: `^[0-9]{3}?$`,
  CVV_AMEX_REGEX: `^[0-9]{4}?$`,
  DATE_REGEX: /\d{4}([\\/.-])\d{2}\1\d{2}/g, //regex to check the date format in yyyy-mm-dd
  EMAIL_REGEX:
    '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  NUMBERONLYPATTERN: /^[0-9]{0,}$/,
  DIGIT5_ZIP: `^[0-9]{5}?$`,
  DIGIT6_ZIP: `^[0-9]{6}?$`,
  LATIN_NAME_REGEX: "^[A-Za-z\\s\\-'’]*$",
  INLINE_ENROLLMENT_SMS_CN: /^\d{11}$/,
  INLINE_ENROLLMENT_SMS_NON_CN: /^(\d{7,16}|)$/,
};

export const GQL_OPT_NAME = {
  heroBanner: 'PhoenixBookHeroBanner',
  reservationDetails: 'PhoenixBookReservationDetails',
  hotelAlerts: 'PhoenixBookGetAlerts',
  propertyData: 'PhoenixBookPropertyData',
  snaData: 'PhoenixBookSnaData',
  hotelCancellationPolicy: 'PhoenixBookCancellationPolicy',
  hotelCancellationPolicyByCart: 'PhoenixBookCartCancellationPolicy',
  memberOptions: 'PhoenixBookGetLookupRewards',
  orderDetails: 'PhoenixBookGetOrderDetails',
  reservationSummary: 'PhoenixBookResSummary',
  stayDetails: 'PhoenixBookStayDetails',
  travelInsurance: 'PhoenixBookTIQuoteUrl',
  currencyChange: 'PhoenixBookCurrencyChange',
  confirmationMessage: 'PhoenixBookConfirmationMessage',
  roomUpgradeUserData: 'PhoenixBookRoomUpgradeUserData',
  hotelHeader: 'PhoenixBookHotelHeaderData',
  hotelHeaderAuth: 'PhoenixBookHotelHeaderDataAuth',
  roomUpgradeData: 'PhoenixBookRoomUpgradeData',
  lookupByType: 'PhoenixBookLookupsByType',
  shareBySMS: 'PhoenixBookShareBySMS',
  addTOCalendar: 'PhoenixBookAddToCalendar',
  summaryOfChargesAuth: 'PhoenixBookSummaryOfChargesAuth',
  summaryOfChargesUnAuth: 'PhoenixBookSummaryOfChargesUnAuth',
  summaryOfChargesCart: 'PhoenixBookSOCCart',
  coBrandCreditCard: 'PhoenixBookCoBrandCreditCard',
  redemptionNotification: 'PhoenixBookNotificationStatus',
  redemptionNotificationUnAuth: 'PhoenixBookNotificationStatusUnAuth',
  shareByEmail: 'PhoenixShareByEmail',
  hotelMessages: 'PhoenixBookGetMessages',
  roomRequests: 'PhoenixBookRoomRequests',
  roomRequestsUnAuth: 'PhoenixBookRoomRequestsUnAuth',
  roomImages: 'PhoenixBookRoomImages',
  stickyBar: 'PhoenixBookStickyBar',
  stayDate: 'PhoenixBookStayDate',
  prePayConsent: 'PhoenixBookGetPrePayConsent',
  houseKeeping: 'PhoenixBookHouseKeeping',
  updateCart: 'PhoenixBookUpdateCart',
  updateProfile: 'PhoenixBookUpdateCustomerPreferences',
  updateMemberInformation: 'PhoenixBookUpdateMemberInformation',
  updateUserInformation: 'PhoenixBookUpdateUserInformation',
  propertyPaymentInfo: 'PhoenixBookPropPayInfo',
  customerPaymentInfo: 'PhoenixBookCustPayInfo',
  prefPlusBook: 'PhoenixBookPrefPluBook',
  memberInformation: 'PhoenixBookMemberInformation',
  countriesAndState: 'PhoenixBookCountryStateLookup',
  userFormTitles: 'PhoenixBookUserFormTitleLookup',
  smsCountryCodes: 'PhoenixBookSmsCountryCodesLookup',
  travelInsuranceWidget: 'PhoenixBookTIQuote',
  paymentmethods: 'PhoenixBookPaymentMethods',
  redemptionPanel: 'PhoenixBookGetRedemptionData',
  stateLookUp: 'PhoenixBookStateLookup',
  updateInsuranceCart: 'PhoenixBookUpdateInsuranceCart',
  updateCartFNA: 'PhoenixBookUpdateCartFNA',
  cartCoBrandCreditCart: 'PhoenixBookCartCoBrandCreditCard',
  creditCardInfo: 'PhoenixBookCreditCardInfo',
  extendCartExpiration: 'PhoenixBookExtendCartExpiration',
  updateCartExpiration: 'PhoenixBookUpdateCartExpiration',
  customerSavedProperties: 'PhoenixBookUpdateSaveProperties',
  searchProductByProperty: 'PhoenixBookSearchProductsByProperty',
  searchProductByPropertyNonErs: 'PhoenixBookSearchProductsByPropertyNonErs',
  propertyDetails: 'PhoenixBookProperty',
  getRoomDetailsFeatures: 'PhoenixBookGetRoomDetailsFeatures',
  getRateDetails: 'PhoenixBookGetRateDetails',
  defaultSelection: 'PhoenixBookDefaultSelectionData',
  getStickyBar: 'phoenixBookSuggestedPlacesDetailsQuery',
  getDestination: 'phoenixBookSuggestedPlacesQuery',
  customPayment: 'PhoenixBookGetNightlyProducts',
  getAAADetails: 'PhoenixBookGetAAADetails',
  addAAADetails: 'PhoenixBookAddAAADetails',
  updateAAADetails: 'PhoenixBookpUpdateAAADetails',
  validateAAANumber: 'PhoenixBookValidateAaaNumber',
  joinNowMemberOptions: 'PhoenixBookGetJoinNow',
  prepayRates: 'PhoenixBookPrepayRates',
  roomOverview: 'PhoenixBookRoomOverviewDetails',
  searchProduct: 'PhoenixBookSearchProductInfo',
  checkNow: 'PhoenixBookCheckInNow',
  travelInsuranceBanner: 'PhoenixBookTravelInsurance',
  getProductFromOrder: 'PhoenixBookGetProductFromOrder',
  updateOrder: 'PhoenixBookUpdateOrder',
  authRoomOverview: 'PhoenixBookAuthRoomOverviewDetails',
  cancelledReservationDetails: 'PhoenixBookCancelledReservationDetails',
  currentReservationDetails: 'PhoenixBookCurrentReservationDetails',
  recommendedProductByUserPreference: 'PhoenixBookRecommendedProductByUserPreference',
  customerPreferences: 'PhoenixBookCustomerPreferences',
  currencyExchange: 'PhoenixBookCurrencyExchange',
  amenitiesList: 'phoenixBookHotelAmenities',
  photoGallery: 'phoenixBookHQVPhotogalleryCall',
  information: 'phoenixBookPropertyInfoCall',
  confirmChangesModal: 'PhoenixBookGetProductInfo',
  translateText: 'PhoenixBookTranslateText',
  roomUpgradeUpsellData: 'PhoenixBookRoomUpgradeUpsellData',
  productAddOns: 'PhoenixGetProductDetails',
  createCart: 'PhoenixCreateCart',
  createOrder: 'PhoenixCreateOrder',
  createTravelInsuranceOrder: 'PhoenixCreateTravelInsuranceOrder',
  createTravelInsuranceCart: 'PhoenixCreateTravelInsuranceCart',
  selectRateCurrencyConversion: 'PhoenixBookConvertCurrency',
  getCartPrice: 'PhoenixBookCartPrice',
  roomImagesCTS: 'PhoenixBookRoomImagesCTS',
  nonRoomProductFlyout: 'PhoenixBookDTTNRPFlyout',
};

// Please make the signature update in the signatures.json file on the _constant folder in apps/book
// These signatures will be not used on the actual graphql call on the browser
export const GQL_OPT_SIGN = {
  PhoenixConsents: '233774a1fc61b249277520a308317a1c59c6269aff0775a03c5149455be582c0',
  PhoenixContentLabels: '3f6466cf88861d02abb6d98ff147366ba83cb4dad0b50025643dc02b91aa12be',
  ProfileCountryCode: '849039589ea9deaedee62fd88d6575f465601a5ab7fa1f81e9328376f9db77e9',
  PhoenixBookCartSOC: '79360670142dc3c2a65b1099657de3110566bee2a28142ff232bfa3e577272ca',
  PhoenixCreateCart: '7f3d2ac665b5b255154de1f5a7c9f6801d5fc2fc6f74286fa5a4d236e935a3b6',
  PhoenixCreateGuestList: '3e10dd7ef497432d50556cbf650e26e6f24e1920d1ddbf70c6969bb8c1f7c821',
  PhoenixCreateOrder: '3d05bf8400064159b896bc392141d78a29f5e09629f0edf01497d342e197a974',
  PhoenixCreateTravelInsuranceCart: 'b3bb4e15fee9eb72dbb8fd0fd389f7c74834e0dee35032a9ee438dad1b02b463',
  PhoenixCreateTravelInsuranceOrder: 'a314cd7839e0df00f39304de730d65548c50103e6b2be7fbcc8162d519e7c9a8',
  PhoenixCustomer: 'c1c3967859260d719f055a3a7a65d1cc1c696e66b3b9fe32d1afa9742dd556f4',
  PhoenixCustomerContactInfo: '1a9dfb0b75bfc2ea792ab5227dfab2e182af39904ecfd98dfabf4b50cdab53d5',
  PhoenixEnrollCustomer: 'e4c3db99447dcbf056c0cea7b1bedfa6dabeeca9e50c67af7006e7e3251968be',
  PhoenixGetCustomerData: 'b350782aa9b2ee1ba10134af2e98bc18047fe27737b0d10ee9d9526bc46488f0',
  PhoenixGetCustomerPreferences: 'aa2c2201ec8e45091038215e9ddc673bbc29180ab584bf72afea3731200cd40f',
  PhoenixGetProductCode: 'ac4f85aa2f4c79de22af12d2ffd8758b32eb526db969bb46906499ea1b6e3eef',
  PhoenixGetProductDetails: '36db2e8c8f819976b3a0c5868264687dca472a0c71dc305e1eda97a736dd9369',
  PhoenixGetPropertyBasicAndContactInfo: '542f7a81f11d9653784464552fb3a5351c04f266f9970ed9728c25c46e5d87bf',
  PhoenixGetShoppingCartDetails: '53663691d68cb7ddb4d714bfaa6f89dae47d7481843d93830d9753a1fca095d1',
  PhoenixPropertyBasicInfo: '830970018ea94cbf505061d5c1f72f5f4ad9492625dea4ef5e4e17f757520239',
  PhoenixUpdateCart: '3bf85a61e0e86787859347ff8843f45d02fd31a79d131483a92857bab220561d',
  PhoenixAddCustomerFeatures: 'd3b6f06aefe8e1d01c1d246744921b1e2a7981bef2dcc7b6492bcd94d5e30711',
  PhoenixUpdateCustomerCommunicationOptions: 'f14903096ec11b09e797c9929c440bdf1857a9b47163338707b694e1ee5edeba',
  PhoenixUpdateCustomerContactInfo: 'c66bd4d55afda1a214f63aa573ab5a77938910adb78f2fa1842118bf4a74a976',
  PhoenixUpdateCustomerPaymentMethod: 'a9f267b8e60312260ae07b3447df405a516a2aa3c8a9e74db649149f09dacbeb',
  PhoenixUpdateCustomerPersonalInformation: '91c37a3620ceb255e616da453e59486158b65fb3d1d8fc34d666442b222ad0f6',
  PhoenixDeleteCart: '2765ebaf82e575eefb6b42a13f8668172446dd0c946259431c33c353b4ccc6c1',
  PhoenixBookAddAAADetails: 'cc49ace53af76412f733edd59418a1f8c8e14a5d93c2c91afe641b062a431119',
  PhoenixBookAddToCalendar: 'd1d956b2b535b233cab7a646e3416b9f56d675777b93fa7cb52481a2354e7670',
  PhoenixBookGetPrePayConsent: 'c0248ad70b21d0659f737ec0f702d4703545983c69c58c7797e15242ef8c527a',
  PhoenixBookGetOrderDetails: '939f42e4f74d95ae52e50258b0e5695c29d070c7a1fec3c3fc8d67c39f2ceb5e',
  PhoenixBookCartCancellationPolicy: '39faf1916b87a44bc632807e0a692e6504be827c403450e5f513c4afa6de6894',
  PhoenixBookCartCoBrandCreditCard: '5d21183dfb79208fa9548ad045ff8eea870d197d2e089fcb91b6f40f39672345',
  PhoenixBookCheckInNow: '7c9372ff0b537f5c30be829249679a481031f85f7b5c9e303b7da20b10e48964',
  PhoenixBookCoBrandCreditCard: 'ffa22281689f444474789c1b2a5f4bb452c7068f6f8926a3e1a080ccbce6eae6',
  PhoenixBookConfirmationMessage: '5e1d21235f211f603ffb8f48726125ce24c6bfc8f5f2dfe1784e496344a67784',
  PhoenixBookCountryStateLookup: '39ef08e4114a7c3f85dc863ac43756ba57397cacb65ec4d7c5959444f8de2efd',
  PhoenixBookCurrencyChange: '4311ba788781fa65d05c090a14654fb02ffae131fede884cde17c6c6730f2800',
  PhoenixBookGetAAADetails: '8022a1fb9f1bc8e5e3c1ebe5649d215f3c117f66ecc3cc07b7201f3a36916219',
  PhoenixBookpUpdateAAADetails: 'e5ce43b661e533827587226cc8e1857317ebbdc05c56daee33f044305363b5cf',
  PhoenixBookCustPayInfo: 'bd2167344f9b5b33d2f06cb728d6002ef01b676de642c11955b62e245f0678d7',
  PhoenixBookGetNightlyProducts: '6a0ab007049107cbf1164786dec8e1b84bbc4b085a9095eba07b9fda159ed6aa',
  PhoenixBookDefaultSelectionData: '380c4103dabe0a99efec3f6b1f7ed3f6adcfdecc44bbad2d3953b01886815a02',
  PhoenixBookExtendCartExpiration: '4368b98f990015a67e32374be20ebf12816e7ed74c309d036bbfaf768247bdbe',
  PhoenixBookHeroBanner: 'd2e8606e29365af27c163581632dec79859061a4c93a87bdf7a817f60dfd17d6',
  PhoenixBookGetAlerts: '32c5757f69c57807e5226b9d6f49683be00c940b0b9ba766a81f746b7b3c168e',
  PhoenixBookCancellationPolicy: 'ea44f55309085169f6586e0a255ad599da40464a4cfbc763a25c9f87a13f2c20',
  PhoenixBookHotelHeaderData: '018b971a06886d6d2ca3f77747bf9f9c2cc8d49638bf25a1c3429277c4627c9f',
  PhoenixBookHotelHeaderDataAuth: 'f8c07a2cd53a25eba2675ea771808f8a761aeb28dcdbb123aa37b9730e48896d',
  PhoenixBookGetMessages: '78389de11eddc9a903798ea5c48ee8a5f785e6f28219ce20b08a9fba17f0da5c',
  PhoenixBookHouseKeeping: '03aac4c76cb656ca3973aeea19c1ced8df1809f8f88cead175a051fa37d5649d',
  PhoenixBookGetJoinNow: '16ecbe465763b04424d0dc2b0b1db0097f8c550242050f6e2912d50025edb44e',
  PhoenixBookMemberInformation: 'f1a568834df34aab8a70d1a840466404ab765b299950e2325c35e39ec49f9f7b',
  PhoenixBookGetLookupRewards: '47efe6ac3efc24057e11f458ded3b3221b59c67cae91c3240bf1cab836b2b97f',
  PhoenixBookPropPayInfo: 'c05e97c2dd1e5f70ec84d00c0852df6d48c1b0ba3aee63291d26733c4642e9c0',
  phoenixBookSuggestedPlacesDetailsQuery: '73546b987f581643cd2e02b0cd6ec040cb60bcaa4494806ab54acf815e2996bc',
  phoenixBookSuggestedPlacesQuery: '29379ce49c137993be1c0c9af3890272ce5f6d61240f684ec957987cc8346bdf',
  PhoenixBookPrepayRates: '60d2d34915ad4c18f2d7c9f1bafffeb12ca1987ea021bfcd5896c68fdf13b25d',
  PhoenixBookGetRateDetails: '84f5c72aa0f43b0da7212d5ad582f9f5fcfa634d72cd1d270aff19bb6efdb63b',
  PhoenixBookNotificationStatus: 'f98379d13af0e50b94fde170002b0d17716ffbe20eaeecef3532148b6afc8bcd',
  PhoenixBookNotificationStatusUnAuth: '29de4b1cfd90e813db708dfabc3e1532112c3a0042f6711a150c1b325f5bf8ef',
  PhoenixBookGetRedemptionData: '300f65f0dd8d44a76db117e1191b18efee4a4b8f06fcb7e1a8d90c326263d116',
  PhoenixBookReservationDetails: '998d44a481038c0cd20efa791b6c29eb65cb1316c147487f0072891df70b4f8a',
  PhoenixBookResSummary: '695f4160e933f1339e027e9b396093698b2da703c69e4a3a55f5fddc65c4e249',
  PhoenixBookGetRoomDetailsFeatures: 'f2797e12ca8e9edddec0e38e22a128866dccdcf98b0865a28414ab9e5c3b72f8',
  PhoenixBookRoomImages: '77ed27f32ccdc05391f96a1b3f95278e43c4415bc9514e3b665dc36ca8026b4c',
  PhoenixBookRoomRequests: '5991cda1f4dfc72fc72842cbe5a2052666ec51c75562bea3254449bb76e51c5f',
  PhoenixBookRoomRequestsUnAuth: '46423cf64eaeac9b5b094bc90ffffd382b23107c075e4c161bf284d260cff1dc',
  PhoenixBookUpdateSaveProperties: 'b0c0bb536606ce0380934f47ab04b8986298e9361ac485e4136adf7c0abb8df1',
  PhoenixBookSearchProductInfo: '6f005c63b04a77dcf75f54906eea74c256ba4b2b70db244a4c78bf428ede3da6',
  PhoenixBookSearchProductsByProperty: '2f636a2fd80d48e05a56091386c178724b703344fd46a190db3cb964496220cf',
  PhoenixBookSearchProductsByPropertyNonErs: 'b38434b0fa7524685ef939faf564ba81aa83f703b006265d07c4111605061ddf',
  PhoenixBookShareBySMS: '7ad857b52eade501a7c02e273944210bb5b9c503ef5b3fb1bca644f7ce98b080',
  PhoenixShareByEmail: '4fe3131e0013a5fa13bb0a6f3b18ad856eab6f295302f45d27b396bdd548bbea',
  PhoenixBookLookupsByType: '9e622987ce77b3750ffe72a8eb4aeb65c74718cd0b5544a4c4e2528e8d1e6bbc',
  PhoenixBookStateLookup: 'e8d14086b9aee472f2b869df8f27cd1b9bbaa2ef8577b522cfd41f4abd5d57c2',
  PhoenixBookStayDate: '88104c8dca3e3dc488d958fe1ac4d4c394c603de86f370415547d77f2cb9f416',
  PhoenixBookStayDetails: 'c39d9ddc9f35a9fed17e98387bd49f275e96cbf8ebcd753a72f416313468da9d',
  PhoenixBookStickyBar: 'd943abadf27f80dd7ae27fc6efad100401ce7736f36ab17086342d9e849b906d',
  PhoenixBookSummaryOfChargesAuth: 'afc0a549aac2274802eb139547eafa359702049c236b7480708a8bf182caf612',
  PhoenixBookSOCCart: 'ce076c093121ea403b86cc75d055189b542a900f10713be68388668ec8a0b4d3',
  PhoenixBookSummaryOfChargesUnAuth: '614c83ced6f29395e64999b8debe6e608d83a96a38df640bdb00c6673b5aa8dc',
  PhoenixBookTIQuoteUrl: 'c0f54d91d152abd6d4f1b1db48dfdf4964a9c23f15af8a145531dfa24a19098c',
  PhoenixBookTIQuote: '83796bc9335d68e7c3aaa56cb561013c2806d691cbd2f5eb5e7dd8bcf522593a',
  PhoenixBookUpdateCart: '9d34fb666556c542acf00f1a6317ae764553da84bf391671a1a84a975d39c43a',
  PhoenixBookUpdateCartExpiration: '3651ab989d8bba9cdfe92d22f73591d6168d67b0a342b0dab54aa52e1592cd6e',
  PhoenixBookUpdateCartFNA: '8adbdcd6e7031ab17cf0fbcba6aa12143c92df7af6af6274fe2ee4f175a86c1a',
  PhoenixBookUpdateInsuranceCart: '292ecc0f65e5a10eaebef3161089de15b6ec63477028af423d525a6394549633',
  PhoenixBookUpdateMemberInformation: '7524a53950ea9988160d68ce3639405dbb988220a6aaed0a0d255c733fe89ecf',
  PhoenixBookUpdateCustomerPreferences: '8e37dbcf3176a332734c81252b32281276dba2de89c4a128fc19f1096845434e',
  PhoenixBookPropertyData: '097ff8d740e48931378abde4850e9839becac2d3cfe0e9a153516917ac26bf97',
  PhoenixBookRoomUpgradeData: '1f02527cce111d82cebac132ae2a6f7fc3a301f4050326f52f798cf0a989fb4a',
  PhoenixBookRoomUpgradeUserData: '2a9c1d7347ac9ec04d967f9565be955dbc9de0071cb6e58bf5f92657719c8fe5',
  PhoenixBookSnaData: 'e1461cd251955a27284aef06fe70062ae62f3d9728790842857b09bcce1eed5c',
  PhoenixBookValidateAaaNumber: '718716383a85d22a00b4d67b5523edac9036d7f256c17d1a2281de9f13f65c6c',
  phoenixBookHotelAmenities: '9216291b30bd67fe7062fa5d3f05d6f913d2512ac845e10cac14cd1e2150ff80',
  phoenixBookHQVPhotogalleryCall: '2b1d3f7ccf486c6652f137461c37713ba24dc4436292fb6ef266dd1a5bb5bf4a',
  phoenixBookPropertyInfoCall: 'f740d5a82b4de1be12cc13edc913bacaff391eaed630c53591922cd21af57695',
  PhoenixBookCurrencyExchange: '0a30f77a652e789bff7307980c2d1b86608194dc12960d16722e33a3b48fbdb3',
  PhoenixBookCustomerPreferences: 'a3376f08ec8ba70fbfe381a80d3d794beb50f5d068a8850cf408720bae07ffbc',
  PhoenixBookRecommendedProductByUserPreference: 'a0c8d627a93ef2343feeceaee68a656ab84eb57e30f8180039bc7a407b4acfe2',
  PhoenixBookCancelledReservationDetails: '48322c5385992d049747e8087745211efe12e7327b5e1d820d6e405c26e4e240',
  PhoenixBookCurrentReservationDetails: 'b6e14deda579d594512249694bb1d9a3d4e68d799210645e306666a66ae5936a',
  PhoenixBookGetProductFromOrder: 'dd4d9087801460c058c8665fb50f6dd1e5f531a88ed69ecc2453a05a4c07217f',
  PhoenixBookPrefPluBook: 'cbca8437ab7ec88a579be7b2a57b1bb14d97ce4468ca1b88d5de1917a0498d41',
  PhoenixBookRoomOverviewDetails: 'fc1a5e8beb17bee950791dee53798c362eeabd577e3b9df2e2421009b5fdc87c',
  PhoenixBookAuthRoomOverviewDetails: '936a1a86f4c08de91eb98943624ca11221a7e43d748f3662ced853548d19d995',
  PhoenixBookSmsCountryCodesLookup: '001d8cc1becec8ba35fb875948a8ee266dfc91f162842e1db6029adde4a4a6cf',
  PhoenixBookUserFormTitleLookup: 'ce5d7f69d0adfc1f2237de3a9bcb20bd944fd1dce17c4b9e3a717813eb039992',
  PhoenixBookTravelInsurance: 'aec5e8180ae093ea7ecfa5be35acbcf30d4be8262c995b28fdc5ed00d219cab7',
  PhoenixBookUpdateOrder: '51484a6ab1eee5538662dbc997d69e969f82ccf99521ff01ce0f95e2ee7c7d88',
  PhoenixBookUpdateUserInformation: '0d89a7f625fbf9420c0e352fa5db4c5a37c6d046783a23a45813dd61e2defebf',
  PhoenixBookGetProductInfo: '9a908ab2aa5ef1fbfe82d3c960aa0319dfcef32ef3b3eaa0549648c328f097eb',
  PhoenixBookTranslateText: '131e344ff3a626f2322108ced5d15d3723b0b907aa7547373e5ac4c4a0e79109',
  PhoenixBookRoomUpgradeUpsellData: 'ad0addfba45586ce6455e5eb095d1419d62ae049bb1aa82127da68e06e6a1d4b',
};

export const LOADER_CONFIGS = {
  ConfirmationMessageLoaderConfig: [{ count: 1 }, { count: 3 }, { count: 2 }],
  CreditCardAccordionLoaderConfig: [{ count: 1, height: 40 }],
  StayDetailsLoadingConfig: [{ count: 1, height: 40 }, { count: 3 }, { count: 1, height: 2 }, { count: 1 }],
  SummaryOfChargesLoadingConfig: [{ count: 1, height: 40 }, { count: 4 }, { count: 1, height: 2 }, { count: 1 }],
  TravelAgentLoadingConfig: [{ count: 1, height: 40 }, { count: 4 }, { count: 1, height: 2 }, { count: 1 }],
  ReservationStayDetailsLoadingConfig: [
    { count: 1, height: 60 },
    { count: 1, height: 10, width: 100 },
    { count: 1, height: 15, width: 100 },
    { count: 2, height: 30 },
    { count: 1, height: 80 },
  ],
  ReservationGreetingLoadingConfig: [
    { count: 1, height: 60, width: 80 },
    { count: 2, height: 30 },
    { count: 2, height: 5 },
    { count: 2, height: 10 },
    { count: 1, height: 32 },
  ],
  ReservationDetailsLoadingConfig: [
    { count: 1, height: 50 },
    { count: 3, height: 20 },
    { count: 1, height: 20, width: 100 },
  ],
  MobileReservationDetailsLoadingConfig: [
    { count: 1, height: 50 },
    { count: 1, height: 20, width: 100 },
    { count: 3, height: 20 },
    { count: 1, height: 20, width: 100 },
  ],
  RrdGiReservationDetailsLoadingConfig: [{ count: 1, height: 20 }],
  RoomRequestsLoadingConfig: [
    { count: 1, height: 50 },
    { count: 1, height: 20 },
    { count: 1, height: 20 },
    { count: 1, height: 20, width: 100 },
    { count: 3, height: 20 },
    { count: 1, height: 60, width: 250 },
  ],
  StickyBarLoadingConfig: [{ count: 1, height: 100 }],
  StickyBarStayLoadingConfig: [
    { count: 1, height: 10, width: 200 },
    { count: 1, height: 14, width: 250 },
  ],
  StickyBarTimerLoadingConfig: [{ count: 1, height: 15, width: 160 }],
  StickyBarButtonLoadingConfig: [{ count: 1, height: 36, width: 140 }],
  MemberInformationLoadingConfig: [{ count: 1, height: 200 }],
  BookingAcknowledgementLoadingConfig: [
    { count: 1, height: 10 },
    { count: 1, height: 52, width: 269 },
  ],
  RedemptionPanelLoadingConfigs: [{ count: 1, height: 200 }],
  RoomDetailsModalLoadingConfig: [
    { count: 1, height: 200 },
    { count: 1, height: 100 },
    { count: 1, height: 100 },
    { count: 1, height: 100 },
    { count: 1, height: 100 },
  ],
  RateDetailsModalLoadingConfig: [
    { count: 1, height: 21 },
    { count: 1, height: 56 },
    { count: 1, height: 56 },
    { count: 1, height: 56 },
    { count: 1, height: 56 },
  ],
  CoBrandCreditCardLoadingConfig: [
    { count: 1, height: 30 },
    { count: 5, height: 15 },
  ],
  CoBrandCreditCardLoadingMobileConfig: [
    { count: 1, height: 30 },
    { count: 10, height: 15 },
  ],
  PrepayRatesLoadingConfig: [{ count: 1, height: 40 }],
  ErsFiltersLoadingConfig: [{ count: 1, height: 50, width: 100 }],
  HotelHeaderImageLoadingConfig: [{ count: 1, height: 100, width: 200 }],
  HotelHeaderDetailsLoadingConfig: [
    { count: 1, height: 26, width: 200 },
    { count: 1, height: 16, width: 300 },
  ],
  RoomDetailsLoadingConfigMobile: [
    { count: 1, height: 40, width: 300 },
    { count: 2, height: 20, width: 200 },
    { count: 2, height: 10, width: 100 },
    { count: 1, height: 50, width: 300 },
  ],
  RoomDetailsLoadingConfigDesktop: [
    { count: 1, height: 40, width: 400 },
    { count: 2, height: 20, width: 300 },
    { count: 2, height: 10, width: 100 },
    { count: 1, height: 50, width: 400 },
  ],
  RoomImageLoadingConfigMobile: [{ count: 1, height: 250, width: 300 }],
  RoomImageLoadingConfigDesktop: [{ count: 1, height: 250, width: 375 }],
  UpcomingResBannerLoadingConfig: [
    { count: 1, height: 40 },
    { count: 1, height: 40 },
  ],
  CancelledResDetailsLoadingConfig: [
    { count: 1, height: 60 },
    { count: 1, height: 250 },
  ],
  MobileCancelledResDetailsLoadingConfig: [
    { count: 1, height: 75 },
    { count: 1, height: 460 },
  ],
  AlertMessageLoadingConfig: [{ count: 1, height: 55 }],
  CurrenRoomDetailsLoaderConfig: [{ count: 1, height: 174 }],
  CurrenRoomDetailsMobileLoaderConfig: [{ count: 1, height: 278 }],
  ButtonLoadingConfigs: [{ count: 1, height: 40 }],
  UpsellCardLoadingConfig: [
    { count: 1, height: 285, borderRadius: '14px 0 0 14px' },
    { count: 2, height: 30 },
    { count: 1, height: 20, width: 100 },
    { count: 1, height: 70, baseColor: 'white' },
    { count: 1, height: 60 },
  ],
  TabletUpsellCardLoadingConfig: [
    { count: 1, height: 200, borderRadius: '14px 0 0 14px' },
    { count: 2, height: 20 },
    { count: 1, height: 20, width: 100 },
    { count: 1, height: 20, baseColor: 'white' },
    { count: 1, height: 50 },
  ],
  MobileUpsellCardLoadingConfig: [
    { count: 1, height: 200, borderRadius: '14px 14px 0 0' },
    { count: 2, height: 30 },
    { count: 1, height: 20, width: 80 },
    { count: 1, height: 30, baseColor: 'white' },
    { count: 2, height: 30, width: 150 },
    { count: 1, height: 50 },
  ],
  CancelModalLoading: [
    { count: 1, height: 40, width: 200 },
    { count: 1, height: 30, width: 100 },
    { count: 1, height: 30, width: 100 },
    { count: 1, height: 60, width: 200 },
  ],
  TabListLoadingConfig: [
    {
      height: 67,
      width: 170,
      count: 3,
      inline: true,
      borderRadius: '0.25rem 0.25rem 0 0',
      style: { marginRight: '0.25rem' },
    },
  ],
};

export const TRACKING_CONSTANT = {
  HEADER_TILE: 'Header',
  HEADER_PROPERTY_NAME: 'Property Name',
  HEADER_ADDRESS_LINK: 'Address Link',
  HEADER_PHONE_NO: 'Phone Number',
  HEADER_RATINGS: 'Ratings',
  HEADER_REVIEWS: 'Reviews',
  HEADER_HOTEL_INFO: 'Hotel Details',
  ROOM_REQUEST_TILE: 'Room Request',
  RATE_CARD: 'RateCard',
  ROOM_DETAILS: 'RoomDetailslink',
  PROPERTY_CARD: 'PropertyCard',
  RATE_DETAILS: 'Rate Details',
  APPLY: 'Apply',
  CLEAR: 'Clear',
  DRAWER_OPEN: 'Drawer Open',
  POP_UP_OPEN: 'Pop-up Open',
  SOC_TILE: 'Summary of Charges',
  GUEST_INFO_TILE: 'Guest Info',
  EDIT: 'Edit',
  PAYMENT_INFO_TILE: 'Payment Info',
  TRAVEL_AGENT_TILE: 'Travel Agent',
  RESCONFO_TILE: 'ResConfo Tile',
  ALREADY_MEMBER: 'Already_Member',
  INTERNAL_LINK: 'Internal',
  EXTERNAL_LINK: 'External',
  OPTION_WIDGET: 'optionWidget',
  STAY_DETAILS: 'Stay Details',
  BODY: 'Body',
  SIGN_IN_FASTER_BOOKING: 'SigninFaster_Booking',
  PAYMENT_OPTIONS: 'Payment Options',
  CLICK_TO_PAY: 'Click to Pay',
  BANNER: 'Banner',
  RES_CONFIRM: 'resConfirm',
  SHOW_TAX_DISABLED: 'Filter|Full Price Display - Disabled|Internal',
  SHOW_TAX_ENABLED: 'Filter|Full Price Display - Enabled|Internal',
  SHOW_TAX_DISABLED_MODIFY_RLM: 'Modify|Full Price Display - Disabled|Internal',
  SHOW_TAX_ENABLED_MODIFY_RLM: 'Modify|Full Price Display - Enabled|Internal',
  REDEEM_POINTS_REDEMPTION_PANEL: 'ModifyConfirmChanges|RedeemPoints|Internal',
  REDEEM_AWARDS_REDEMPTION_PANEL: 'ModifyConfirmChanges|RedeemAwards|Internal',
  RLM_CAROUSEL_PREV_ARROW: 'RLM 2.0|CarouselLeft|Internal',
  RLM_CAROUSEL_NEXT_ARROW: 'RLM 2.0|CarouselRight|Internal',
  CLICK_TRACK_ADDITIONAL_VAR: 'event167,list3=',
  AAA_SUBMIT_TRACK: {
    trackingProperties: {
      trackingDescription: 'Submit',
      clickTrack: true,
      trackingContentPosition: 'AAA',
    },
  },
  RATE_DETAILS_TRACK: {
    trackingProperties: {
      trackingDescription: 'Rate Details Link',
      clickTrack: true,
      trackingContentPosition: 'Reservation Summary',
    },
  },
  STAY_DETAILS_RESSUM_TRACK: {
    trackingProperties: {
      trackingDescription: 'Stay Details',
      clickTrack: true,
      trackingContentPosition: 'ResConfo Tile',
    },
  },
  SELECT_BTN_TRACK: {
    standardTabMember: {
      trackingProperties: {
        trackingContentPosition: 'standard Tab',
        trackingDescription: 'Member Rate Select Button',
        clickTrack: true,
      },
    },
    standardTabNonMember: {
      trackingProperties: {
        trackingContentPosition: 'standard Tab',
        trackingDescription: 'Non-Member Rate Select Button',
        clickTrack: true,
      },
    },
    dealsAndPackagesTabMember: {
      trackingProperties: {
        trackingContentPosition: 'dealsAndPackages Tab',
        trackingDescription: 'Member Rate Select Button',
        clickTrack: true,
      },
    },
    dealsAndPackagesTabNonMember: {
      trackingProperties: {
        trackingContentPosition: 'dealsAndPackages Tab',
        trackingDescription: 'Non-Member Rate Select Button',
        clickTrack: true,
      },
    },
    prepayTabMember: {
      trackingProperties: {
        trackingContentPosition: 'prepay Tab',
        trackingDescription: 'Member Rate Select Button',
        clickTrack: true,
      },
    },
    prepayTabNonMember: {
      trackingProperties: {
        trackingContentPosition: 'prepay Tab',
        trackingDescription: 'Non-Member Rate Select Button',
        clickTrack: true,
      },
    },
    specialRatesTabMember: {
      trackingProperties: {
        trackingContentPosition: 'special-rates Tab',
        trackingDescription: 'Member Rate Select Button',
        clickTrack: true,
      },
    },
    specialRatesTabNonMember: {
      trackingProperties: {
        trackingContentPosition: 'special-rates Tab',
        trackingDescription: 'Non-Member Rate Select Button',
        clickTrack: true,
      },
    },
    redemptionTabMember: {
      trackingProperties: {
        trackingContentPosition: 'redemption Tab',
        trackingDescription: 'Member Rate Select Button',
        clickTrack: true,
      },
    },
    groupTabMember: {
      trackingProperties: {
        trackingContentPosition: 'group Tab',
        trackingDescription: 'Member Rate Select Button',
        clickTrack: true,
      },
    },
    modifyRlmPage: {
      trackingProperties: {
        trackingContentPosition: 'Modify',
        trackingDescription: 'Update',
        clickTrack: true,
      },
    },
  },
};

export const DATALAYER_CONSTANT = {
  headerKeys: [
    'accept-language',
    'user-agent',
    'x-request-id',
    'x-service-id',
    'x-forwarded-host',
    'x-host',
    'x-xm-site-id',
    'x-lite-site',
    'x-request-uri',
    'x-request-host',
    'sec-fetch-user',
    'x-forwarded-for',
    'x-schema',
    'sec-fetch-mode',
    'x-forwarded-port',
    'sec-fetch-site',
    'sec-fetch-dest',
    'x-forwarded-proto',
    'referer',
    'forwarded',
    'messageid',
    'x-akamai-edgescape',
    'x-akamai-device-characteristics',
    'x-client-ip',
    'x-akamai-config-log-detail',
    'akamai-origin-hop',
    'digitalserviceswitch',
  ],
  sessionDataKeys: {
    marshaCode: 'cacheData.data.AriesReservation.propertyId',
  },
};

export const enum hardCodedAllLocale {
  en = 'en-US',
  es = 'es-ES',
}
export const VITAL_UXL_CALLS_COMPONENT = {
  [GQL_OPT_NAME.searchProductByProperty]: 'TabList',
  [GQL_OPT_NAME.reservationDetails]: 'ReservationDetails',
  [GQL_OPT_NAME.memberInformation]: 'MemberInformation',
  [GQL_OPT_NAME.updateMemberInformation]: 'MemberInformation',
  [GQL_OPT_NAME.propertyPaymentInfo]: 'CreditCard',
  [GQL_OPT_NAME.customerPaymentInfo]: 'CreditCard',
  [GQL_OPT_NAME.hotelCancellationPolicy]: 'HotelCancellationPolicy',
  [GQL_OPT_NAME.roomRequests]: 'RoomRequests',
  [GQL_OPT_NAME.updateCart]: 'UpdateCart',
  [GQL_OPT_NAME.updateProfile]: 'RoomRequests',
  [GQL_OPT_NAME.countriesAndState]: 'MemberInformation',
  [GQL_OPT_NAME.userFormTitles]: 'MemberInformation',
  [GQL_OPT_NAME.smsCountryCodes]: 'MemberInformation',
  [GQL_OPT_NAME.roomOverview]: 'RoomOverview',
  [GQL_OPT_NAME.authRoomOverview]: 'RoomOverview',
  [GQL_OPT_NAME.roomRequestsUnAuth]: 'RoomRequests',
  [GQL_OPT_NAME.confirmChangesModal]: 'ConfirmChangesModal',
};

export const HotelGalleryPhotoViewerConstants = {
  IMAGE_URL_DOMAIN: 'https://cache.marriott.com',
  GOOGLE_MAP_PIN_URL: 'https://www.google.com/maps/search/?api=1&query=',
  BAIDU_MAP_PIN_URL: 'http://api.map.baidu.com/marker?location=',
  BAIDU_MAP_URL: 'https://map.baidu.com/?autoOpen=true&latlng=',
  defaultCategoryList: ['hotelView', 'suites', 'guestRooms', 'recreationAndFitness', 'dining'],
  SM_PHOTOS: 'Photos',
  SM_SCROLL_LIMIT: 205,
  SM_SECTIONS: [
    'hqv-photo-section',
    'hqv-location-section',
    'hqv-hotel-info-section',
    'hqv-amenities-section',
    'hqv-property-details-section',
  ],
  SM_VIEW_HOTEL_WEBSITE_LINK: '/hotels/travel/',
  OUTPUT_QUALITY: '90',
  MOBILE_IMAGE_WIDTH: 358,
  MOBILE_IMAGE_HEIGHT: 160,
  TABLET_IMAGE_WIDTH: 451,
  TABLET_IMAGE_HEIGHT: 366,
  DESKTOP_IMAGE_WIDTH: 562,
  DESKTOP_IMAGE_HEIGHT: 366,
  DESKTOP_VIEWPORT_SIZE: 1024,
  TABLET_VIEWPORT_SIZE: 768,
  URL_MAP_PIN: 123,
};
export const EnrollmentSourceCode = {
  USMI: 'USMI',
  MSAN: 'MSAN',
};

export const ONE_CLICK_JOIN_ERR_MSG = {
  EMAIL_DUPLICATE_ERROR: 'emailDuplicateError',
  API_ERROR: 'uxlFailureErrorMessage',
};

export const ONE_CLICK_JOIN_SUCCESS_MSG = {
  NEW_MEMBER_ENROLL: 'newMemberNumberMsg',
};

export const COUNTRY_MOBILE_NUMBER_MIN_LENGTH = {
  [constants.HONGKONG_MOBILE_CODE]: 8,
  [constants.CHINA_MOBILE_CODE]: 11,
  // Add more countries and their corresponding min lengths here
  [constants.DEFAULT]: 10,
};

export const ENV_CONSTANTS = {
  uxlImageCacheDomain: 'UXL_IMAGE_CACHE_DOMAIN',
  dacLogoEnable: 'NEXT_PUBLIC_DAC_LOGO_ENABLE',
};

export const currencyLocales = [
  {
    currency: 'USD',
    locale: 'en-US',
  },
  {
    currency: 'AUD',
    locale: 'en-AU',
  },
  {
    currency: 'CAD',
    locale: 'en-CA',
  },
  {
    currency: 'EUR',
    locale: 'en-US',
  },
  {
    currency: 'GBP',
    locale: 'en-US',
  },
  {
    currency: 'HKD',
    locale: 'en-US',
  },
  {
    currency: 'JPY',
    locale: 'en-US',
  },
  {
    currency: 'MXN',
    locale: 'es-MX',
  },
  {
    currency: 'CNY',
    locale: 'zh-CN',
  },
  {
    currency: 'ZAR',
    locale: 'en-ZA',
  },
  {
    currency: 'IDR',
    locale: 'id-ID',
  },
  {
    currency: 'RUB',
    locale: 'ru-RU',
  },
  {
    currency: 'KRW',
    locale: 'en-US',
  },
  {
    currency: 'ILS',
    locale: 'en-US',
  },
];

export const MGP_CONSTANTS = {
  CHECK_IN_ELIGIBLE: 'CHECK_IN_ELIGIBLE',
  CHECK_IN_MODIFIABLE: 'CHECK_IN_MODIFIABLE',
  CHECK_OUT_ELIGIBLE: 'CHECK_OUT_ELIGIBLE',
  CHECKED_IN_HOUSE: 'CHECKED_IN_HOUSE',
};

export const GQL_OPT_NAME_DTT = {
  heroBanner: 'PhoenixBookDTTHeroBanner',
  reservationDetails: 'PhoenixBookDTTReservationDetails',
  hotelAlerts: 'PhoenixBookDTTGetAlerts',
  propertyData: 'PhoenixBookDTTPropertyData',
  snaData: 'PhoenixBookDTTSnaData',
  hotelCancellationPolicy: 'PhoenixBookDTTCancellationPolicy',
  hotelCancellationPolicyByCart: 'PhoenixBookDTTCartCancellationPolicy',
  memberOptions: 'PhoenixBookGetLookupRewards',
  orderDetails: 'PhoenixBookDTTGetOrderDetails',
  reservationSummary: 'PhoenixBookDTTResSummary',
  stayDetails: 'PhoenixBookDTTStayDetails',
  travelInsurance: 'PhoenixBookDTTTIQuoteUrl',
  currencyChange: 'PhoenixBookDTTCurrencyChange',
  confirmationMessage: 'PhoenixBookDTTConfirmationMessage',
  roomUpgradeUserData: 'PhoenixBookDTTRoomUpgradeUserData',
  hotelHeader: 'PhoenixBookDTTHotelHeaderData',
  hotelHeaderAuth: 'PhoenixBookDTTHotelHeaderDataAuth',
  roomUpgradeData: 'PhoenixBookDTTRoomUpgradeData',
  lookupByType: 'PhoenixBookDTTLookupsByType',
  shareBySMS: 'PhoenixBookDTTShareBySMS',
  addTOCalendar: 'PhoenixBookDTTAddToCalendar',
  summaryOfChargesAuth: 'PhoenixBookDTTSummaryOfChargesAuth',
  summaryOfChargesUnAuth: 'PhoenixBookDTTSummaryOfChargesUnAuth',
  summaryOfChargesCart: 'PhoenixBookDTTSOCCart',
  coBrandCreditCard: 'PhoenixBookDTTCoBrandCreditCard',
  redemptionNotification: 'PhoenixBookDTTNotificationStatus',
  redemptionNotificationUnAuth: 'PhoenixBookDTTNotificationStatusUnAuth',
  shareByEmail: 'PhoenixBookDTTShareByEmail',
  hotelMessages: 'PhoenixBookDTTGetMessages',
  roomRequests: 'PhoenixBookDTTRoomRequests',
  roomRequestsUnAuth: 'PhoenixBookDTTRoomRequestsUnAuth',
  roomImages: 'PhoenixBookDTTRoomImages',
  stickyBar: 'PhoenixBookDTTStickyBar',
  stayDate: 'PhoenixBookDTTStayDate',
  generateOTP: 'PhoenixBookDttGenerateAccountOneTimePassword',
  prePayConsent: 'PhoenixBookDTTGetPrePayConsent',
  houseKeeping: 'PhoenixBookDTTHouseKeeping',
  updateCart: 'PhoenixBookDTTUpdateCart',
  updateProfile: 'PhoenixBookUpdateCustomerPreferences',
  updateMemberInformation: 'PhoenixBookUpdateMemberInformation',
  updateUserInformation: 'PhoenixBookDTTUpdateUserInformation',
  propertyPaymentInfo: 'PhoenixBookDTTPropPayInfo',
  customerPaymentInfo: 'PhoenixBookDTTCustPayInfo',
  prefPlusBook: 'PhoenixBookDTTPrefPluBook',
  memberInformation: 'PhoenixBookMemberInformation',
  countriesAndState: 'PhoenixBookCountryStateLookup',
  userFormTitles: 'PhoenixBookUserFormTitleLookup',
  smsCountryCodes: 'PhoenixBookSmsCountryCodesLookup',
  travelInsuranceWidget: 'PhoenixBookDTTTIQuote',
  paymentmethods: 'PhoenixBookDTTPaymentMethods',
  redemptionPanel: 'PhoenixBookDTTGetRedemptionData',
  stateLookUp: 'PhoenixBookDTTStateLookup',
  updateInsuranceCart: 'PhoenixBookDTTUpdateInsuranceCart',
  updateCartFNA: 'PhoenixBookDTTUpdateCartFNA',
  cartCoBrandCreditCart: 'PhoenixBookDTTCartCoBrandCreditCard',
  creditCardInfo: 'PhoenixBookDTTCreditCardInfo',
  extendCartExpiration: 'PhoenixBookExtendCartExpiration',
  updateCartExpiration: 'PhoenixBookDTTUpdateCartExpiration',
  customerSavedProperties: 'PhoenixBookUpdateSaveProperties',
  searchProductByProperty: 'PhoenixBookDTTSearchProductsByProperty',
  searchProductByPropertyNonErs: 'PhoenixBookDTTSearchProductsByPropertyNonErs',
  propertyDetails: 'PhoenixBookDTTProperty',
  getRoomDetailsFeatures: 'PhoenixBookDTTGetRoomDetailsFeatures',
  getRateDetails: 'PhoenixBookDTTGetRateDetails',
  defaultSelection: 'PhoenixBookDTTDefaultSelectionData',
  getStickyBar: 'PhoenixBookDTTSuggestedPlacesDetailsQuery',
  getDestination: 'PhoenixBookDTTSuggestedPlacesQuery',
  customPayment: 'PhoenixBookDTTGetNightlyProducts',
  getAAADetails: 'PhoenixBookDTTGetAAADetails',
  addAAADetails: 'PhoenixBookAddAAADetails',
  updateAAADetails: 'PhoenixBookpUpdateAAADetails',
  validateAAANumber: 'PhoenixBookDTTValidateAaaNumber',
  joinNowMemberOptions: 'PhoenixBookDTTGetJoinNow',
  prepayRates: 'PhoenixBookDTTPrepayRates',
  roomOverview: 'PhoenixBookDTTRoomOverviewDetails',
  searchProduct: 'PhoenixBookDTTSearchProductInfo',
  checkNow: 'PhoenixBookDTTCheckInNow',
  travelInsuranceBanner: 'PhoenixBookDTTTravelInsurance',
  getProductFromOrder: 'PhoenixBookDTTGetProductFromOrder',
  updateOrder: 'PhoenixBookDTTUpdateOrder',
  authRoomOverview: 'PhoenixBookDTTAuthRoomOverviewDetails',
  cancelledReservationDetails: 'PhoenixBookDTTCancelledReservationDetails',
  currentReservationDetails: 'PhoenixBookDTTCurrentReservationDetails',
  recommendedProductByUserPreference: 'PhoenixBookDTTRecommendedProductByUserPreference',
  customerPreferences: 'PhoenixBookDTTCustomerPreferences',
  currencyExchange: 'PhoenixBookDTTCurrencyExchange',
  amenitiesList: 'PhoenixBookDTTHotelAmenities',
  photoGallery: 'PhoenixBookDTTHQVPhotogalleryCall',
  information: 'PhoenixBookDTTPropertyInfoCall',
  confirmChangesModal: 'PhoenixBookDTTGetProductInfo',
  translateText: 'PhoenixBookDTTTranslateText',
  roomUpgradeUpsellData: 'PhoenixBookDTTRoomUpgradeUpsellData',
  productAddOns: 'PhoenixBookDTTGetProductDetails',
  createCart: 'PhoenixBookDTTCreateCart',
  createOrder: 'PhoenixBookDTTCreateOrder',
  createTravelInsuranceOrder: 'PhoenixBookDTTCreateTravelInsuranceOrder',
  createTravelInsuranceCart: 'PhoenixBookDTTCreateTravelInsuranceCart',
  selectRateCurrencyConversion: 'PhoenixBookConvertCurrency',
  getCartPrice: 'PhoenixBookDTTCartPrice',
  roomImagesCTS: 'PhoenixBookDTTRoomImagesCTS',
  nonRoomProductFlyout: 'PhoenixBookDTTNRPFlyout',
  associateProfileWithCart: 'PhoenixBookDTTAssociateProfileWithCart',
};

export const GQL_OPT_SIGN_DTT = {
  PhoenixBookDTTConsents: '233774a1fc61b249277520a308317a1c59c6269aff0775a03c5149455be582c0',
  PhoenixBookDTTContentLabels: '3f6466cf88861d02abb6d98ff147366ba83cb4dad0b50025643dc02b91aa12be',
  ProfileCountryCode: '849039589ea9deaedee62fd88d6575f465601a5ab7fa1f81e9328376f9db77e9',
  PhoenixBookDTTCartSOC: '79360670142dc3c2a65b1099657de3110566bee2a28142ff232bfa3e577272ca',
  PhoenixBookDTTCreateCart: '7f3d2ac665b5b255154de1f5a7c9f6801d5fc2fc6f74286fa5a4d236e935a3b6',
  PhoenixBookDTTCreateGuestList: '3e10dd7ef497432d50556cbf650e26e6f24e1920d1ddbf70c6969bb8c1f7c821',
  PhoenixBookDTTCreateOrder: '3d05bf8400064159b896bc392141d78a29f5e09629f0edf01497d342e197a974',
  PhoenixBookDTTCreateTravelInsuranceCart: 'b3bb4e15fee9eb72dbb8fd0fd389f7c74834e0dee35032a9ee438dad1b02b463',
  PhoenixBookDTTCreateTravelInsuranceOrder: 'a314cd7839e0df00f39304de730d65548c50103e6b2be7fbcc8162d519e7c9a8',
  PhoenixBookDTTCustomer: 'c1c3967859260d719f055a3a7a65d1cc1c696e66b3b9fe32d1afa9742dd556f4',
  PhoenixBookDTTCustomerContactInfo: '1a9dfb0b75bfc2ea792ab5227dfab2e182af39904ecfd98dfabf4b50cdab53d5',
  PhoenixBookDTTEnrollCustomer: 'e4c3db99447dcbf056c0cea7b1bedfa6dabeeca9e50c67af7006e7e3251968be',
  PhoenixBookDTTGetCustomerData: 'b350782aa9b2ee1ba10134af2e98bc18047fe27737b0d10ee9d9526bc46488f0',
  PhoenixBookDTTGetCustomerPreferences: 'aa2c2201ec8e45091038215e9ddc673bbc29180ab584bf72afea3731200cd40f',
  PhoenixBookDTTGetProductCode: 'ac4f85aa2f4c79de22af12d2ffd8758b32eb526db969bb46906499ea1b6e3eef',
  PhoenixBookDTTGetProductDetails: '36db2e8c8f819976b3a0c5868264687dca472a0c71dc305e1eda97a736dd9369',
  PhoenixBookDTTGetPropertyBasicAndContactInfo: '542f7a81f11d9653784464552fb3a5351c04f266f9970ed9728c25c46e5d87bf',
  PhoenixBookDTTGetShoppingCartDetails: '53663691d68cb7ddb4d714bfaa6f89dae47d7481843d93830d9753a1fca095d1',
  PhoenixBookDTTPropertyBasicInfo: '830970018ea94cbf505061d5c1f72f5f4ad9492625dea4ef5e4e17f757520239',
  // PhoenixBookDTTUpdateCart: '3bf85a61e0e86787859347ff8843f45d02fd31a79d131483a92857bab220561d',
  PhoenixBookDTTAddCustomerFeatures: 'd3b6f06aefe8e1d01c1d246744921b1e2a7981bef2dcc7b6492bcd94d5e30711',
  PhoenixBookDTTUpdateCustomerCommunicationOptions: 'f14903096ec11b09e797c9929c440bdf1857a9b47163338707b694e1ee5edeba',
  PhoenixBookDTTUpdateCustomerContactInfo: 'c66bd4d55afda1a214f63aa573ab5a77938910adb78f2fa1842118bf4a74a976',
  PhoenixBookDTTUpdateCustomerPaymentMethod: 'a9f267b8e60312260ae07b3447df405a516a2aa3c8a9e74db649149f09dacbeb',
  PhoenixBookDTTUpdateCustomerPersonalInformation: '91c37a3620ceb255e616da453e59486158b65fb3d1d8fc34d666442b222ad0f6',
  PhoenixBookDTTDeleteCart: '2765ebaf82e575eefb6b42a13f8668172446dd0c946259431c33c353b4ccc6c1',
  PhoenixBookAddAAADetails: 'cc49ace53af76412f733edd59418a1f8c8e14a5d93c2c91afe641b062a431119',
  PhoenixBookDTTAddToCalendar: 'd1d956b2b535b233cab7a646e3416b9f56d675777b93fa7cb52481a2354e7670',
  PhoenixBookDTTGetPrePayConsent: 'c0248ad70b21d0659f737ec0f702d4703545983c69c58c7797e15242ef8c527a',
  PhoenixBookDTTGetOrderDetails: '939f42e4f74d95ae52e50258b0e5695c29d070c7a1fec3c3fc8d67c39f2ceb5e',
  PhoenixBookDTTCartCancellationPolicy: '39faf1916b87a44bc632807e0a692e6504be827c403450e5f513c4afa6de6894',
  PhoenixBookDTTCartCoBrandCreditCard: '5d21183dfb79208fa9548ad045ff8eea870d197d2e089fcb91b6f40f39672345',
  PhoenixBookDTTCheckInNow: '7c9372ff0b537f5c30be829249679a481031f85f7b5c9e303b7da20b10e48964',
  PhoenixBookDTTCoBrandCreditCard: 'ffa22281689f444474789c1b2a5f4bb452c7068f6f8926a3e1a080ccbce6eae6',
  PhoenixBookDTTConfirmationMessage: '5e1d21235f211f603ffb8f48726125ce24c6bfc8f5f2dfe1784e496344a67784',
  PhoenixBookCountryStateLookup: '39ef08e4114a7c3f85dc863ac43756ba57397cacb65ec4d7c5959444f8de2efd',
  PhoenixBookDTTCurrencyChange: '4311ba788781fa65d05c090a14654fb02ffae131fede884cde17c6c6730f2800',
  PhoenixBookDTTGetAAADetails: '8022a1fb9f1bc8e5e3c1ebe5649d215f3c117f66ecc3cc07b7201f3a36916219',
  PhoenixBookpUpdateAAADetails: 'e5ce43b661e533827587226cc8e1857317ebbdc05c56daee33f044305363b5cf',
  PhoenixBookDTTCustPayInfo: '37cb0d5e697568dc29ec0eeb0579a87d34414790498c32388ac53899bb3bcf54',
  PhoenixBookDTTGetNightlyProducts: '6a0ab007049107cbf1164786dec8e1b84bbc4b085a9095eba07b9fda159ed6aa',
  PhoenixBookDTTDefaultSelectionData: '380c4103dabe0a99efec3f6b1f7ed3f6adcfdecc44bbad2d3953b01886815a02',
  PhoenixBookExtendCartExpiration: '4368b98f990015a67e32374be20ebf12816e7ed74c309d036bbfaf768247bdbe',
  PhoenixBookDTTHeroBanner: 'd2e8606e29365af27c163581632dec79859061a4c93a87bdf7a817f60dfd17d6',
  PhoenixBookDTTGetAlerts: '32c5757f69c57807e5226b9d6f49683be00c940b0b9ba766a81f746b7b3c168e',
  PhoenixBookDTTCancellationPolicy: 'ea44f55309085169f6586e0a255ad599da40464a4cfbc763a25c9f87a13f2c20',
  PhoenixBookDTTHotelHeaderData: '018b971a06886d6d2ca3f77747bf9f9c2cc8d49638bf25a1c3429277c4627c9f',
  PhoenixBookDTTHotelHeaderDataAuth: 'f8c07a2cd53a25eba2675ea771808f8a761aeb28dcdbb123aa37b9730e48896d',
  PhoenixBookDTTGetMessages: '78389de11eddc9a903798ea5c48ee8a5f785e6f28219ce20b08a9fba17f0da5c',
  PhoenixBookDTTHouseKeeping: '03aac4c76cb656ca3973aeea19c1ced8df1809f8f88cead175a051fa37d5649d',
  PhoenixBookDTTGetJoinNow: '16ecbe465763b04424d0dc2b0b1db0097f8c550242050f6e2912d50025edb44e',
  PhoenixBookMemberInformation: 'f1a568834df34aab8a70d1a840466404ab765b299950e2325c35e39ec49f9f7b',
  PhoenixBookGetLookupRewards: '47efe6ac3efc24057e11f458ded3b3221b59c67cae91c3240bf1cab836b2b97f',
  PhoenixBookDTTPropPayInfo: 'c05e97c2dd1e5f70ec84d00c0852df6d48c1b0ba3aee63291d26733c4642e9c0',
  PhoenixBookDTTSuggestedPlacesDetailsQuery: '73546b987f581643cd2e02b0cd6ec040cb60bcaa4494806ab54acf815e2996bc',
  PhoenixBookDTTSuggestedPlacesQuery: '29379ce49c137993be1c0c9af3890272ce5f6d61240f684ec957987cc8346bdf',
  PhoenixBookDTTPrepayRates: '60d2d34915ad4c18f2d7c9f1bafffeb12ca1987ea021bfcd5896c68fdf13b25d',
  PhoenixBookDTTGetRateDetails: '84f5c72aa0f43b0da7212d5ad582f9f5fcfa634d72cd1d270aff19bb6efdb63b',
  PhoenixBookDTTNotificationStatus: 'f98379d13af0e50b94fde170002b0d17716ffbe20eaeecef3532148b6afc8bcd',
  PhoenixBookDTTNotificationStatusUnAuth: '29de4b1cfd90e813db708dfabc3e1532112c3a0042f6711a150c1b325f5bf8ef',
  PhoenixBookDTTGetRedemptionData: '300f65f0dd8d44a76db117e1191b18efee4a4b8f06fcb7e1a8d90c326263d116',
  PhoenixBookDTTReservationDetails: '998d44a481038c0cd20efa791b6c29eb65cb1316c147487f0072891df70b4f8a',
  PhoenixBookDTTResSummary: '695f4160e933f1339e027e9b396093698b2da703c69e4a3a55f5fddc65c4e249',
  PhoenixBookDTTGetRoomDetailsFeatures: 'f2797e12ca8e9edddec0e38e22a128866dccdcf98b0865a28414ab9e5c3b72f8',
  PhoenixBookDTTRoomImages: '77ed27f32ccdc05391f96a1b3f95278e43c4415bc9514e3b665dc36ca8026b4c',
  PhoenixBookDTTRoomRequests: '5991cda1f4dfc72fc72842cbe5a2052666ec51c75562bea3254449bb76e51c5f',
  PhoenixBookDTTRoomRequestsUnAuth: '46423cf64eaeac9b5b094bc90ffffd382b23107c075e4c161bf284d260cff1dc',
  PhoenixBookUpdateSaveProperties: 'b0c0bb536606ce0380934f47ab04b8986298e9361ac485e4136adf7c0abb8df1',
  PhoenixBookDTTSearchProductInfo: '6f005c63b04a77dcf75f54906eea74c256ba4b2b70db244a4c78bf428ede3da6',
  PhoenixBookDTTSearchProductsByProperty: '2f636a2fd80d48e05a56091386c178724b703344fd46a190db3cb964496220cf',
  PhoenixBookDTTSearchProductsByPropertyNonErs: 'b38434b0fa7524685ef939faf564ba81aa83f703b006265d07c4111605061ddf',
  PhoenixBookShareBySMS: '7ad857b52eade501a7c02e273944210bb5b9c503ef5b3fb1bca644f7ce98b080',
  PhoenixBookDTTShareByEmail: '4fe3131e0013a5fa13bb0a6f3b18ad856eab6f295302f45d27b396bdd548bbea',
  PhoenixBookDTTLookupsByType: '9e622987ce77b3750ffe72a8eb4aeb65c74718cd0b5544a4c4e2528e8d1e6bbc',
  PhoenixBookDTTStateLookup: 'e8d14086b9aee472f2b869df8f27cd1b9bbaa2ef8577b522cfd41f4abd5d57c2',
  PhoenixBookDTTStayDate: '88104c8dca3e3dc488d958fe1ac4d4c394c603de86f370415547d77f2cb9f416',
  PhoenixBookDTTStayDetails: 'c39d9ddc9f35a9fed17e98387bd49f275e96cbf8ebcd753a72f416313468da9d',
  PhoenixBookDTTStickyBar: 'd943abadf27f80dd7ae27fc6efad100401ce7736f36ab17086342d9e849b906d',
  PhoenixBookDTTSummaryOfChargesAuth: 'afc0a549aac2274802eb139547eafa359702049c236b7480708a8bf182caf612',
  PhoenixBookDTTSOCCart: 'ce076c093121ea403b86cc75d055189b542a900f10713be68388668ec8a0b4d3',
  PhoenixBookDTTSummaryOfChargesUnAuth: '614c83ced6f29395e64999b8debe6e608d83a96a38df640bdb00c6673b5aa8dc',
  PhoenixBookDTTTIQuoteUrl: 'c0f54d91d152abd6d4f1b1db48dfdf4964a9c23f15af8a145531dfa24a19098c',
  PhoenixBookDTTTIQuote: '83796bc9335d68e7c3aaa56cb561013c2806d691cbd2f5eb5e7dd8bcf522593a',
  PhoenixBookDTTUpdateCart: '9d34fb666556c542acf00f1a6317ae764553da84bf391671a1a84a975d39c43a',
  PhoenixBookDTTUpdateCartExpiration: '3651ab989d8bba9cdfe92d22f73591d6168d67b0a342b0dab54aa52e1592cd6e',
  PhoenixBookDTTUpdateCartFNA: '8adbdcd6e7031ab17cf0fbcba6aa12143c92df7af6af6274fe2ee4f175a86c1a',
  PhoenixBookDTTUpdateInsuranceCart: '292ecc0f65e5a10eaebef3161089de15b6ec63477028af423d525a6394549633',
  PhoenixBookUpdateMemberInformation: '7524a53950ea9988160d68ce3639405dbb988220a6aaed0a0d255c733fe89ecf',
  PhoenixBookUpdateCustomerPreferences: '8e37dbcf3176a332734c81252b32281276dba2de89c4a128fc19f1096845434e',
  PhoenixBookDTTPropertyData: '097ff8d740e48931378abde4850e9839becac2d3cfe0e9a153516917ac26bf97',
  PhoenixBookDTTRoomUpgradeData: '1f02527cce111d82cebac132ae2a6f7fc3a301f4050326f52f798cf0a989fb4a',
  PhoenixBookDTTRoomUpgradeUserData: '2a9c1d7347ac9ec04d967f9565be955dbc9de0071cb6e58bf5f92657719c8fe5',
  PhoenixBookDTTSnaData: 'e1461cd251955a27284aef06fe70062ae62f3d9728790842857b09bcce1eed5c',
  PhoenixBookDTTValidateAaaNumber: '718716383a85d22a00b4d67b5523edac9036d7f256c17d1a2281de9f13f65c6c',
  PhoenixBookDTTHotelAmenities: '9216291b30bd67fe7062fa5d3f05d6f913d2512ac845e10cac14cd1e2150ff80',
  PhoenixBookDTTHQVPhotogalleryCall: '2b1d3f7ccf486c6652f137461c37713ba24dc4436292fb6ef266dd1a5bb5bf4a',
  PhoenixBookDTTPropertyInfoCall: 'f740d5a82b4de1be12cc13edc913bacaff391eaed630c53591922cd21af57695',
  PhoenixBookDTTCurrencyExchange: '0a30f77a652e789bff7307980c2d1b86608194dc12960d16722e33a3b48fbdb3',
  PhoenixBookDTTCustomerPreferences: 'a3376f08ec8ba70fbfe381a80d3d794beb50f5d068a8850cf408720bae07ffbc',
  PhoenixBookDTTRecommendedProductByUserPreference: 'a0c8d627a93ef2343feeceaee68a656ab84eb57e30f8180039bc7a407b4acfe2',
  PhoenixBookDTTCancelledReservationDetails: '48322c5385992d049747e8087745211efe12e7327b5e1d820d6e405c26e4e240',
  PhoenixBookDTTCurrentReservationDetails: 'b6e14deda579d594512249694bb1d9a3d4e68d799210645e306666a66ae5936a',
  PhoenixBookDTTGetProductFromOrder: 'dd4d9087801460c058c8665fb50f6dd1e5f531a88ed69ecc2453a05a4c07217f',
  PhoenixBookDTTPrefPluBook: 'cbca8437ab7ec88a579be7b2a57b1bb14d97ce4468ca1b88d5de1917a0498d41',
  PhoenixBookDTTRoomOverviewDetails: 'fc1a5e8beb17bee950791dee53798c362eeabd577e3b9df2e2421009b5fdc87c',
  PhoenixBookDTTAuthRoomOverviewDetails: '936a1a86f4c08de91eb98943624ca11221a7e43d748f3662ced853548d19d995',
  PhoenixBookDTTSmsCountryCodesLookup: '001d8cc1becec8ba35fb875948a8ee266dfc91f162842e1db6029adde4a4a6cf',
  PhoenixBookDTTUserFormTitleLookup: 'ce5d7f69d0adfc1f2237de3a9bcb20bd944fd1dce17c4b9e3a717813eb039992',
  PhoenixBookDTTTravelInsurance: 'aec5e8180ae093ea7ecfa5be35acbcf30d4be8262c995b28fdc5ed00d219cab7',
  PhoenixBookDTTUpdateOrder: '51484a6ab1eee5538662dbc997d69e969f82ccf99521ff01ce0f95e2ee7c7d88',
  PhoenixBookDTTUpdateUserInformation: '0d89a7f625fbf9420c0e352fa5db4c5a37c6d046783a23a45813dd61e2defebf',
  PhoenixBookDTTGetProductInfo: '9a908ab2aa5ef1fbfe82d3c960aa0319dfcef32ef3b3eaa0549648c328f097eb',
  PhoenixBookDTTTranslateText: '131e344ff3a626f2322108ced5d15d3723b0b907aa7547373e5ac4c4a0e79109',
  PhoenixBookDTTRoomUpgradeUpsellData: 'ad0addfba45586ce6455e5eb095d1419d62ae049bb1aa82127da68e06e6a1d4b',
};

export const VITAL_UXL_CALLS = [
  GQL_OPT_NAME.searchProductByProperty,
  GQL_OPT_NAME.reservationDetails,
  GQL_OPT_NAME.memberInformation,
  GQL_OPT_NAME.updateMemberInformation,
  GQL_OPT_NAME.propertyPaymentInfo,
  GQL_OPT_NAME.customerPaymentInfo,
  GQL_OPT_NAME.hotelCancellationPolicy,
  GQL_OPT_NAME.roomRequests,
  GQL_OPT_NAME.updateCart,
  GQL_OPT_NAME.updateProfile,
  GQL_OPT_NAME.countriesAndState,
  GQL_OPT_NAME.userFormTitles,
  GQL_OPT_NAME.smsCountryCodes,
  GQL_OPT_NAME.roomOverview,
  GQL_OPT_NAME.authRoomOverview,
  GQL_OPT_NAME.roomRequestsUnAuth,
  GQL_OPT_NAME.confirmChangesModal,
  GQL_OPT_NAME.reservationSummary,
];

export const VITAL_UXL_CALLS_DTT = [
  GQL_OPT_NAME_DTT.searchProductByProperty,
  GQL_OPT_NAME_DTT.reservationDetails,
  GQL_OPT_NAME_DTT.memberInformation,
  GQL_OPT_NAME_DTT.updateMemberInformation,
  GQL_OPT_NAME_DTT.propertyPaymentInfo,
  GQL_OPT_NAME_DTT.customerPaymentInfo,
  GQL_OPT_NAME_DTT.hotelCancellationPolicy,
  GQL_OPT_NAME_DTT.roomRequests,
  GQL_OPT_NAME_DTT.updateCart,
  GQL_OPT_NAME_DTT.updateProfile,
  GQL_OPT_NAME_DTT.countriesAndState,
  GQL_OPT_NAME_DTT.userFormTitles,
  GQL_OPT_NAME_DTT.smsCountryCodes,
  // GQL_OPT_NAME_DTT.roomOverview,  // TODO Enable back once UXL resolves all the blockers
  // GQL_OPT_NAME_DTT.authRoomOverview,
  GQL_OPT_NAME_DTT.roomRequestsUnAuth,
  GQL_OPT_NAME_DTT.searchProductByPropertyNonErs,
  GQL_OPT_NAME.confirmChangesModal,
  GQL_OPT_NAME_DTT.reservationSummary,
  GQL_OPT_NAME_DTT.hotelCancellationPolicy,
  GQL_OPT_NAME_DTT.hotelCancellationPolicyByCart,
];

//NRP Constants
// TO DO: This needs to be updated once the inventoryTypeCode's are finalized.
export const INVENTORY_TYPE_CODES = {
  cribCode: 'CRIB',
  rollAwayCode: 'ROLLAWAY',
  petCode: 'PETFEE2',
  petClCode: 'PETFEE1',
};

export const NRP_CONSTANTS = {
  NRP_PRODUCT_TYPES: ['ancillary'],
};
export const ACRS_CONSTANTS = {
  ACRS: 'ACRS',
};

/**
 * this file include  calls related to otp and mfa
 */
import axios from 'axios';
import { addSubDirectoryPrefix } from './helper';
import {
  CONSENT_FORM_DECRYPT_ENDPOINT,
  constants,
  generateOneTimePwrdForMobileUrl,
  validateOneTimePwrdForMobileUrl,
  PROFILE_CONSTANTS,
} from './constants';
import { handleRedirect } from './userLogin.helper';
import { useAccountPersistentStore } from '../store/accountPersistentStore';

export const isMobilePhoneOtpVerrificationRequired = (
  phoneNumber?: Array<{
    countryCode: string;
    phoneNumber: string;
    phoneType: string;
  }>,
  currentLocale?: string
) => {
  /**
   * if any conditon is true make isProfileNudge flag true
   * reuired phoennnumber, usercountry
   * check locale if country is china
   */
  const phoneArr = phoneNumber?.filter(key => key.phoneType === PROFILE_CONSTANTS.PHONE_TYPE.DEFAULT);
  if (
    phoneArr?.length &&
    phoneArr?.[0].countryCode === constants.CHINA_COUNTRY_CODE &&
    currentLocale === constants.LOCALE_KEY_CHINA
  ) {
    return phoneArr?.[0]?.phoneNumber;
  }
  return false;
};

export const generateUserOtpForVerification = (
  payload: { phoneNumber: string | undefined; nudgeVerify?: boolean; resendOtp?: boolean },
  currentLocale: string
) => {
  /**
   * required  phonenumber to generate otp
   * {phonenumber:String}
   * locale:string
   * this function is used to generate the otp
   */
  if (payload.phoneNumber) {
    const { setProfileNudgePayload } = useAccountPersistentStore.getState();
    const phoneStr = payload.phoneNumber;
    setProfileNudgePayload({
      fourDigitOfMobile: phoneStr?.substring(phoneStr.length - 4, phoneStr.length),
    });
    return axios.post(addSubDirectoryPrefix(generateOneTimePwrdForMobileUrl), payload, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        ...(currentLocale && { locale: currentLocale }),
      },
    });
  } else {
    /**
     * if phone number is not present then throw this error
     */
    throw Error('Phone Number is required');
  }
};

export const handleRedirectForProfileNudge = async (data: {
  isRedirect: string;
  nextStateURI: string;
  encodePayload?: string;
  communicationOption?: string;
  transactionId?: string;
}) => {
  /**generate otp for mobile number */
  if (data?.isRedirect) {
    /**
     * redirect user to specific page
     */
    const { setProfileNudgePayload } = useAccountPersistentStore.getState();
    /**
     * assign encoded payload to presistent store for resuse on CYI page
     */

    setProfileNudgePayload?.({
      isNudgeVerify: true,
      communicationToken: data?.communicationOption,
      transactionId: data?.transactionId,
    });
    /**
     * redirect page to next state uri
     */
    handleRedirect(addSubDirectoryPrefix(data.nextStateURI));
  }
};

export const communicationTokenDecrypt = (communicationOption: string) => {
  /** decrypt communication options */
  return axios.post(CONSENT_FORM_DECRYPT_ENDPOINT, {
    data: [
      {
        key: 'communicationOption',
        value: communicationOption, // to check how to get value
        algorithmKey: 'aesCBC',
      },
    ],
    clientId: 'mcom_account',
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleVerifyOtpForProfile = (payload: any) => {
  /**
   * verify otp call
   */
  return axios.post(addSubDirectoryPrefix(`${validateOneTimePwrdForMobileUrl}`), payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

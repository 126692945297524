// Import common constants (always required)
import * as common from './common';

let isDTT = false;
let isACRS = false;

// declare global

// console.dir(global,{depth:null});

if (typeof window !== 'undefined') {
  isDTT = window?.__NEXT_DATA__?.props?.pageProps?.isDTT;
  isACRS = window?.__NEXT_DATA__?.props?.pageProps?.isACRS;
}

// Initialize type-specific constants based on the logic
let typeSpecific;

if (isDTT) {
  if (isACRS) {
    // If DTT is true and ACRS is true, load constantsAcrs
    typeSpecific = require('./constantsAcrs');
  } else {
    // If DTT is true and ACRS is false, load constantsEapi
    typeSpecific = require('./constantsEapi');
  }
} else {
  // If DTT is false, load constantsEapi
  typeSpecific = require('./constantsEapi');
}

const combinedExports: typeof common & typeof typeSpecific = {};
for (const key of Object.keys(common as typeof common)) {
  combinedExports[key] = { ...common[key as keyof typeof common], ...typeSpecific[key] };
}

for (const key of Object.keys(typeSpecific)) {
  if (!combinedExports[key]) {
    combinedExports[key] = typeSpecific[key];
  }
}

export const {
  constants,
  MGP_CONSTANTS,
  URL_CONSTANTS,
  CUSTOM_EVENTS_CONSTANTS,
  REGEX_CONSTANTS,
  GQL_OPT_NAME,
  GQL_OPT_NAME_DTT,
  GQL_OPT_SIGN,
  GQL_OPT_SIGN_DTT,
  LOADER_CONFIGS,
  TRACKING_CONSTANT,
  DATALAYER_CONSTANT,
  VITAL_UXL_CALLS,
  VITAL_UXL_CALLS_DTT,
  VITAL_UXL_CALLS_COMPONENT,
  HotelGalleryPhotoViewerConstants,
  EnrollmentSourceCode,
  ONE_CLICK_JOIN_ERR_MSG,
  ONE_CLICK_JOIN_SUCCESS_MSG,
  COUNTRY_MOBILE_NUMBER_MIN_LENGTH,
  ENV_CONSTANTS,
  INVENTORY_TYPE_CODES,
  NRP_CONSTANTS,
  ACRS_CONSTANTS,
  hardCodedAllLocale,
  currencyLocales,
} = combinedExports;

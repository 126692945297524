/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useId, useEffect, useContext, useMemo } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { inspect } from 'util';
import { useLazyQuery } from '@apollo/client';
import { Button, Text, Link, Types, Messages, CheckBox, InputTextField, RichText } from '@marriott/mi-ui-library';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { PageContext } from '../../../modules/context/PageContext';
import {
  constants,
  CREATE_ACCOUNT_CLICK_VAL,
  CHINESE_MOBILE_CODE,
  CHINESE_MOBILE_LENGTH,
  NUMBERS_REGEX,
  ONE_CLICK_JOIN_ERROR,
  CHINESE_SOURCE_LANG,
  ENGLISH_SOURCE_LANG,
  BANNER_MSGS_KEYS,
  generateOneTimePwrdForMobileUrl,
  DUPLICATE_PHONE_ERROR_KEY,
  ENROLL_PATTERN_EXPERIENCES,
} from '../../../modules/utils/constants/constants';
import {
  addSubDirectoryPrefix,
  apiLogger,
  checkChinaLocale,
  checkForValidZipCode,
  emailRegex,
  generateApolloClientHeaders,
  getCountryAndLangCode,
  isChinese,
  randDecrpytAPI,
  scrollIntoViewClass,
  shouldSwapName,
  nameFieldRegex,
  nameRegExpValidation,
  canUseDOM,
  getCurrentUrlParams,
} from '../../../modules/utils';
import { phoenixAccountTransliterate } from '../../../modules/graph';
import { useCreateAccountStore } from '../../../modules/store/createAccountStore';
import { useBannerMessagesStore } from '../../../modules/store/bannerMessagesStore';
import { PhoneNumberField } from '../../../molecules/PhoneNumberField';
import { Dropdown } from '../../../molecules/Dropdown';
import { PasswordInput } from '../../../molecules/PasswordInput';
import { OtpTimer } from '../../../molecules/OtpTimer';
import {
  CreateAccountFormProps,
  CountryOption,
  CountryList,
  PrefillResponse,
  CreateAccountErrors,
} from './CreateAccountForm.types';
import { StyledCreateAccountForm } from './CreateAccountForm.styles';

// Use named rather than default exports.
export const CreateAccountForm: FC<CreateAccountFormProps> = models => {
  const {
    nameInlineErrorMsg,
    pwrdCharCheck,
    pwrdLowerCaseCheck,
    pwrdUpperCaseCheck,
    pwrdSpecialCharCheck,
    countries,
    firstNameLabel,
    lastNameLabel,
    zipPostalCodeLabel,
    countryRegionLabel,
    emailLabel,
    mobilePhoneLabel,
    sendVerificationLabel,
    sendVerificationDescription,
    verificationCodeLabel,
    rememberMeLabel,
    confirmPwrdLabel,
    pwrdLabel,
    createPwrdLabel,
    firstNameError,
    firstNameBannerError,
    lastNameError,
    lastNameBannerError,
    zipCodeError,
    emailErrorLabel,
    alreadyAMemberText,
    activateOnlineAccountText,
    activateOnlineAccountPath,
    pwrdErrorLabel,
    confirmAndNewPwrdMismatchMsg,
    errorMessageHeader,
    mobilePhoneError,
    verificationCodeRequired,
    mobilePhoneRequired,
    mobilePhoneDigitsRequired,
    pinyinEnglishSurname,
    pinyinEnglishName,
    chinaSurNameError,
    chinaNameError,
    prohibitedCountryErrMsg,
    emailDuplicateErrMsg,
    inlineMessageForName,
    zipInlineError,
    emailInvalidError,
    optionalLabel,
    zipCodeInvalidError,
    eligibleCountries,
    isValidClientId,
    partnerPortalObject,
    isDTT,
    eaa,
    inlineErrorChineseName,
    incorrectPinyinEnglishLastNameFormat,
    incorrectPinyinEnglishFirstNameFormat,
    incorrectChineseLastNameFormat,
    incorrectChineseFirstNameFormat,
    existingPhoneNumberWarning,
    countryCodeLabel,
    mobilePhoneInvalidError,
    mobilePhoneEmptyError,
    verificationCodeInlineError,
    phoneAlreadyAssociatedError,
  } = models;

  const {
    formData,
    setFormData,
    errorKey,
    setTransactionDetails,
    setTripId,
    setStartDate,
    setJoinNowBtnClicked,
    joinNowBtnClicked,
    errorsList,
    setErrorsList,
    remembermeCheckBox,
    consentErrors,
    setRemembermeCheckBox,
    setFormValidation,
    goBackUrl,
    isValidPartnerPortal,
    prefillObj,
    setPrefillObj,
    resetTimer,
    setResetTimer,
  } = useCreateAccountStore();

  const passwordCondArr = [
    {
      condition: pwrdCharCheck,
      isValid: false,
    },
    {
      condition: pwrdUpperCaseCheck,
      isValid: false,
    },
    {
      condition: pwrdLowerCaseCheck,
      isValid: false,
    },
    {
      condition: pwrdSpecialCharCheck,
      isValid: false,
    },
  ];

  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const prefillResponse: PrefillResponse = pageContext?.enrollPrefillResponse;
  const isPatternFour = prefillResponse?.partnerExperience === ENROLL_PATTERN_EXPERIENCES.PATTERN_FOUR;
  const isPatternTwo = prefillResponse?.partnerExperience === ENROLL_PATTERN_EXPERIENCES.PATTERN_TWO;
  const { isMobileAuthFeatureEnabled, isNonChinaMobileEnabled, currentLocale } = pageContext;
  const { countryCode } = getCountryAndLangCode(currentLocale);
  const localeCountryCode = countryCode || constants.USA_COUNTRY_CODE;
  const localeBasedNameSwapConfig = pageContext?.localeBasedNameSwapConfig;
  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;
  const { HIDE_MOB_NUM_FOR_COUNTRIES: hideMobNumForCountries, PROHIBITED_COUNTRIES_CODES: prohibitedCountries } =
    clientEnvVars;
  const hideMobNumForCountriesArr: Array<string> = hideMobNumForCountries?.split(',') || [];
  const prohibitedCountriesArr: Array<string> = prohibitedCountries?.split(',') || [];
  const { randParameter, isOCJFlow, bannerMsgs } = useBannerMessagesStore(state => state);
  const isChinaLocale = checkChinaLocale(currentLocale || '');
  const inputId = useId();
  const [queryParams, setQueryParams] = useState('');
  const [passwordType, setPasswordType] = useState<'text' | 'password'>('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState<'text' | 'password'>('password');
  const [passwordConditions, setPasswordConditions] = useState(passwordCondArr);
  const [disableField, setDisableField] = useState(isChinaLocale);
  const [mobileField, setMobileField] = useState(false);
  const [mobileFieldOptional, setMobileFieldOptional] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [showZipcodeField, setShowZipcodeField] = useState(false);
  const [reverseNamefield, setReverseNamefield] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState<CountryOption>();
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [showSendCode, setShowSendCode] = useState<boolean>(false);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const isValidChangePassword = !passwordValid;
  const { IS_LOCAL_DEV } = process.env;

  const [errors, setErrors] = useState<CreateAccountErrors>({
    firstNameError: '',
    lastNameError: '',
    errorFirstName: '',
    errorLastName: '',
    zipCodeError: '',
    emailErrorLabel: '',
    pwrdErrorLabel: '',
    confirmAndNewPwrdMismatchMsg: '',
    verificationCodeRequired: '',
    mobilePhoneRequired: '',
    mobilePhoneDigitsRequired: '',
    mobilePhoneError: '',
    chinaSurNameError: '',
    chinaNameError: '',
    prohibitedCountryErrMsg: '',
    zipCodeInvalidError: '',
    emailInvalidError: '',
    usPostalCodeInvalidError: '',
    canadaPostalCodeInvalidError: '',
    uxlFailureErrorMessage: '',
    existingAccountInlineErrorMessage: '',
    incorrectChineseLastNameError: '',
    incorrectChineseFirstNameError: '',
    incorrectVerificationCode: '',
    expiredTransactionCode: '',
    phoneAlreadyAssociatedError: '',
    transactionIdExpiredError: '',
    invalidOneTimePasswordError: '',
    otpRequired: '',
    otpIncorrectErrorMessage: '',
    mobilePhoneInvalidError: '',
    mobilePhoneEmptyError: '',
    verificationCodeInlineError: '',
    errorPinyinEnglishSurNameInline: '',
    errorpinyinEnglishNameInline: '',
  });

  const [chineseCharInput, setChineseCharInput] = useState<{
    firstNameInlineErrorFlag: boolean;
    lastNameInlineErrorFlag: boolean;
  }>({
    firstNameInlineErrorFlag: false,
    lastNameInlineErrorFlag: false,
  });

  const resetPwrd = () => {
    setFormData({ pwrd: '', confirmPwrd: '' });
    setPasswordValid(false);
  };

  const randDecryptionAPI = async (urlQueryParams: string) => {
    const param = new URLSearchParams(urlQueryParams);
    const randQueryParameter = param?.get('rand') ?? ''; // rand parameter

    if (randParameter || randQueryParameter) {
      try {
        const response = await randDecrpytAPI(isOCJFlow ? randParameter : randQueryParameter, isDTT);
        if (response?.status === 200) {
          setFormData({
            ...formData,
            firstName: response?.data?.firstName,
            lastName: response?.data?.lastName,
            zipcode: response?.data?.postalCode,
            email: response?.data?.email,
          });
          handleCountryNameChange(getCountryDetailByCountryCode(response?.data?.country));
          setTripId(response?.data?.reservationId);
          setStartDate(response?.data?.checkInDate);
          if (isOCJFlow) {
            checkProhibitedCountries(response?.data?.country);
          }
        }
      } catch (error) {
        apiLogger(`[ProfileDetails] GetProfileDetails - sessionId: ${sessionData?.sessionToken}: ${inspect(error)}`);
      }
    }
  };

  // to run rand api once when isCheckedIn modal is up
  // to check if clientid is valid for sweepstake variation
  useEffect(() => {
    const urlQueryParams = canUseDOM ? getCurrentUrlParams()?.replace(/&amp;/g, '&') : '';
    setQueryParams(urlQueryParams);
    randDecryptionAPI(urlQueryParams);
    checkLocaleNameSwap();
    populatePrefillData(urlQueryParams);
  }, []);

  useEffect(() => {
    if (bannerMsgs?.key !== BANNER_MSGS_KEYS.NEW_MEMBER_NUMBER_MSG) {
      sessionStorage.removeItem('bannerMessagesState');
    }
  }, [bannerMsgs?.key]);

  //clear verfication field if there is an error
  useEffect(() => {
    if (resetTimer) {
      setFormData({ ...formData, verificationCode: '' });
    }
  }, [resetTimer]);

  // sweepstake phone dropdown to have countries with country code +1
  const updatedSweepstakeCountriesDialCodes = useMemo(() => {
    return eligibleCountries?.map(eligibleCountry => ({
      ...eligibleCountry,
      ...(countries as CountryList[])?.find(country => country?.id === eligibleCountry?.id),
    }));
  }, []);

  // used to map country code from rand response
  const getCountryDetailByCountryCode = (countryCode: string) => {
    const countryDetail = (isValidClientId ? updatedSweepstakeCountriesDialCodes : countries)?.find(
      country => country.id === countryCode
    );
    return countryDetail;
  };

  const updatedCountriesDialCodes = (isValidClientId ? updatedSweepstakeCountriesDialCodes : countries)?.map(
    (country: { id?: string; countryDialCode?: string; value?: string }) => ({
      id: country?.id || '',
      name: country?.value || '',
      value: country?.countryDialCode || '',
    })
  );

  const countryDialCode = updatedCountriesDialCodes?.find(
    (d: any) => d.id?.toUpperCase() === countryCode?.toUpperCase()
  )?.value;

  const [selectedCountryCode, setSelectedCountryCode] = useState(countryDialCode);
  const hasOtpError =
    !!errors?.verificationCodeRequired ||
    !!errors?.transactionIdExpiredError ||
    !!errors?.invalidOneTimePasswordError ||
    !!errors?.otpIncorrectErrorMessage ||
    !!errors?.otpRequired;

  const isChinesePhone = formData?.mobilePhoneCountryId?.value === CHINESE_MOBILE_CODE;
  const isChinesePhoneValid = formData?.mobilePhone?.length === CHINESE_MOBILE_LENGTH;
  const isValidPhoneNo = formData?.mobilePhone?.length >= 7 && formData?.mobilePhone?.length <= 16;

  const partnerExperienceTwo = (countryId?: string) => {
    if ((countryId && hideMobNumForCountriesArr?.includes(countryId)) || hideMobNumForCountriesArr?.includes('ALL')) {
      setDisableField(false);
      setMobileField(false);
    } else {
      setDisableField(false);
      setMobileField(true);
      setMobileFieldOptional(true);
    }
  };

  const partnerExperienceFour = (countryId?: string, isPrefill?: boolean) => {
    if (countryId === constants.CHINA_COUNTRY_CODE) {
      // adding back phone number if prefill have phone number and user switches between coutries
      if (
        prefillResponse?.phone?.number &&
        prefillResponse?.phone?.countryCode === countryId &&
        !formData?.mobilePhone
      ) {
        setFormData({ mobilePhone: prefillResponse?.phone?.number });
      }
      if (
        !isPrefill ||
        (isPrefill &&
          (!prefillResponse?.phone?.countryCode ||
            prefillResponse?.phone?.countryCode === constants.CHINA_COUNTRY_CODE))
      ) {
        setDisableField(true);
        setMobileField(true);
        setMobileFieldOptional(false);
      } else {
        setDisableField(false);
        setMobileField(true);
        setMobileFieldOptional(true);
      }
    } else if (countryId !== constants.CHINA_COUNTRY_CODE) {
      setDisableField(false);
      setMobileField(false);
      setMobileFieldOptional(true);
      setFormData({ mobilePhone: '' });
    }
  };

  const validatePartnerExperience = (countryId?: string, pattern?: number, isPrefill?: boolean) => {
    switch (pattern) {
      case ENROLL_PATTERN_EXPERIENCES.PATTERN_TWO:
        partnerExperienceTwo(countryId);
        break;
      case ENROLL_PATTERN_EXPERIENCES.PATTERN_FOUR:
        partnerExperienceFour(countryId, isPrefill);
        break;
      default:
        return;
    }
  };

  const phoneNumberValidation = (countryId?: string, isPrefill?: boolean) => {
    if (eaa) {
      validatePartnerExperience(countryId, prefillResponse?.partnerExperience, isPrefill);
    } else if (isChinaLocale && countryId === constants.CHINA_COUNTRY_CODE) {
      setDisableField(true);
      setMobileField(true);
    } else if ((isValidClientId && !isChinaLocale) || countryId === constants.CHINA_COUNTRY_CODE) {
      setMobileField(true);
      setMobileFieldOptional(true);
    } else {
      setDisableField(false);
      setMobileField(false);
      setMobileFieldOptional(false);
    }
  };

  const updateDefaultCountryCode = () => {
    const defaultCountryObj = (isValidClientId ? updatedSweepstakeCountriesDialCodes : countries)?.find(
      cc => cc.id === localeCountryCode
    );

    setDefaultCountry(defaultCountryObj);
    setFormData({
      country: defaultCountryObj,
      mobilePhoneCountryId: {
        id: defaultCountryObj?.id || '',
        name: defaultCountryObj?.value || '',
        value: defaultCountryObj?.countryDialCode || '',
      },
    });
    phoneNumberValidation(defaultCountryObj?.id);
  };

  const checkLocaleNameSwap = () => {
    const localeSwapCheck = shouldSwapName(localeBasedNameSwapConfig, localeCountryCode);

    if (localeCountryCode === constants.CANADA_COUNTRY_CODE || localeCountryCode === constants.USA_COUNTRY_CODE) {
      setShowZipcodeField(true);
    }

    if (localeSwapCheck) {
      setReverseNamefield(true);
    } else {
      setReverseNamefield(false);
    }
  };

  const populatePrefillData = async (urlQueryParams: string) => {
    const param = new URLSearchParams(urlQueryParams);
    const clientID = param?.get('client_id') ?? '';
    //update default formdata based on prefill response
    if (eaa && prefillResponse) {
      let translatedFirstName, translatedLastName;
      if (prefillResponse?.localizedFirstName && !prefillResponse?.firstName) {
        const firstNameResponse = await englishTranslateFromChinese(prefillResponse?.localizedFirstName as string);
        translatedFirstName = firstNameResponse?.data?.transliterate?.transliteratedText?.[0] as string;
        nameRegExpValidation(
          firstNameResponse?.data?.transliterate?.transliteratedText?.[0],
          'pinyinEnglishName',
          'errorpinyinEnglishNameInline',
          setErrors,
          errors,
          nameInlineErrorMsg
        );
      }
      if (prefillResponse?.localizedLastName && !prefillResponse?.lastName) {
        const lastNameResponse = await englishTranslateFromChinese(prefillResponse?.localizedLastName as string);
        translatedLastName = lastNameResponse?.data?.transliterate?.transliteratedText?.[0] as string;
        nameRegExpValidation(
          lastNameResponse?.data?.transliterate?.transliteratedText?.[0],
          'pinyinEnglishSurname',
          'errorPinyinEnglishSurNameInline',
          setErrors,
          errors,
          nameInlineErrorMsg
        );
      }

      const userCountryCode = prefillResponse?.countryCode || localeCountryCode;
      const userCountryDetails = getCountryDetailByCountryCode(userCountryCode);
      const userPhoneCountryCode = prefillResponse?.phone?.countryCode || userCountryCode;
      const userPhoneCountryDialcode = updatedCountriesDialCodes?.find(country => userPhoneCountryCode === country.id); // to get country id from BE, map from AEM to get country object
      const isPhoneNumberHidden =
        (prefillResponse?.partnerExperience === ENROLL_PATTERN_EXPERIENCES.PATTERN_TWO &&
          (hideMobNumForCountriesArr?.includes(userCountryCode) || hideMobNumForCountriesArr?.includes('ALL'))) ||
        (prefillResponse?.partnerExperience === ENROLL_PATTERN_EXPERIENCES.PATTERN_FOUR &&
          userCountryCode !== constants.CHINA_COUNTRY_CODE);

      setFormData({
        ...formData,
        firstName: isChinaLocale ? prefillResponse?.localizedFirstName : prefillResponse?.firstName || '',
        lastName: isChinaLocale ? prefillResponse?.localizedLastName : prefillResponse?.lastName || '',
        email: prefillResponse?.email || '',
        zipcode: prefillResponse?.postalCode || '',
        mobilePhone: !isPhoneNumberHidden && prefillResponse?.phone?.number ? prefillResponse?.phone?.number : '',
        ...(!isPhoneNumberHidden &&
          userPhoneCountryDialcode && {
            mobilePhoneCountryId: userPhoneCountryDialcode,
          }),
        ...(isChinaLocale && {
          pinyinEnglishName: translatedFirstName || prefillResponse?.firstName,
          pinyinEnglishSurname: translatedLastName || prefillResponse?.lastName,
        }),
      });
      setPrefillObj?.({
        enrollmentSourceCode: prefillResponse?.enrollmentSourceCode,
        partnerUrl: prefillResponse?.redirectUri,
        queryParams: `${prefillResponse?.queryParams}&locale=${currentLocale}&clientId=${clientID}`,
        partnerExperience: prefillResponse?.partnerExperience,
      });
      handleCountryNameChange(
        userCountryDetails,
        isPhoneNumberHidden || !!prefillResponse?.phone?.countryCode || !!prefillResponse?.phone?.number,
        true
      );
      setSelectedCountryCode(userPhoneCountryDialcode?.value || userCountryDetails?.countryDialCode);
    } else {
      updateDefaultCountryCode();
    }
  };

  useEffect(() => {
    const isPasswordValid = passwordConditions.reduce((acc, curr) => acc && curr.isValid, true);
    setPasswordValid(isPasswordValid);
  }, [passwordConditions]);

  //updtOnlyCountry - this attribute is used while decryption of eaa prefill
  const handleCountryNameChange = (option: any, updtOnlyCountry?: boolean, isPrefill?: boolean) => {
    if (option.id === constants.CANADA_COUNTRY_CODE || option.id === constants.USA_COUNTRY_CODE) {
      setFormData({ zipcode: prefillResponse?.postalCode || '' });
      setShowZipcodeField(true);
    } else {
      setFormData({ zipcode: '' });
      setShowZipcodeField(false);
    }

    if (
      option.id === constants.KOREA_COUNTRY_CODE ||
      option.id === constants.CHINA_COUNTRY_CODE ||
      option.id === constants.CHINA_HK_LOCALE_CODE ||
      option.id === constants.CHINA_MO_LOCALE_CODE ||
      option.id === constants.TRADITIONAL_CHINA_COUNTRY_CODE ||
      option.id === constants.JAPAN_COUNTRY_CODE
    ) {
      setReverseNamefield(true);
    } else {
      setReverseNamefield(false);
    }
    !updtOnlyCountry &&
      fetchMobileNumber({ countryCode: option?.countryDialCode, mobileNumber: formData?.mobilePhone });
    phoneNumberValidation(option?.id, isPrefill);
    setFormData({ country: { ...option } });
    resetForm('zipcode');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    value && resetForm(name);

    if (name === 'pwrd') {
      validatePassword(value);
    }

    setFormData({ [name]: value });
  };

  const validatePassword = (value: string) => {
    const str = value;
    const passwordArr = [...passwordConditions];
    if (passwordArr[0]) {
      passwordArr[0].isValid = 8 <= str.length && str.length <= 20;
    }
    if (passwordArr[1]) {
      passwordArr[1].isValid = !!str.match(/[A-Z]/g);
    }
    if (passwordArr[2]) {
      passwordArr[2].isValid = !!str.match(/[a-z]/g);
    }
    if (passwordArr[3]) {
      passwordArr[3].isValid = !!str.match(/^(?=.*[0-9$!#&@?%=_])[A-Za-z0-9$!#&@?%=_]{1,}$/g);
    }
    setPasswordConditions([...passwordArr]);
  };

  const resetForm = (fieldName: string) => {
    const newErrors: any = { ...errors };
    switch (fieldName) {
      case 'firstName':
        newErrors.firstNameError = '';
        break;
      case 'lastName':
        newErrors.lastNameError = '';
        break;
      case 'zipcode':
        newErrors.zipCodeError = '';
        newErrors.zipCodeInvalidError = '';
        newErrors.usPostalCodeInvalidError = '';
        newErrors.canadaPostalCodeInvalidError = '';
        break;
      case 'mobilePhone':
        newErrors.mobilePhoneError = '';
        newErrors.mobilePhoneRequired = '';
        newErrors.mobilePhoneDigitsRequired = '';
        break;
      case 'verificationCodeRequired':
        newErrors.verificationCodeRequired = '';
        break;
      case 'email':
        newErrors.emailErrorLabel = '';
        newErrors.emailInvalidError = '';
        newErrors.existingAccountInlineErrorMessage = '';
        break;
      case 'pwrd':
        newErrors.pwrdErrorLabel = '';
        break;
      case 'confirmPwrd':
        newErrors.confirmAndNewPwrdMismatchMsg = '';
        break;
      case 'pinyinEnglishSurname':
        newErrors.chinaSurNameError = '';
        newErrors.errorPinyinLastName = '';
        break;
      case 'pinyinEnglishName':
        newErrors.chinaNameError = '';
        newErrors.errorPinyinFirstName = '';
        break;

      default:
    }
    setErrors(newErrors);
  };

  const validateAllFields = () => {
    const newErrors: CreateAccountErrors = {};
    const inlineErrors: CreateAccountErrors = {};
    let isValid = true;

    if (!isChinaLocale) {
      if (!formData?.firstName) {
        newErrors.firstNameError = firstNameError;
        isValid = false;
      }

      if (!formData?.lastName) {
        newErrors.lastNameError = lastNameError;
        isValid = false;
      }
      if (formData?.firstName) {
        if (!nameFieldRegex.test(formData?.firstName)) {
          newErrors.errorFirstName = firstNameBannerError;
          isValid = false;
        }
      }

      if (formData?.lastName) {
        if (!nameFieldRegex.test(formData?.lastName)) {
          newErrors.errorLastName = lastNameBannerError;
          isValid = false;
        }
      }
    } else {
      if (chineseCharInput?.firstNameInlineErrorFlag) {
        newErrors.errorFirstName = incorrectChineseFirstNameFormat;
        isValid = false;
      }

      if (chineseCharInput?.lastNameInlineErrorFlag) {
        newErrors.errorLastName = incorrectChineseLastNameFormat;
        isValid = false;
      }

      if (formData?.pinyinEnglishName) {
        if (!nameFieldRegex.test(formData?.pinyinEnglishName?.trim())) {
          newErrors.errorPinyinFirstName = incorrectPinyinEnglishFirstNameFormat;
          isValid = false;
        }
      }

      if (formData?.pinyinEnglishSurname) {
        if (!nameFieldRegex.test(formData?.pinyinEnglishSurname?.trim())) {
          newErrors.errorPinyinLastName = incorrectPinyinEnglishLastNameFormat;
          isValid = false;
        }
      }
    }

    if (isMobileAuthFeatureEnabled || isChinaLocale) {
      if (!formData?.pinyinEnglishSurname) {
        newErrors.chinaSurNameError = chinaSurNameError;
        isValid = false;
      }

      if (!formData?.pinyinEnglishName) {
        newErrors.chinaNameError = chinaNameError;
        isValid = false;
      }
    }

    if (showZipcodeField) {
      if (!formData?.zipcode?.trim()) {
        newErrors.zipCodeError = zipCodeError;
        isValid = false;
      } else if (!checkForValidZipCode(formData?.zipcode, formData?.country?.id)) {
        newErrors.zipCodeInvalidError = zipCodeInvalidError;
        isValid = false;
      }
    }

    if (!formData?.email && !disableField && (!mobileField || !(mobileField && !mobileFieldOptional))) {
      newErrors.emailErrorLabel = emailErrorLabel;
      isValid = false;
    }
    // check if length is less than 5 or it is of incorrect format according to regex
    if (
      !disableField &&
      formData?.email?.trim() &&
      (formData?.email?.trim()?.length < 5 || !emailRegex.test(formData.email?.trim()))
    ) {
      newErrors.emailInvalidError = emailInvalidError;
      isValid = false;
    }

    if (!formData?.pwrd && !disableField) {
      newErrors.pwrdErrorLabel = pwrdErrorLabel;
      isValid = false;
    }

    if ((formData?.pwrd && !formData?.confirmPwrd) || formData.confirmPwrd !== formData.pwrd) {
      newErrors.confirmAndNewPwrdMismatchMsg = confirmAndNewPwrdMismatchMsg;
      isValid = false;
    }
    if (formData.confirmPwrd !== formData.pwrd) {
      newErrors.confirmAndNewPwrdMismatchMsg = confirmAndNewPwrdMismatchMsg;
      isValid = false;
    }

    if (mobileField && !disableField && !mobileFieldOptional) {
      if (!formData?.verificationCode) {
        newErrors.verificationCodeRequired = verificationCodeRequired;
        if (eaa && prefillObj?.partnerExperience === ENROLL_PATTERN_EXPERIENCES.PATTERN_FOUR) {
          inlineErrors.verificationCodeInlineError = verificationCodeInlineError;
        }
        isValid = false;
      }
    }

    if (disableField && mobileField && !mobileFieldOptional) {
      //the mobile field is empty mobilePhoneError shows mobile phone required error message
      if (!formData?.mobilePhone) {
        if (eaa && prefillObj?.partnerExperience === ENROLL_PATTERN_EXPERIENCES.PATTERN_FOUR) {
          newErrors.mobilePhoneRequired = mobilePhoneError;
          inlineErrors.mobilePhoneEmptyError = mobilePhoneEmptyError;
        } else {
          newErrors.mobilePhoneRequired = mobilePhoneError;
          newErrors.mobilePhoneDigitsRequired = mobilePhoneDigitsRequired;
        }
        isValid = false;
      }
    }

    // add mobileField check to perform below validation when phone section is in enable mode
    if (formData?.mobilePhone && mobileField) {
      if (
        (!isChinesePhone && !isValidPhoneNo) ||
        (isChinesePhone && !isChinesePhoneValid) ||
        !NUMBERS_REGEX.test(formData?.mobilePhone)
      ) {
        //the mobile field is invalid mobilePhoneRequired shows mobile phone invalid error message
        newErrors.mobilePhoneRequired = mobilePhoneRequired;
        newErrors.mobilePhoneDigitsRequired = mobilePhoneDigitsRequired;
        if (eaa && prefillObj?.partnerExperience === ENROLL_PATTERN_EXPERIENCES.PATTERN_FOUR) {
          inlineErrors.mobilePhoneInvalidError = mobilePhoneInvalidError;
        }
        isValid = false;
      }
    }
    if (formData?.country?.id === constants.KOREA_COUNTRY_CODE && Object.keys(consentErrors)?.length) {
      if (consentErrors['errorMessagePersonal']) {
        newErrors.errorMessagePersonal = consentErrors['errorMessagePersonal'];
        isValid = false;
      }
      if (consentErrors['errorMessagePromotions']) {
        newErrors.errorMessagePromotions = consentErrors['errorMessagePromotions'];
        isValid = false;
      }
    }
    setErrors({ ...newErrors, ...inlineErrors });
    setErrorsList(Object.values(newErrors));
    !isValid && scrollIntoViewClass('create-account-description-container');
    setFormValidation(isValid);
    setJoinNowBtnClicked(false);
    if (!isValid) {
      resetPwrd();
    }
    return isValid;
  };

  useEffect(() => {
    const newErrors: any = {};
    if (errorKey) {
      errorKey.forEach(error => {
        if (isValidPartnerPortal && errorKey[0] === 'emailDuplicateError') {
          newErrors.existingAccountInlineErrorMessage = partnerPortalObject?.existingAccountInlineErrorMessage;
        } else {
          newErrors[error as keyof CreateAccountFormProps] =
            models[error as keyof CreateAccountFormProps] ?? pageContext?.uxlErrorMessage;
        }
      });
    }
    setErrors(newErrors);
    setErrorsList(Object.values(newErrors));
    Object.values(newErrors)?.length && scrollIntoViewClass('create-account-description-container');
    setFormValidation(false);
    setJoinNowBtnClicked(false);
    resetPwrd();
  }, [errorKey]);

  const checkProhibitedCountries = (randCountry?: string) => {
    let isInvalid = false;
    if (
      prohibitedCountriesArr?.includes(formData?.country?.id) ||
      prohibitedCountriesArr?.includes(localeCountryCode) ||
      (randCountry && prohibitedCountriesArr?.includes(randCountry))
    ) {
      const newErrors: any = {};
      newErrors.prohibitedCountryErrMsg = prohibitedCountryErrMsg;
      setErrors(newErrors);
      setErrorsList(Object.values(newErrors));
      scrollIntoViewClass('create-account-description-container');
      isInvalid = true;
      setJoinNowBtnClicked(false);
    }
    if (isInvalid) {
      resetPwrd();
    }
    return isInvalid;
  };

  // The validateAllFields() function will be executed solely after the 'joinNow' button has received a click event.
  useEffect(() => {
    if (joinNowBtnClicked) {
      if (!checkProhibitedCountries()) {
        validateAllFields();
      }
    }
  }, [joinNowBtnClicked]);

  const [chineseTranslate] = useLazyQuery(phoenixAccountTransliterate, {
    onCompleted: (data: any) => {
      apiLogger(`[CreateAccount] ChineseTranslate - sessionId value: ${sessionData?.sessionToken}: ${inspect(data)}`);
      return data?.transliterate?.transliteratedText?.[0];
    },
    onError: error => {
      apiLogger(
        `[CreateAccount] ChineseTranslate - sessionId value:: ${sessionData?.sessionToken} - error: ${inspect(error)}`
      );
    },
  });

  const isPhoneNumberValid = () => {
    const newErrors: CreateAccountErrors = { phoneAlreadyAssociatedError: '' };
    const inlineErrors: CreateAccountErrors = {};
    let isValid = true;

    if (mobileField && !mobileFieldOptional) {
      //the mobile field is empty mobilePhoneError shows mobile phone required error message
      if (!formData?.mobilePhone) {
        if (eaa) {
          newErrors.mobilePhoneRequired = mobilePhoneError;
          inlineErrors.mobilePhoneEmptyError = mobilePhoneEmptyError;
          isValid = false;
        } else {
          newErrors.mobilePhoneRequired = mobilePhoneError;
          newErrors.mobilePhoneDigitsRequired = mobilePhoneDigitsRequired;
          isValid = false;
        }
      } else if (
        (!isChinesePhone && !isValidPhoneNo) ||
        (isChinesePhone && !isChinesePhoneValid) ||
        !NUMBERS_REGEX.test(formData?.mobilePhone)
      ) {
        newErrors.mobilePhoneRequired = mobilePhoneRequired;
        newErrors.mobilePhoneDigitsRequired = mobilePhoneDigitsRequired;
        isValid = false;
        if (eaa) {
          inlineErrors.mobilePhoneInvalidError = mobilePhoneInvalidError;
        }
      }
    }
    if (isValid) {
      newErrors.mobilePhoneRequired = '';
      newErrors.mobilePhoneDigitsRequired = '';
      inlineErrors.mobilePhoneEmptyError = '';
      inlineErrors.mobilePhoneInvalidError = '';
    }

    const errorMessageList = Object.values(newErrors).filter(value => value);
    setErrors(prevErrors => ({ ...prevErrors, ...newErrors, ...inlineErrors }));
    setErrorsList(errorMessageList);
    const hasErrors = errorMessageList.length === 0;
    !hasErrors && scrollIntoViewClass('create-account-description-container');
    return hasErrors;
  };

  const handleTimerActiveChange = (timerState: boolean) => {
    setIsOtpSent(timerState);
  };

  const handleOtp = async () => {
    if (isPhoneNumberValid()) {
      setisLoading(true);
      try {
        const payload = {
          phoneNumber: `${formData?.mobilePhoneCountryId?.value}${formData?.mobilePhone}`,
        };
        const response = await axios.post(addSubDirectoryPrefix(generateOneTimePwrdForMobileUrl), payload, {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            ...(currentLocale && { locale: currentLocale }),
          },
        });
        setDisableField(false);
        setTransactionDetails(response.data.transactionId);
        setIsOtpSent(true);
        setShowSendCode(true);
        setResetTimer(false);
      } catch (error: any) {
        apiLogger(
          `[CreateAccount] GenerateTransactionDetails - sessionId value:: ${
            sessionData?.sessionToken
          } - error: ${inspect(error)}`
        );
        const newErrors: any = {};
        if (error) {
          const errorMessages = error?.response?.data?.phoenixErrorMessages?.errorMessages || [];
          if (errorMessages.length) {
            errorMessages.forEach((errorKey: string) => {
              if (errorKey === DUPLICATE_PHONE_ERROR_KEY) {
                const url = `${eaa ? constants.EAA_LOGIN_URL : constants.MCOM_LOGIN_URL}${
                  eaa ? window?.location?.search : ''
                }`;
                newErrors.phoneAlreadyAssociatedError = phoneAlreadyAssociatedError?.replace('login_url', url);
              } else {
                newErrors[errorKey as keyof CreateAccountFormProps] =
                  models[errorKey as keyof CreateAccountFormProps] ?? pageContext?.uxlErrorMessage;
              }
            });
          } else {
            newErrors['uxlErrorMessage'] = pageContext?.uxlErrorMessage;
          }
        }
        setErrors(newErrors);
        setErrorsList(Object.values(newErrors));
        Object.values(newErrors)?.length && scrollIntoViewClass('create-account-description-container');
      } finally {
        setisLoading(false);
      }
    }
  };
  const checkChineseValidation = (val: string, fieldType: string) => {
    if (fieldType === 'firstName') {
      if (val && !isChinese(val)) {
        setChineseCharInput({
          ...chineseCharInput,
          firstNameInlineErrorFlag: true,
        });
        setFormData({
          ...formData,
          pinyinEnglishName: '',
        });
      } else {
        setChineseCharInput({
          ...chineseCharInput,
          firstNameInlineErrorFlag: false,
        });
      }
    } else if (fieldType === 'lastName') {
      if (val && !isChinese(val)) {
        setChineseCharInput({
          ...chineseCharInput,
          lastNameInlineErrorFlag: true,
        });
        setFormData({
          ...formData,
          pinyinEnglishSurname: '',
        });
      } else {
        setChineseCharInput({
          ...chineseCharInput,
          lastNameInlineErrorFlag: false,
        });
      }
    }
    return;
  };
  const englishTranslateFromChinese = (textValue: string) => {
    return chineseTranslate({
      variables: {
        input: {
          sourceLanguage: CHINESE_SOURCE_LANG,
          sourceText: textValue,
          targetLanguage: ENGLISH_SOURCE_LANG,
        },
      },
      // skip: skipQuery,
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    });
  };

  const fetchMobileNumber = (obj: any) => {
    setShowSendCode(false);
    const countryId = updatedCountriesDialCodes?.find(country => obj.countryCode === country.value);
    setFormData({
      mobilePhone: obj.mobileNumber,
      mobilePhoneCountryId: countryId,
    });
    setSelectedCountryCode(obj?.countryCode);
    // add partner check to show and hide phone field based on phone country code
    if (obj.countryCode === CHINESE_MOBILE_CODE && (isPatternFour || (isChinaLocale && !isPatternTwo))) {
      setDisableField(true);
      setMobileFieldOptional(false);
    } else {
      setDisableField(false);
      setMobileFieldOptional(true);
    }
  };

  const handleCheckboxChange = () => {
    setRemembermeCheckBox(!remembermeCheckBox);
  };

  const renderEmail = () => {
    return (
      <div className="row">
        <div className="col-12">
          <InputTextField
            label={mobileField && !mobileFieldOptional ? `${emailLabel} (${optionalLabel})` : emailLabel}
            inputValue={formData?.email}
            testId="email-label"
            showErrorMessage={
              !!errors?.emailErrorLabel || !!errors?.emailInvalidError || !!errors?.existingAccountInlineErrorMessage
            }
            messageToShow={
              errorsList?.length && errors?.existingAccountInlineErrorMessage
                ? partnerPortalObject?.existingAccountInlineErrorMessage
                : errors?.emailErrorLabel
                ? emailErrorLabel
                : emailInvalidError
            }
            setErrorHtml={true}
            messageClass="error-label"
            inputMaxLength={80}
            infoLabel={emailLabel}
            getInputProps={() => ({
              autoComplete: 'off',
              id: `${inputId}-Email`,
              disabled: disableField,
            })}
            getLabelProps={() => ({
              htmlFor: `${inputId}-Email`,
            })}
            className={clsx(
              'm-input-field pb-2 mt-5',
              errors?.emailErrorLabel || errors?.emailInvalidError || errors?.existingAccountInlineErrorMessage
                ? 'is-error'
                : formData?.email
                ? 'is-active'
                : '',
              disableField && 'is-disabled'
            )}
            getInputValue={(val: string) => {
              val && resetForm('email');
              setFormData({ ...formData, email: val });
            }}
          />
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <form method="post" data-testid="create-account-form">
        <div className="create-account-form">
          <div className="row">
            <div className="col-12 col-lg-6">
              {reverseNamefield ? (
                <InputTextField
                  label={lastNameLabel}
                  inputValue={formData?.lastName}
                  showErrorMessage={
                    !!errors?.lastNameError || !!errors?.errorLastName || chineseCharInput?.lastNameInlineErrorFlag
                  }
                  messageToShow={
                    checkChinaLocale(currentLocale || '') && errors?.errorLastName
                      ? inlineErrorChineseName
                      : errors?.errorLastName
                      ? nameInlineErrorMsg
                      : errors?.lastNameError
                      ? lastNameError
                      : chineseCharInput?.lastNameInlineErrorFlag
                      ? inlineErrorChineseName
                      : ''
                  }
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={isChinaLocale ? undefined : 25}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    id: `${inputId}-lastName`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-lastName`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.lastNameError || errors?.errorLastName || chineseCharInput?.lastNameInlineErrorFlag
                      ? 'is-error'
                      : formData?.lastName
                      ? 'is-active'
                      : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('lastName');
                    setFormData({ ...formData, lastName: val?.trim() });
                    !isChinaLocale
                      ? nameRegExpValidation(val, 'lastName', 'errorLastName', setErrors, errors, nameInlineErrorMsg)
                      : checkChineseValidation(val, 'lastName');
                  }}
                  onBlur={event => {
                    isChinaLocale &&
                      !chineseCharInput?.lastNameInlineErrorFlag &&
                      event?.target.value !== '' &&
                      englishTranslateFromChinese(event?.target?.value as string).then(data => {
                        setFormData({
                          ...formData,
                          pinyinEnglishSurname: data?.data?.transliterate?.transliteratedText?.[0],
                        });
                        nameRegExpValidation(
                          data?.data?.transliterate?.transliteratedText?.[0],
                          'pinyinEnglishSurname',
                          'errorPinyinEnglishSurNameInline',
                          setErrors,
                          errors,
                          nameInlineErrorMsg
                        );
                      });
                  }}
                />
              ) : (
                <InputTextField
                  label={firstNameLabel}
                  inputValue={formData?.firstName}
                  showErrorMessage={!!errors?.firstNameError || !!errors?.errorFirstName}
                  messageToShow={
                    errors?.errorFirstName ? nameInlineErrorMsg : errors?.firstNameError ? firstNameError : ''
                  }
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={20}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    id: `${inputId}-firstName`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-firstName`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.firstNameError || !!errors?.errorFirstName
                      ? 'is-error'
                      : formData?.firstName
                      ? 'is-active'
                      : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('firstName');
                    setFormData({ ...formData, firstName: val?.trim() });
                    !isChinaLocale &&
                      nameRegExpValidation(val, 'firstName', 'errorFirstName', setErrors, errors, nameInlineErrorMsg);
                  }}
                  onBlur={event => {
                    isChinese(event?.target.value as string) &&
                      event?.target.value !== '' &&
                      englishTranslateFromChinese(event?.target.value as string).then(data => {
                        setFormData({
                          ...formData,
                          pinyinEnglishName: data?.data?.transliterate?.transliteratedText?.[0],
                        });
                        nameRegExpValidation(
                          data?.data?.transliterate?.transliteratedText?.[0],
                          'pinyinEnglishName',
                          'errorpinyinEnglishNameInline',
                          setErrors,
                          errors,
                          nameInlineErrorMsg
                        );
                      });
                  }}
                />
              )}
            </div>
            <div className="col-12 col-lg-6">
              {reverseNamefield ? (
                <InputTextField
                  label={firstNameLabel}
                  inputValue={formData?.firstName}
                  showErrorMessage={
                    !!errors?.firstNameError || !!errors?.errorFirstName || chineseCharInput?.firstNameInlineErrorFlag
                  }
                  messageToShow={
                    checkChinaLocale(currentLocale || '') && errors?.errorFirstName
                      ? inlineErrorChineseName
                      : errors?.errorFirstName
                      ? nameInlineErrorMsg
                      : errors?.firstNameError
                      ? firstNameError
                      : chineseCharInput?.firstNameInlineErrorFlag
                      ? inlineErrorChineseName
                      : ''
                  }
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={isChinaLocale ? undefined : 20}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    id: `${inputId}-firstName`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-firstName`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    chineseCharInput?.firstNameInlineErrorFlag || errors?.firstNameError || errors?.errorFirstName
                      ? 'is-error'
                      : formData?.firstName
                      ? 'is-active'
                      : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('firstName');
                    setFormData({ ...formData, firstName: val?.trim() });
                    !isChinaLocale
                      ? nameRegExpValidation(val, 'firstName', 'errorFirstName', setErrors, errors, nameInlineErrorMsg)
                      : checkChineseValidation(val, 'firstName');
                  }}
                  onBlur={event => {
                    isChinese(event?.target.value as string) &&
                      event?.target.value !== '' &&
                      !chineseCharInput?.firstNameInlineErrorFlag &&
                      englishTranslateFromChinese(event?.target.value as string).then(data => {
                        setFormData({
                          ...formData,
                          pinyinEnglishName: data?.data?.transliterate?.transliteratedText?.[0],
                        });
                        nameRegExpValidation(
                          data?.data?.transliterate?.transliteratedText?.[0],
                          'pinyinEnglishName',
                          'errorpinyinEnglishNameInline',
                          setErrors,
                          errors,
                          nameInlineErrorMsg
                        );
                      });
                  }}
                />
              ) : (
                <InputTextField
                  label={lastNameLabel}
                  inputValue={formData?.lastName}
                  showErrorMessage={!!errors?.lastNameError || !!errors?.errorLastName}
                  messageToShow={
                    errors?.errorLastName ? nameInlineErrorMsg : errors?.lastNameError ? lastNameError : ''
                  }
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={25}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    // onKeyUp: handleKeyPress,
                    id: `${inputId}-lastName`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-lastName`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.lastNameError || errors?.errorLastName ? 'is-error' : formData?.lastName ? 'is-active' : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('lastName');
                    setFormData({ ...formData, lastName: val?.trim() });
                    !isChinaLocale &&
                      nameRegExpValidation(val, 'lastName', 'errorLastName', setErrors, errors, nameInlineErrorMsg);
                  }}
                  onBlur={event => {
                    isChinaLocale &&
                      event?.target.value !== '' &&
                      englishTranslateFromChinese(event?.target?.value as string).then(data => {
                        setFormData({
                          ...formData,
                          pinyinEnglishSurname: data?.data?.transliterate?.transliteratedText?.[0],
                        });
                        nameRegExpValidation(
                          data?.data?.transliterate?.transliteratedText?.[0],
                          'pinyinEnglishSurname',
                          'errorPinyinEnglishSurNameInline',
                          setErrors,
                          errors,
                          nameInlineErrorMsg
                        );
                      });
                  }}
                />
              )}
            </div>
          </div>
          {(isMobileAuthFeatureEnabled || isChinaLocale) && (
            <div className="row">
              <div className="col-12 col-lg-6">
                <InputTextField
                  label={pinyinEnglishSurname}
                  inputValue={formData?.pinyinEnglishSurname}
                  showErrorMessage={
                    !!errors?.chinaSurNameError ||
                    !!errors?.errorPinyinEnglishSurNameInline ||
                    !!errors?.errorPinyinLastName
                  }
                  messageToShow={
                    errors?.errorPinyinEnglishSurNameInline || errors?.errorPinyinLastName
                      ? nameInlineErrorMsg
                      : chinaSurNameError
                  }
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={25}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    id: `${inputId}-pinyinEnglishSurname`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-pinyinEnglishSurname`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.chinaSurNameError || errors?.errorPinyinLastName
                      ? 'is-error'
                      : formData?.pinyinEnglishSurname
                      ? 'is-active'
                      : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('pinyinEnglishSurname');
                    setFormData({ ...formData, pinyinEnglishSurname: val?.trim() });
                    nameRegExpValidation(
                      val,
                      'pinyinEnglishSurname',
                      'errorPinyinEnglishSurNameInline',
                      setErrors,
                      errors,
                      nameInlineErrorMsg
                    );
                  }}
                  showBadge={!!inlineMessageForName}
                  badgeValue={inlineMessageForName}
                />
              </div>
              <div className="col-12 col-lg-6">
                <InputTextField
                  label={pinyinEnglishName}
                  inputValue={formData?.pinyinEnglishName}
                  showErrorMessage={
                    !!errors?.chinaNameError || !!errors?.errorpinyinEnglishNameInline || !!errors?.errorPinyinFirstName
                  }
                  messageToShow={
                    errors?.errorpinyinEnglishNameInline || errors?.errorPinyinFirstName
                      ? nameInlineErrorMsg
                      : chinaSurNameError
                  }
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={20}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    id: `${inputId}-pinyinEnglishName`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-pinyinEnglishName`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.chinaNameError || errors?.errorPinyinFirstName
                      ? 'is-error'
                      : formData?.pinyinEnglishName
                      ? 'is-active'
                      : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('pinyinEnglishName');
                    setFormData({ ...formData, pinyinEnglishName: val?.trim() });
                    nameRegExpValidation(
                      val,
                      'pinyinEnglishName',
                      'errorpinyinEnglishNameInline',
                      setErrors,
                      errors,
                      nameInlineErrorMsg
                    );
                  }}
                  showBadge={!!inlineMessageForName}
                  badgeValue={inlineMessageForName}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className={clsx(`col-12 ${showZipcodeField ? 'col-lg-6' : 'col-lg-12'}`)}>
              <div className="m-input-field pb-2 mt-5">
                <label
                  className="country-label"
                  id="dropdown-label-fp-country-code"
                  htmlFor="dropdown-label-fp-country-code"
                  data-testid="dropdown-label-fp-country"
                >
                  {countryRegionLabel}
                </label>
                <Dropdown
                  defaultOption={formData?.country?.value || defaultCountry?.value || ''}
                  dropdownOptions={isValidClientId ? updatedSweepstakeCountriesDialCodes ?? [] : countries || []}
                  onChange={handleCountryNameChange}
                  customClassName="activate_account--countrydropdown is-active"
                  dropdownId="fp-country-code"
                />
              </div>
            </div>
            {!!showZipcodeField && (
              <div className="col-12 col-lg-6">
                <InputTextField
                  label={zipPostalCodeLabel}
                  inputValue={formData?.zipcode}
                  showErrorMessage={
                    !!errors?.zipCodeError ||
                    !!errors?.zipCodeInvalidError ||
                    !!errors?.canadaPostalCodeInvalidError ||
                    !!errors?.usPostalCodeInvalidError
                  }
                  messageToShow={errors?.zipCodeError ? zipCodeError : zipInlineError}
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={10}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    // onKeyUp: handleKeyPress,
                    id: `${inputId}-Zipcode`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-Zipcode`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.zipCodeError || errors?.zipCodeInvalidError
                      ? 'is-error'
                      : formData?.zipcode
                      ? 'is-active'
                      : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('zipcode');
                    setFormData({ ...formData, zipcode: val });
                  }}
                />
              </div>
            )}
          </div>
          {(disableField || mobileField) && (
            <div className="row">
              <div className="col-12">
                <div className={`m-input-field py-2 mt-5 phone-field`}>
                  <PhoneNumberField
                    inputTextLabel={mobileFieldOptional ? `${mobilePhoneLabel} (${optionalLabel})` : mobilePhoneLabel}
                    label={countryCodeLabel}
                    defaultCCOption={
                      formData?.mobilePhoneCountryId?.value
                        ? formData?.mobilePhoneCountryId?.value
                        : isValidClientId && !isChinaLocale
                        ? (updatedCountriesDialCodes ?? [])?.find(country => country?.id === defaultCountry?.id)
                            ?.value ?? constants?.USA_COUNTRY_DIAL_CODE
                        : CHINESE_MOBILE_CODE
                    }
                    dropdownCCOptions={updatedCountriesDialCodes ?? []}
                    mobileInputVal={formData?.mobilePhone?.replace(selectedCountryCode || '', '') || ''}
                    isInputError={
                      !!errors?.mobilePhoneError ||
                      !!errors?.mobilePhoneRequired ||
                      !!errors.phoneAlreadyAssociatedError
                    }
                    inputErrorMsg={errors.phoneAlreadyAssociatedError ? existingPhoneNumberWarning : mobilePhoneError}
                    disabled={false}
                    fetchMobileNumber={(obj: any) => fetchMobileNumber(obj)}
                    isNonChinaMobileEnabled={!isNonChinaMobileEnabled}
                    isRegisteredNumber={false}
                    isMobileEditable={true}
                    showIdandValueinOptions={true}
                    badgeProp={
                      (isMobileAuthFeatureEnabled || isChinaLocale) &&
                      formData?.country?.id === constants?.CHINA_COUNTRY_CODE &&
                      formData?.mobilePhoneCountryId?.value === CHINESE_MOBILE_CODE &&
                      sendVerificationDescription
                    }
                  />
                  {(isMobileAuthFeatureEnabled || isChinaLocale) &&
                    formData?.country?.id === constants?.CHINA_COUNTRY_CODE &&
                    formData?.mobilePhoneCountryId?.value === CHINESE_MOBILE_CODE && (
                      <Button
                        type={Types.ButtonTypeVariation.Button}
                        buttonCopy={''}
                        className={clsx(
                          'm-button-m m-button-primary submit-btn',
                          showSendCode || isLoading || !formData?.mobilePhone ? 'disabled' : ''
                        )}
                        isLink={false}
                        callback={handleOtp}
                        isDisabled={showSendCode || isLoading}
                      >
                        {isLoading ? <div className="m-spinner-s"></div> : sendVerificationLabel}
                      </Button>
                    )}
                </div>
                {(isMobileAuthFeatureEnabled || isChinaLocale) &&
                  formData?.country?.id === constants?.CHINA_COUNTRY_CODE &&
                  formData?.mobilePhoneCountryId?.value === CHINESE_MOBILE_CODE && (
                    <div className={clsx('m-input-field py-2 mt-5', disableField ? 'is-disabled' : '')}>
                      <InputTextField
                        iconClass="icon icon-clear"
                        // showIcon={otpError.otp !== undefined}
                        type="number"
                        label={verificationCodeLabel}
                        inputValue={formData.verificationCode}
                        showErrorMessage={!!errors?.verificationCodeRequired}
                        messageToShow={verificationCodeRequired}
                        setErrorHtml={true}
                        messageClass="error-label"
                        className={clsx('m-input-field', disableField && 'is-disabled')}
                        inputMaxLength={10}
                        getInputValue={(val: string) => {
                          setFormData({ ...formData, verificationCode: val });
                        }}
                        getLabelProps={() => ({
                          htmlFor: `${inputId}-verificationCode`,
                        })}
                        getInputProps={() => ({
                          autoComplete: 'off',
                          // onKeyUp: handleKeyPress,
                          id: `${inputId}-verificationCode`,
                          disabled: disableField,
                        })}
                      />

                      {showSendCode && (
                        <div className={clsx(' t-font-xs d-flex mt-1', disableField ? 'is-disabled' : '')}>
                          {pageContext?.enableTimer === 'true' && models?.timer ? (
                            <OtpTimer
                              handleOtp={handleOtp}
                              onTimerActiveChange={handleTimerActiveChange}
                              isOtpSent={isOtpSent}
                              timer={models?.timer}
                              timerRunningLabel={models?.timerRunningLabel}
                              resendNewCodeLabel={models?.resendANewCode}
                              otpValidationError={
                                errors?.incorrectVerificationCode || errors?.expiredTransactionCode || hasOtpError
                                  ? true
                                  : false
                              }
                              incorrectCodeRunningLabel={models?.incorrectCodeRunningLabel}
                              verificationCodeInlineMessage={models?.otpIncorrectErrorMessage}
                              didNotGetACodeLabel={models?.didNotGetACode}
                              resetTimer={resetTimer}
                            />
                          ) : (
                            <>
                              <RichText
                                text={
                                  errors?.incorrectVerificationCode || errors?.expiredTransactionCode
                                    ? models?.otpIncorrectErrorMessage
                                    : models?.didNotGetACode
                                }
                                componentId="verification-code"
                                customClass={clsx(
                                  errors?.incorrectVerificationCode || errors?.expiredTransactionCode || hasOtpError
                                    ? 'error-label'
                                    : ''
                                )}
                              />
                              <div
                                onClick={handleOtp}
                                className={clsx(
                                  errors?.incorrectVerificationCode || errors?.expiredTransactionCode || hasOtpError
                                    ? 'error-label'
                                    : '',
                                  'ml-1 pointer-cursor help-text-decoration mr-3 resend-code'
                                )}
                                data-testid="verify_code"
                              >
                                <RichText text={models?.resendANewCode} componentId="resend-new-code" />
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </div>
          )}
          {renderEmail()}
          <div className="row">
            <div className="col-12 col-lg-6 mt-5">
              <PasswordInput
                value={formData.pwrd}
                id="password"
                name="pwrd"
                disable={disableField}
                hasError={!!errors?.pwrdErrorLabel || !!errors?.confirmAndNewPwrdMismatchMsg}
                label={pwrdLabel}
                passwordType={passwordType}
                error={errors?.pwrdErrorLabel || errors?.confirmAndNewPwrdMismatchMsg}
                customClass={clsx('mb-2', disableField && 'is-disabled', 'create-account-password')}
                iconClass={`password-icon ${passwordType === 'password' ? 'icon-visibility-off' : 'icon-visibility'}`}
                labelClass="t-body-s"
                showValidations={true}
                passwordConditions={passwordConditions}
                handleChange={handleChange}
                onIconClick={() => setPasswordType(passwordType === 'password' ? 'text' : 'password')}
                isValid={passwordValid}
                showCheckIcon={true}
                ariaLabel="createaccount-password"
                descriptionClass="password-checks__description"
                errorLabelClass="password-checks__errorlabel"
              />
            </div>
            <div className="col-12 col-lg-6 pb-2 mt-5">
              <PasswordInput
                value={formData.confirmPwrd}
                id="confirmPassword"
                name="confirmPwrd"
                disable={isValidChangePassword}
                hasError={false}
                label={confirmPwrdLabel}
                passwordType={confirmPasswordType}
                customClass="mb-4"
                iconClass={confirmPasswordType === 'password' ? 'icon-visibility-off' : 'icon-visibility'}
                labelClass="t-body-s"
                handleChange={handleChange}
                onIconClick={() => setConfirmPasswordType(confirmPasswordType === 'password' ? 'text' : 'password')}
                isValid={formData.confirmPwrd?.length && formData.confirmPwrd === formData.pwrd ? true : false}
                showCheckIcon={true}
                ariaLabel="createaccount-confirm-password"
                descriptionClass="password-checks__description"
                errorLabelClass="password-checks__errorlabel"
              />
            </div>
          </div>
        </div>
      </form>
    );
  };

  const renderEAAForm = () => {
    return (
      <form method="post" data-testid="create-account-form">
        <div className="create-account-form">
          <div className="row">
            <div className="col-12 col-lg-6">
              {reverseNamefield ? (
                <InputTextField
                  label={lastNameLabel}
                  inputValue={formData?.lastName}
                  showErrorMessage={
                    !!errors?.lastNameError || !!errors?.errorLastName || chineseCharInput?.lastNameInlineErrorFlag
                  }
                  messageToShow={
                    checkChinaLocale(currentLocale || '') && errors?.errorLastName
                      ? inlineErrorChineseName
                      : errors?.errorLastName
                      ? nameInlineErrorMsg
                      : errors?.lastNameError
                      ? lastNameError
                      : chineseCharInput?.lastNameInlineErrorFlag
                      ? inlineErrorChineseName
                      : ''
                  }
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={isChinaLocale ? undefined : 25}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    // onKeyUp: handleKeyPress,
                    id: `${inputId}-lastName`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-lastName`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.lastNameError || errors?.errorLastName || chineseCharInput?.lastNameInlineErrorFlag
                      ? 'is-error'
                      : formData?.lastName
                      ? 'is-active'
                      : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('lastName');
                    setFormData({ ...formData, lastName: val?.trim() });
                    !isChinaLocale
                      ? nameRegExpValidation(val, 'lastName', 'errorLastName', setErrors, errors, nameInlineErrorMsg)
                      : checkChineseValidation(val, 'lastName');
                  }}
                  onBlur={event => {
                    isChinaLocale &&
                      !chineseCharInput?.lastNameInlineErrorFlag &&
                      event?.target.value !== '' &&
                      englishTranslateFromChinese(event?.target?.value as string).then(data => {
                        setFormData({
                          ...formData,
                          pinyinEnglishSurname: data?.data?.transliterate?.transliteratedText?.[0],
                        });
                        nameRegExpValidation(
                          data?.data?.transliterate?.transliteratedText?.[0],
                          'pinyinEnglishSurname',
                          'errorPinyinEnglishSurNameInline',
                          setErrors,
                          errors,
                          nameInlineErrorMsg
                        );
                      });
                  }}
                />
              ) : (
                <InputTextField
                  label={firstNameLabel}
                  inputValue={formData?.firstName}
                  showErrorMessage={!!errors?.firstNameError || !!errors?.errorFirstName}
                  messageToShow={
                    errors?.errorFirstName ? nameInlineErrorMsg : errors?.firstNameError ? firstNameError : ''
                  }
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={20}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    id: `${inputId}-firstName`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-firstName`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.firstNameError || errors?.errorFirstName
                      ? 'is-error'
                      : formData?.firstName
                      ? 'is-active'
                      : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('firstName');
                    setFormData({ ...formData, firstName: val?.trim() });
                    !isChinaLocale &&
                      nameRegExpValidation(val, 'firstName', 'errorFirstName', setErrors, errors, nameInlineErrorMsg);
                  }}
                  onBlur={event => {
                    isChinese(event?.target.value as string) &&
                      event?.target.value !== '' &&
                      englishTranslateFromChinese(event?.target.value as string).then(data => {
                        setFormData({
                          ...formData,
                          pinyinEnglishName: data?.data?.transliterate?.transliteratedText?.[0],
                        });
                        nameRegExpValidation(
                          data?.data?.transliterate?.transliteratedText?.[0],
                          'pinyinEnglishName',
                          'errorpinyinEnglishNameInline',
                          setErrors,
                          errors,
                          nameInlineErrorMsg
                        );
                      });
                  }}
                />
              )}
            </div>
            <div className="col-12 col-lg-6">
              {reverseNamefield ? (
                <InputTextField
                  label={firstNameLabel}
                  inputValue={formData?.firstName}
                  showErrorMessage={
                    !!errors?.firstNameError || !!errors?.errorFirstName || chineseCharInput?.firstNameInlineErrorFlag
                  }
                  messageToShow={
                    checkChinaLocale(currentLocale || '') && errors?.errorFirstName
                      ? inlineErrorChineseName
                      : errors?.errorFirstName
                      ? nameInlineErrorMsg
                      : errors?.firstNameError
                      ? firstNameError
                      : chineseCharInput?.firstNameInlineErrorFlag
                      ? inlineErrorChineseName
                      : ''
                  }
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={isChinaLocale ? undefined : 20}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    id: `${inputId}-firstName`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-firstName`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    chineseCharInput?.firstNameInlineErrorFlag || errors?.firstNameError || errors?.errorFirstName
                      ? 'is-error'
                      : formData?.firstName
                      ? 'is-active'
                      : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('firstName');
                    setFormData({ ...formData, firstName: val?.trim() });
                    !isChinaLocale
                      ? nameRegExpValidation(val, 'firstName', 'errorFirstName', setErrors, errors, nameInlineErrorMsg)
                      : checkChineseValidation(val, 'firstName');
                  }}
                  onBlur={event => {
                    isChinese(event?.target.value as string) &&
                      !chineseCharInput?.firstNameInlineErrorFlag &&
                      event?.target.value !== '' &&
                      englishTranslateFromChinese(event?.target.value as string).then(data => {
                        setFormData({
                          ...formData,
                          pinyinEnglishName: data?.data?.transliterate?.transliteratedText?.[0],
                        });
                        nameRegExpValidation(
                          data?.data?.transliterate?.transliteratedText?.[0],
                          'pinyinEnglishName',
                          'errorpinyinEnglishNameInline',
                          setErrors,
                          errors,
                          nameInlineErrorMsg
                        );
                      });
                  }}
                />
              ) : (
                <InputTextField
                  label={lastNameLabel}
                  inputValue={formData?.lastName}
                  showErrorMessage={!!errors?.lastNameError || !!errors?.errorLastName}
                  messageToShow={
                    errors?.errorLastName ? nameInlineErrorMsg : errors?.lastNameError ? lastNameError : ''
                  }
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={25}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    // onKeyUp: handleKeyPress,
                    id: `${inputId}-lastName`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-lastName`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.lastNameError || errors?.errorLastName ? 'is-error' : formData?.lastName ? 'is-active' : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('lastName');
                    setFormData({ ...formData, lastName: val?.trim() });
                    !isChinaLocale &&
                      nameRegExpValidation(val, 'lastName', 'errorLastName', setErrors, errors, nameInlineErrorMsg);
                  }}
                  onBlur={event => {
                    isChinaLocale &&
                      event?.target.value !== '' &&
                      englishTranslateFromChinese(event?.target?.value as string).then(data => {
                        setFormData({
                          ...formData,
                          pinyinEnglishSurname: data?.data?.transliterate?.transliteratedText?.[0],
                        });
                        nameRegExpValidation(
                          data?.data?.transliterate?.transliteratedText?.[0],
                          'pinyinEnglishSurname',
                          'errorPinyinEnglishSurNameInline',
                          setErrors,
                          errors,
                          nameInlineErrorMsg
                        );
                      });
                  }}
                />
              )}
            </div>
          </div>
          {(isMobileAuthFeatureEnabled || isChinaLocale) && (
            <div className="row">
              <div className="col-12 col-lg-6">
                <InputTextField
                  label={pinyinEnglishSurname}
                  inputValue={formData?.pinyinEnglishSurname}
                  showErrorMessage={
                    !!errors?.chinaSurNameError ||
                    !!errors?.errorPinyinEnglishSurNameInline ||
                    !!errors?.errorPinyinLastName
                  }
                  messageToShow={
                    errors?.errorPinyinEnglishSurNameInline || errors?.errorPinyinLastName
                      ? nameInlineErrorMsg
                      : chinaSurNameError
                  }
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={25}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    id: `${inputId}-pinyinEnglishSurname`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-pinyinEnglishSurname`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.chinaSurNameError || errors?.errorPinyinLastName
                      ? 'is-error'
                      : formData?.pinyinEnglishSurname
                      ? 'is-active'
                      : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('pinyinEnglishSurname');
                    setFormData({ ...formData, pinyinEnglishSurname: val?.trim() });
                    nameRegExpValidation(
                      val,
                      'pinyinEnglishSurname',
                      'errorPinyinEnglishSurNameInline',
                      setErrors,
                      errors,
                      nameInlineErrorMsg
                    );
                  }}
                  showBadge={!!inlineMessageForName}
                  badgeValue={inlineMessageForName}
                />
              </div>
              <div className="col-12 col-lg-6">
                <InputTextField
                  label={pinyinEnglishName}
                  inputValue={formData?.pinyinEnglishName}
                  showErrorMessage={
                    !!errors?.errorpinyinEnglishNameInline || !!errors?.errorPinyinFirstName || !!errors?.chinaNameError
                  }
                  messageToShow={
                    errors?.errorpinyinEnglishNameInline || errors?.errorPinyinFirstName
                      ? nameInlineErrorMsg
                      : chinaSurNameError
                  }
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={20}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    id: `${inputId}-pinyinEnglishName`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-pinyinEnglishName`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.chinaNameError || errors?.errorPinyinFirstName
                      ? 'is-error'
                      : formData?.pinyinEnglishName
                      ? 'is-active'
                      : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('pinyinEnglishName');
                    setFormData({ ...formData, pinyinEnglishName: val?.trim() });
                    nameRegExpValidation(
                      val,
                      'pinyinEnglishName',
                      'errorpinyinEnglishNameInline',
                      setErrors,
                      errors,
                      nameInlineErrorMsg
                    );
                  }}
                  showBadge={!!inlineMessageForName}
                  badgeValue={inlineMessageForName}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className={clsx(`col-12 ${showZipcodeField ? 'col-lg-6' : 'col-lg-12'}`)}>
              <div className="m-input-field pb-2 mt-5">
                <label
                  className="country-label"
                  id="dropdown-label-fp-country-code"
                  htmlFor="dropdown-label-fp-country-code"
                  data-testid="dropdown-label-fp-country"
                >
                  {countryRegionLabel}
                </label>
                <Dropdown
                  defaultOption={formData?.country?.value || defaultCountry?.value || ''}
                  dropdownOptions={isValidClientId ? updatedSweepstakeCountriesDialCodes ?? [] : countries || []}
                  onChange={handleCountryNameChange}
                  customClassName="activate_account--countrydropdown is-active"
                  dropdownId="fp-country-code"
                />
              </div>
            </div>
            {!!showZipcodeField && (
              <div className="col-12 col-lg-6">
                <InputTextField
                  label={zipPostalCodeLabel}
                  inputValue={formData?.zipcode}
                  showErrorMessage={
                    !!errors?.zipCodeError ||
                    !!errors?.zipCodeInvalidError ||
                    !!errors?.canadaPostalCodeInvalidError ||
                    !!errors?.usPostalCodeInvalidError
                  }
                  messageToShow={errors?.zipCodeError ? zipCodeError : zipInlineError}
                  setErrorHtml={true}
                  messageClass="error-label"
                  inputMaxLength={10}
                  getInputProps={() => ({
                    autoComplete: 'off',
                    // onKeyUp: handleKeyPress,
                    id: `${inputId}-Zipcode`,
                  })}
                  getLabelProps={() => ({
                    htmlFor: `${inputId}-Zipcode`,
                  })}
                  className={clsx(
                    'm-input-field pb-2 mt-5',
                    errors?.zipCodeError || errors?.zipCodeInvalidError
                      ? 'is-error'
                      : formData?.zipcode
                      ? 'is-active'
                      : ''
                  )}
                  getInputValue={(val: string) => {
                    val && resetForm('zipcode');
                    setFormData({ ...formData, zipcode: val });
                  }}
                />
              </div>
            )}
          </div>
          {prefillObj?.partnerExperience === ENROLL_PATTERN_EXPERIENCES.PATTERN_TWO && renderEmail()}
          {(disableField || mobileField) && (
            <div className="row">
              <div className="col-12">
                <div className={`m-input-field py-2 mt-5 phone-field`}>
                  <PhoneNumberField
                    inputTextLabel={mobileFieldOptional ? `${mobilePhoneLabel} (${optionalLabel})` : mobilePhoneLabel}
                    label={countryCodeLabel}
                    defaultCCOption={
                      formData?.mobilePhoneCountryId?.value
                        ? formData?.mobilePhoneCountryId?.value
                        : (updatedCountriesDialCodes ?? [])?.find(country => country?.id === defaultCountry?.id)
                            ?.value ?? constants?.USA_COUNTRY_DIAL_CODE
                    }
                    dropdownCCOptions={updatedCountriesDialCodes ?? []}
                    mobileInputVal={formData?.mobilePhone?.replace(selectedCountryCode || '', '') || ''}
                    isInputError={
                      !!errors?.mobilePhoneError ||
                      !!errors?.mobilePhoneRequired ||
                      !!errors.phoneAlreadyAssociatedError
                    }
                    inputErrorMsg={
                      errors.phoneAlreadyAssociatedError
                        ? existingPhoneNumberWarning
                        : errors?.mobilePhoneEmptyError || errors?.mobilePhoneInvalidError || mobilePhoneError
                    }
                    disabled={false}
                    fetchMobileNumber={(obj: any) => fetchMobileNumber(obj)}
                    isNonChinaMobileEnabled={!isNonChinaMobileEnabled}
                    isRegisteredNumber={false}
                    isMobileEditable={true}
                    showIdandValueinOptions={true}
                    badgeProp={
                      mobileField &&
                      !mobileFieldOptional &&
                      formData?.country?.id === constants?.CHINA_COUNTRY_CODE &&
                      formData?.mobilePhoneCountryId?.value === CHINESE_MOBILE_CODE
                        ? sendVerificationDescription
                        : ''
                    }
                  />
                  {mobileField &&
                    !mobileFieldOptional &&
                    formData?.country?.id === constants?.CHINA_COUNTRY_CODE &&
                    formData?.mobilePhoneCountryId?.value === CHINESE_MOBILE_CODE && (
                      <Button
                        type={Types.ButtonTypeVariation.Button}
                        buttonCopy={''}
                        className={clsx(
                          'm-button-m m-button-primary submit-btn',
                          showSendCode || isLoading || !formData?.mobilePhone ? 'disabled' : ''
                        )}
                        isLink={false}
                        callback={handleOtp}
                        isDisabled={showSendCode || isLoading}
                      >
                        {isLoading ? <div className="m-spinner-s"></div> : sendVerificationLabel}
                      </Button>
                    )}
                </div>
                {mobileField &&
                  !mobileFieldOptional &&
                  formData?.country?.id === constants?.CHINA_COUNTRY_CODE &&
                  formData?.mobilePhoneCountryId?.value === CHINESE_MOBILE_CODE && (
                    <div className={clsx('m-input-field py-2 mt-5', disableField ? 'disableField' : '')}>
                      <InputTextField
                        iconClass="icon icon-clear"
                        // showIcon={otpError.otp !== undefined}
                        type="number"
                        label={verificationCodeLabel}
                        inputValue={formData.verificationCode}
                        showErrorMessage={!!errors?.verificationCodeRequired}
                        messageToShow={
                          errors?.verificationCodeInlineError
                            ? errors?.verificationCodeInlineError
                            : verificationCodeRequired
                        }
                        setErrorHtml={true}
                        messageClass={clsx('error-label', disableField && 'is-disabled')}
                        className={clsx('m-input-field', disableField && 'is-disabled')}
                        inputMaxLength={10}
                        getInputValue={(val: string) => {
                          setFormData({ ...formData, verificationCode: val });
                        }}
                        getLabelProps={() => ({
                          htmlFor: `${inputId}-verificationCode`,
                        })}
                        getInputProps={() => ({
                          autoComplete: 'off',
                          // onKeyUp: handleKeyPress,
                          id: `${inputId}-verificationCode`,
                          disabled: disableField,
                        })}
                      />

                      {showSendCode && (
                        <div className={clsx(' t-font-xs d-flex mt-1', disableField ? 'is-disabled' : '')}>
                          {pageContext?.enableTimer === 'true' && models?.timer ? (
                            <OtpTimer
                              handleOtp={handleOtp}
                              onTimerActiveChange={handleTimerActiveChange}
                              isOtpSent={isOtpSent}
                              timer={models?.timer}
                              timerRunningLabel={models?.timerRunningLabel}
                              resendNewCodeLabel={models?.resendANewCode}
                              otpValidationError={
                                errors?.incorrectVerificationCode || errors?.expiredTransactionCode || hasOtpError
                                  ? true
                                  : false
                              }
                              incorrectCodeRunningLabel={models?.incorrectCodeRunningLabel}
                              verificationCodeInlineMessage={models?.otpIncorrectErrorMessage}
                              didNotGetACodeLabel={models?.didNotGetACode}
                              resetTimer={resetTimer}
                            />
                          ) : (
                            <>
                              <RichText
                                text={
                                  errors?.incorrectVerificationCode || errors?.expiredTransactionCode
                                    ? models?.otpIncorrectErrorMessage
                                    : models?.didNotGetACode
                                }
                                componentId="verification-code"
                                customClass={clsx(
                                  errors?.incorrectVerificationCode || errors?.expiredTransactionCode || hasOtpError
                                    ? 'error-label'
                                    : ''
                                )}
                              />
                              <div
                                onClick={handleOtp}
                                className={clsx(
                                  errors?.incorrectVerificationCode || errors?.expiredTransactionCode || hasOtpError
                                    ? 'error-label'
                                    : '',
                                  'ml-1 pointer-cursor help-text-decoration mr-3 resend-code'
                                )}
                                data-testid="verify_code_eaa"
                              >
                                <RichText text={models?.resendANewCode} componentId="resend-new-code" />
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </div>
          )}
          {prefillObj?.partnerExperience !== ENROLL_PATTERN_EXPERIENCES.PATTERN_TWO && renderEmail()}
          <div className={clsx('row', disableField && 'disableField')}>
            <div className="col-12 col-lg-6 mt-5 create-password-eaa">
              <PasswordInput
                value={formData.pwrd}
                id="password"
                name="pwrd"
                disable={disableField}
                hasError={!!errors?.pwrdErrorLabel || !!errors?.confirmAndNewPwrdMismatchMsg}
                label={createPwrdLabel || ''} // to add label from AEM for createPassword and remove condition
                passwordType={passwordType}
                error={errors?.pwrdErrorLabel || errors?.confirmAndNewPwrdMismatchMsg}
                customClass={clsx('mb-2 ', disableField && 'is-disabled')}
                iconClass={`password-icon ${passwordType === 'password' ? 'icon-visibility-off' : 'icon-visibility'}`}
                labelClass="t-body-s"
                showValidations={true}
                passwordConditions={passwordConditions}
                handleChange={handleChange}
                onIconClick={() => setPasswordType(passwordType === 'password' ? 'text' : 'password')}
                isValid={passwordValid}
                showCheckIcon={true}
                ariaLabel="createaccount-password"
                descriptionClass="password-checks__description"
                errorLabelClass="password-checks__errorlabel"
              />
            </div>
            <div className="col-12 col-lg-6 pb-2 mt-5">
              <PasswordInput
                value={formData.confirmPwrd}
                id="confirmPassword"
                name="confirmPwrd"
                disable={isValidChangePassword}
                hasError={false}
                label={confirmPwrdLabel}
                passwordType={confirmPasswordType}
                customClass="mb-lg-4"
                iconClass={confirmPasswordType === 'password' ? 'icon-visibility-off' : 'icon-visibility'}
                labelClass="t-body-s"
                handleChange={handleChange}
                onIconClick={() => setConfirmPasswordType(confirmPasswordType === 'password' ? 'text' : 'password')}
                isValid={formData.confirmPwrd?.length && formData.confirmPwrd === formData.pwrd ? true : false}
                showCheckIcon={true}
                ariaLabel="createaccount-confirm-password"
                descriptionClass="password-checks__description"
                errorLabelClass="password-checks__errorlabel"
              />
            </div>
          </div>
        </div>
      </form>
    );
  };

  return (
    <StyledCreateAccountForm className="mb-3">
      {(!!errorsList?.length || (isOCJFlow && bannerMsgs?.key)) && (
        <div>
          <Messages messageType="error-sev1" className="mt-3 account-page-error-msg mb-4 px-2">
            {errors?.prohibitedCountryErrMsg ? (
              <RichText text={prohibitedCountryErrMsg} componentId="error-span" />
            ) : errors?.uxlFailureErrorMessage ? (
              <RichText text={pageContext?.uxlErrorMessage} componentId={'uxl-failure-msg'} />
            ) : errors?.phoneAlreadyAssociatedError ? (
              <RichText text={errors?.phoneAlreadyAssociatedError} componentId={'phone-number-already-associated'} />
            ) : bannerMsgs?.key ? (
              <RichText
                text={
                  bannerMsgs?.key === ONE_CLICK_JOIN_ERROR.EMAIL_DUPLICATE_ERROR
                    ? emailDuplicateErrMsg
                    : pageContext?.uxlErrorMessage
                }
                componentId="error-span"
              />
            ) : isValidPartnerPortal && errors?.existingAccountInlineErrorMessage ? (
              <RichText
                text={partnerPortalObject?.existingAccountErrorMessage?.replace('return_to_url', goBackUrl) ?? ''}
                componentId={'partner-portal-error'}
              />
            ) : (
              <>
                <span>{errorMessageHeader}</span>
                <ul>
                  {errorsList.map((error, index) => (
                    <li key={index}>
                      <RichText text={error} componentId={error} />
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Messages>
        </div>
      )}

      {!partnerPortalObject?.hideActivateAccountCta && (
        <div className={clsx('create-account__link row col-12 mt-5 mb-2 ', !eaa && 'flex flex-md-row flex-column')}>
          <Text
            customClass={clsx(' pb-1 pb-md-0')}
            copyText={alreadyAMemberText}
            fontSize={Types.size.medium}
            element={Types.tags.paragraph}
          />
          <Link
            text={activateOnlineAccountText}
            linkClassName={clsx('m-link-action link-underline', eaa ? 'my-md-0 my-1 mx-1' : 'mx-md-1')}
            linkHref={
              queryParams && eaa ? `${activateOnlineAccountPath}${queryParams}` || '' : activateOnlineAccountPath
            }
            target="_self"
            linkType="internal"
            custom_click_track_value={CREATE_ACCOUNT_CLICK_VAL}
          />
        </div>
      )}

      {eaa ? renderEAAForm() : renderForm()}
      {!eaa && (
        <div className="row col-12 mb-2">
          <CheckBox
            onChange={handleCheckboxChange}
            checked={remembermeCheckBox}
            checkboxName="remember_me"
            children={
              <RichText
                customClass="t-font-m paragraph-margin px-2"
                text={rememberMeLabel}
                componentId={`checkbox-${rememberMeLabel}`}
              />
            }
            checkboxId={inputId}
            className="create_account__checkbox"
          />
        </div>
      )}
    </StyledCreateAccountForm>
  );
};

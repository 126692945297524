/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';

const isDev = process.env['NODE_ENV'] === 'development';
const commonHeaders = {
  'Content-Type': 'application/json',
  dtt: typeof window !== 'undefined' ? (window?.dtt as unknown as boolean) ?? false : false,
};
const instance = axios.create({
  headers: commonHeaders,
  ...(isDev && {
    baseURL: process.env['NEXT_PUBLIC_PROXY_HOST'] || '',
  }),
});

export const axiosWrapper = {
  intercept: (interceptConfigs: Record<string, string | boolean | number>) => {
    if (Object.keys(interceptConfigs)?.length) {
      for (const key in interceptConfigs) {
        instance.defaults.headers.common[key] = interceptConfigs[key] || '';
      }
    }
  },
  get: (url: string, config?: AxiosRequestConfig) => {
    const customHeaders = {
      ...instance.defaults.headers.get,
      ...commonHeaders,
      ...config?.headers,
    };

    return instance.get(url, { ...config, headers: customHeaders });
  },
  post(url: string, data?: any, config?: AxiosRequestConfig) {
    const customHeaders = {
      ...instance.defaults.headers.post,
      ...commonHeaders,
      ...config?.headers,
    };

    return instance.post(url, data, { ...config, headers: customHeaders });
  },
  put: (url: string, data?: any, config?: AxiosRequestConfig) => {
    const customHeaders = {
      ...instance.defaults.headers.put,
      ...commonHeaders,
      ...config?.headers,
    };

    return instance.put(url, data, { ...config, headers: customHeaders });
  },
  delete: (url: string, config?: AxiosRequestConfig) => {
    const customHeaders = {
      ...instance.defaults.headers.delete,
      ...commonHeaders,
      ...config?.headers,
    };

    return instance.delete(url, { ...config, headers: customHeaders });
  },
};

export const constants = {
  ROOM_FEATURES_CODES: ['C2', 'R1', 'V9', 'Y2', 'F2', 'B5'],
  STAY_CODES: ['E1'],
  BATHROOM_CODES: ['E6'],
  HOUSEKEEPING_OPT_IN: 'J9',
  HOUSEKEEPING_CODES: ['J8', 'J9'],
  HOUSEKEEPING_OPT_OUT: 'J8',
  MARRIOTT_BRAND_CODE: 'X2',
  GROUP_CODE_LENGHT: 7,

  // Housekeeping codes
  HK_EXCLUDED_BRAND_LIST: ['ER', 'MV', 'RZ', 'EB', 'WH', 'L3', 'XR', 'JW', 'BG', 'RS', 'SN'],

  // brands that need to redirected to the landing page instead of the reviews page
  HWSLandingPageRedirectionBrands: ['RZ', 'MV', 'DS', 'BG'],

  // Room Preferences Codes
  PREFERENCES_EXCEPTION_CODE: ['S5', 'D7', 'D9', 'N8', 'U2', 'W5', 'N2', 'M2'],
  ROOM_LOCATIONS_CODES: ['H1', 'L2', 'N1'],
  FLOOR_CODES: ['L2', 'H1'],

  ADDITIONAL_RF: 'R4',

  PLUS_PAYMENT_METHOD_CODE_TYPE: '25.PMT',
  PAYMENT_METHOD_CODE_TYPE_DEPOSITE: '1.PMT,4.PMT,8.PMT',
  PAYMENT_METHOD_CODE_TYPE_GUARANTEE: '2.PMT,3.PMT,5.PMT,19.PMT,22.PMT,25.PMT,29.PMT,30.PMT,32.PMT,40.PMT',
  DEFAULT_PMT: '5.PMT',

  HOTEL_ICON_CODES: {
    HOTEL_SERVICES: 'A4',
    HOTEL_HIGHLIGHTS: 'HH',
    PARKING: 'PT',
    HOUSEKEEPING: '10',
  },

  // New Error framework
  EAPI_ERROR_CODES: {
    E_ARRIVAL_FORMAT: 'E_ARRIVAL_FORMAT',
    E_BAD_CONF_NO: 'E_BAD_CONF_NO',
    E_ALREADY_CANCELLED: 'E_ALREADY_CANCELLED',
    E_CHANGED_HOTEL: 'E_CHANGED_HOTEL',
    E_GNR_DATE_FORMAT: 'E_GNR_DATE_FORMAT',
    E_MOD_DISALLOW: 'E_MOD_DISALLOW',
    E_BAD_HSSMMP_ID: 'E_BAD_HSSMMP_ID',
    E_BAD_IATAN_VER: 'E_BAD_IATAN_VER',
    E_TOO_MANY_NIGHTS: 'E_TOO_MANY_NIGHTS',
    E_BAD_IATA: 'E_BAD_IATA',
    E_NO_CERTS_LEFT: 'E_NO_CERTS_LEFT',
    E_MAX_BOOKINGS: 'E_MAX_BOOKINGS',
    E_MAX_BOOK_ARRIVAL: 'E_MAX_BOOK_ARRIVAL',
    E_DOUBLE_CLICK: 'E_DOUBLE_CLICK',
    E_REPEATED_HOTEL: 'E_REPEATED_HOTEL',
    E_FRAUD_ALERT: 'E_FRAUD_ALERT',
    profileNotFoundInMarshaErrorText: 'profileNotFoundInMarshaErrorText',
    travelAgentUnauthorizedErrorText: 'travelAgentUnauthorizedErrorText',
    427: 'invalidTravelDatesErrorText',
    dates: 'invalidTravelDatesErrorText',
    'queries.start-date': 'invalidTravelDatesErrorText',
    389: 'invalidPmtIdErrorText',
    invalidPrefPlusErrorText: 'invalidPrefPlusErrorText',
    240: 'invalidCreditCardErrorText',
    163: 'expiredCardErrorText',
    119: 'invalidMaxGuestErrorText',
    288: 'maglevDomainRejErrorText',
    invalidMaglevTripsErrorText: 'invalidMaglevTripsErrorText',
    invalidMaglevErrorText: 'invalidMaglevErrorText',
    invalidGuestAccErrorText: 'invalidGuestAccErrorText',
    invalidTravelAccErrorText: 'invalidTravelAccErrorText',
    'invalid.cart.id': 'invalid.cart.id',
    AP10077: 'AP10077',
    AP10071: 'AP10071',
    redemptionInsufficientPoints: 'redemptionInsufficientPoints',
    RCM053: 'RCM053',
    ASP053: 'ASP053',
    enrollmentFailure: 'enrollmentFailure',
    duplicateEmail: 'duplicateEmail',
    duplicatePhone: 'duplicatePhone',
    matchKeyError: 'matchKeyError',
    'mrprogram.matchkey.error': 'mrprogram.matchkey.error',
    'conf.res.link.failure.enroll.matchkeyerror.msg': 'conf.res.link.failure.enroll.matchkeyerror.msg',
    bookingError: 'bookingError',
    graphQlError: 'graphQlError',
    redemptionerror: 'redemptionerror',
    isadultonlyerror: 'isadultonlyerror',
    restrictModification: 'restrictModification',
    userErrorCode: 'userErrorCode',
    profileNotFound: 'profileNotFound',
    MAX_GUEST_COUNT_ERROR: 'guestCountError',
    AVAILIBILITY_ERROR: 'availabilityError',
    WARNING: 'warning',
    ERROR: 'ERROR',
    'rooms.guests': 'rooms.guests',
    450: '450',
    365: '365',
    ORD_NOT_FOUND: 'ORD-SYS-9009',
  },
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { toRem } from './utils';

// eslint-disable-next-line import/no-webpack-loader-syntax
import globalColors from '!!scss-extract-loader?{"plugins": ["scss-extract-js"]}!../../../../node_modules/@marriott/global-styles/dist/scss/base/variables/_colors.scss';

export default {
  lightGray: '#b2b2b2',
  gray: globalColors['merch30'],
  borderGray: '#5e5d5b',
  //darkGray: '#444054',
  background: '#f4f4f4',
  'base-10': globalColors['base10'],
  'base-20': globalColors['base20'],
  carrot: globalColors['merch10'],
  'merch-60': globalColors['merch60'],
  'dark-gray-1': globalColors['merch30'],
  'dark-gray-2': globalColors['ambassadorSolid'],
  'dark-gray-3': globalColors['neutral40'],
  'dark-gray-4': '#767676',
  // 'dark-gray-5': '#1e1e1e',
  'dark-gray-6': '#979797',
  'dark-gray-7': '#1d1d1d',
  'dark-gray-8': '#868686',
  //'dark-gray-9': '#cccccc',
  'dark-gray-10': '#878787',
  //'dark-gray-11': '#e4e7e7',
  'dark-gray-12': globalColors['neutral50'],
  'dark-gray-13': globalColors['neutral00'],

  white: globalColors['merch50'],
  'white-smoke': '#F5F5F5',
  // 'light-gray': '#999999',
  'light-gray-1': '#d2d2d2',
  'light-gray-2': globalColors['neutral10'],
  // 'light-gray-2': '#e6e6e6',
  'light-gray-3': globalColors['neutral20'],
  'light-gray-4': globalColors['base10'],
  'light-gray-5': '#fcfcfc',
  'light-gray-6': globalColors['neutral30'],
  'light-gray-7': '#dbdbdb',
  'light-gray-9': '#c0c0c0',
  'light-gray-10': globalColors['neutral40'],

  // 'light-orange': '#b84c16',
  'light-orange-2': '#ff9962',
  // 'light-blue': '#3b5998',
  // 'dark-orange': '#b84c16',
  'merch-green': globalColors['merch20'],
  'accent-20': globalColors['bvyAccent20'],
  black: '#000000',
  Terracotta: '#df8355',
  silver: '#bababa',
  'close-silver': '#c4c4c4',
  gallery: '#eeeeee',
  alot: '#d8d8d8',
  alabaster: '#f8f8f8',
  zeus: '#231d19',
  monza: '#d0021b',
  alto: '#d5d5d5',
  salmon: '#ff8d6b',
  green: '#45785e',

  'carousel-pink': '#fcebed',
  'dove-gray': '#6C6C6C',
  'mine-shaft': '#303030',
  'fiery-Orange': '#b84c16',
  'rose-of-sharon': '#b94600',
  'rose-of-sharon-2': '#da3c4f',
  'outline-blue': '#065AF6',
  'red-damask': '#dc6f50',
  'cerise-red': '#e93d76',
  'black-opacity-2': 'rgba(0, 0, 0, 0.2)',
  'cta-box-shadow': `0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07)`,
  'cta-banner-shadow': `0 ${toRem(1)} ${toRem(15)} 0 rgba(0, 0, 0, 0.12)`,
  'container-box-shadow': `0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.1)`,
  'container-box-shadow-1': `0 ${toRem(4)} ${toRem(14)} 0 rgba(0, 0, 0, 0.1)`,
  'non-interactive-accent-color': `var(--non-interactive-accent-color)`,
  'interactive-accent-color': 'var(--interactive-accent-color)',
  'link-text-color': 'var(--link-text-color)',
  'alert-05': globalColors['alert05'],
  'alert-50': globalColors['alert50'],
  'alert-30': globalColors['alert30'],
};

import dayjs, { MonthNames } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import arraySupport from 'dayjs/plugin/arraySupport';
import badMutable from 'dayjs/plugin/badMutable';
import updateLocale from 'dayjs/plugin/updateLocale';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { constants } from '../constants';
import { logger } from './logger';

dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(isoWeek);
dayjs.extend(arraySupport);
dayjs.extend(badMutable);
dayjs.extend(updateLocale);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const { DATE_FORMAT, MONTH_DATE_FORMAT, DEFAULT_LANG, DAY_DATE_FORMAT, IS_LOCALE_THAI, BUDDHIST_YEAR_VALUE } =
  constants;

let UPDATED_DATE_FORMAT = '';

export const getDayJsDate = (date = new Date()): dayjs.Dayjs => dayjs(date);
export const getDayJsDateByString = (date = ''): dayjs.Dayjs => dayjs(date);

export const getDateByStringAndFormat = (date: string | Date | null, format: string | string[], strict = true): Date =>
  dayjs(date, format, strict).toDate();

// this method is for dynamically import the locale and update dateOrdinal & dateFormat if any
export const loadLocale = (locale = DEFAULT_LANG, ordinal = '', format = '') => {
  if (locale.split(/_|-/)[0].toLowerCase() !== 'zh') {
    locale = locale.split(/_|-/)[0].toLowerCase();
  } else {
    locale = locale.toLowerCase();
  }
  UPDATED_DATE_FORMAT = format;
  try {
    require(`dayjs/locale/${locale}.js`);
  } catch (e) {
    require(`dayjs/locale/${DEFAULT_LANG}.js`);
    locale = DEFAULT_LANG;
  }
  dayjs.locale(locale);
  dayjs.updateLocale(locale, {
    ordinal: (n: number) => `${n}${ordinal || ''}`,
  });
};

export const getDateInFormat = (date = new Date(), format = DATE_FORMAT): string => {
  const locale = document?.documentElement?.lang?.split(/_|-/)[0].toLowerCase();
  if (locale === IS_LOCALE_THAI) {
    return modifyDateStringForThai(dayjs(date).format(UPDATED_DATE_FORMAT || format));
  } else {
    return dayjs(date).format(UPDATED_DATE_FORMAT || format);
  }
};

export const getMonthAndDate = (dateString: string): string => {
  const date = new Date(dateString);

  // Get the month and date
  const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

export const getStringDateInFormat = (date = '', format = DATE_FORMAT): string =>
  dayjs(date).format(UPDATED_DATE_FORMAT || format);

export const getDateInMonthFormat = (date = '', format = MONTH_DATE_FORMAT): string =>
  dayjs(date).format(UPDATED_DATE_FORMAT || format);

export const getDateInMonthFormatUtc = (date = '', format = MONTH_DATE_FORMAT): string =>
  dayjs.utc(date).format(UPDATED_DATE_FORMAT || format);

export const getDateInDayDateFormat = (
  date = '',
  format = DAY_DATE_FORMAT,
  formatFromParamsPrioritized = false
): string =>
  dayjs(date).format(formatFromParamsPrioritized ? format || UPDATED_DATE_FORMAT : UPDATED_DATE_FORMAT || format);

export const isValidDate = (date: string | Date | null, format: string | string[], strict = true): boolean =>
  dayjs(date, format, strict).isValid();

export const getDateByString = (date: string | Date): Date => new Date(date);

export const getDayJsMonths = (): MonthNames => dayjs.months();

export const modifyDateStringForThai = (dateString: string): string => {
  let modifiedDateString = '';
  let i = 0;

  while (i < dateString.length) {
    // Check if the current character is a numeric digit
    if (dateString[i] >= '0' && dateString[i] <= '9') {
      // Found the start of a sequence of numeric digits
      const start = i;
      const end = i + 3;

      // Ensure we have at least 4 characters left in the string
      if (end < dateString.length && dateString[end] >= '0' && dateString[end] <= '9') {
        // Extract the 4 consecutive numeric digits
        const numericDigits = dateString.slice(start, end + 1);

        // Convert the numeric string to a number, add the given value
        const newValue = parseInt(numericDigits) + BUDDHIST_YEAR_VALUE;

        // Append the modified numeric value to the result string
        modifiedDateString += newValue.toString();

        // Move the index past the 4 consecutive numeric digits
        i = end + 1;
      } else {
        // If not enough characters left, just append the current character
        modifiedDateString += dateString[i];
        i++;
      }
    } else {
      // Append non-numeric characters directly to the result string
      modifiedDateString += dateString[i];
      i++;
    }
  }

  return modifiedDateString;
};

export const convertArabicToEnglishNumbers = (dateString: string): string => {
  // Arabic to English numeral mapping
  const arabicToEnglishMap: { [index: string]: string } = {
    '٠': '0',
    '١': '1',
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9',
  };

  // Replace Arabic numerals with English numerals
  return dateString.replace(/[٠-٩]/g, function (match) {
    return arabicToEnglishMap[match].toString();
  });
};

export const getDifferenceInDays = (dateStringA: string, dateStringB: string) => {
  try {
    // Convert string to dayjs object
    const targetDateA = dayjs(dateStringA);

    // Get today's date
    const targetDateB = dayjs(dateStringB);

    // Calculate the difference in days
    const differenceInDays = targetDateA.diff(targetDateB, 'day');
    return differenceInDays;
  } catch (e) {
    logger('Logger Instance')('Date Function').log.log(e);
    return 0;
  }
};

export default dayjs;

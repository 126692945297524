// Styles for MissingStayRequestForm go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledMissingStayRequestForm = styled.div`
  .blur {
    opacity: 0.5;
  }

  .form {
    border-radius: ${toRem(14)};

    .form__line {
      border-bottom: ${toRem(1)} solid ${baseVariables.color['neutral20']};
      padding: ${toRem(12)} 0;
      &--first,
      &--last {
        border-bottom: ${toRem(1)} solid ${baseVariables.color['neutral30']};
      }
      &.blur {
        opacity: 1;
      }
    }

    &__footer {
      &--submit {
        width: 100%;
        @media ${baseVariables.mediaQuery.md} {
          width: auto;
        }
      }
    }
  }
  .validation-msg {
    color: ${baseVariables.color['alert50']} !important;
  }
`;

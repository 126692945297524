// Styles for MemberSnapshotRibbon go here.
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';
export const StyledMemberSnapshotRibbon = styled.div`
  .card {
    &__body {
      height: 100%;
      background: transparent;
      box-sizing: border-box;
      box-shadow: none;
      border-radius: 0;
    }

    .recent-activity {
      background-color: transparent;

      &__textcardbody {
        box-shadow: 0 ${toRem(4)} ${toRem(16)} ${baseVariables.shadows.boxShadowTile};
        background: ${baseVariables.color.base20};
        box-sizing: border-box;
        border-radius: ${toRem(14)};
        padding: ${toRem(64)} ${toRem(24)} ${toRem(104)} ${toRem(24)};
        @media ${baseVariables.mediaQuery.sm} {
          box-shadow: none;
          top: 30%;
          width: 95%;
          position: absolute !important;
        }
      }

      &__icon {
        font-size: ${toRem(72)};
      }
    }
    .border-line {
      display: none;
      @media ${baseVariables.mediaQuery.sm} {
        display: flex;
        border-bottom: ${toRem(1)} solid ${baseVariables.color['neutral10']};
      }
    }
    &__placeholder__skeleton {
      &__label {
        width: ${toRem(120)};
        height: ${toRem(16)};
        @media ${baseVariables.mediaQuery.lg} {
          width: ${toRem(240)};
          height: ${toRem(22)};
        }
      }
      &__cta {
        width: ${toRem(120)};
        height: ${toRem(16)};
        @media ${baseVariables.mediaQuery.lg} {
          width: ${toRem(240)};
          height: ${toRem(22)};
        }
      }
      &__img {
        width: 100%;
        height: ${toRem(200)};
      }
    }
  }
  .icon-member-rates1x {
    font-size: ${toRem(40)};
  }
  .icon-internet {
    font-size: ${toRem(40)};
  }
  .icon-avatar {
    font-size: ${toRem(40)};
  }
  .new-member {
    div {
      @media ${baseVariables.mediaQuery.lg} {
        max-width: 458px;
      }
    }
  }
  .new-member-card {
    box-shadow: 0 ${toRem(4)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
    border-radius: ${toRem(14)};
    background: ${baseVariables.color.base20};
    margin-top: 0 !important;

    @media ${baseVariables.mediaQuery.sm} {
      margin-top: ${toRem(24)} !important;
    }
  }

  .recent-activity-card {
    @media ${baseVariables.mediaQuery.sm} {
      box-shadow: 0 ${toRem(4)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
      border-radius: ${toRem(14)};
      background: ${baseVariables.color.base20};
    }
  }
  .modal-main-container {
    &__hrLine {
      box-sizing: border-box;
      height: ${toRem(1)};
      border: ${toRem(1)} solid ${baseVariables.color['neutral30']};
      margin: 0;
    }
  }
  .skeleton__body {
    height: ${toRem(480)};
    box-shadow: 0 ${toRem(4)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
    border-radius: ${toRem(14)};
    background: ${baseVariables.color.base20};
    @media ${baseVariables.mediaQuery.md} {
      height: inherit;
      box-shadow: unset;
    }
  }
  .card-skeleton {
    @media ${baseVariables.mediaQuery.md} {
      box-shadow: 0 ${toRem(4)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
      border-radius: ${toRem(14)};
      box-sizing: border-box;
    }
  }
`;

export const StyledMemberStatusCardLoader = styled.div`
  .member_status_card {
    &__skeleton {
      background-color: ${baseVariables.color['alert10']} !important;
      border-radius: ${toRem(14)};
      box-sizing: border-box;
      box-shadow: none;
      @media ${baseVariables.mediaQuery.md} {
        box-shadow: 0 ${toRem(4)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
      }
      &__section {
        width: 85%;
        &__label1 {
          width: 35%;
          height: ${toRem(24)};
        }
        &__label2 {
          width: 65%;
          height: ${toRem(24)};
        }
        &__label3 {
          width: 70%;
          height: ${toRem(52)};
        }
        &__progress {
          width: 100%;
          height: ${toRem(10)};
          border-radius: ${toRem(32)};
        }
        &__btn {
          width: ${toRem(144)};
          height: ${toRem(34)};
        }
      }
      &__img {
        width: ${toRem(200)};
        height: ${toRem(160)};
        border-radius: ${toRem(14)};
      }
    }
  }
`;

import React, { FC, useEffect, useState, useContext, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import axios from 'axios';
import clsx from 'clsx';
import moment from 'moment';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Button, Link, Types, RichText, Text } from '@marriott/mi-ui-library';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { FAILURE_CODE, handleRedirect, PageContext, encryptPassword, clearSessionAndCookie } from '../../modules';
import {
  CONSENT_TYPE_ONE_COUNTRY_SET,
  UNIQUE_CONSENT_TYPE_COUNTRY_SET,
  constants,
  ENROLL_ENDPOINT,
  PROFILE_REDIRECTION_URL,
  PROMOTIONS_CONSTANT,
  RFP_URL,
  ENROLLMENT_CONSTANTS,
  ONE_CLICK_JOIN_ERROR,
  BANNER_MSGS_KEYS,
  SWEEPSTAKE_ENROLLMENT_CLICK_TRACK,
  API_ERROR_KEY,
  ENROLL_ENDPOINT_DTT,
  TRACKING_CONSTANTS,
  MARRIOTT_URL,
  MARRIOTT_URL_CHINA,
} from '../../modules/utils/constants/constants';
import {
  addSubDirectoryPrefix,
  addSubDirectoryPrefixForNonEaa,
  checkChinaLocale,
  getCurrentUrlParams,
  getEnrollmentSourceCodeFromCountry,
  getEnrollmentPreferredLanguageCode,
  skipDecode,
  addClassToBlankTargetLinks,
} from '../../modules/utils/helper';
import { DATALAYER_PAGE_EVENT, updateDataLayerProperties } from '../../modules/utils/accountDataLayer';
import { DefaultConsentForm } from '../../molecules/DefaultConsentForm';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import { useCreateAccountStore } from '../../modules/store/createAccountStore';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import { CreateAccountConsentTypes, createAccountReqBody, CustomError } from './CreateAccountConsentsForm.types';
import { StyledCreateAccountConsentsForm } from './CreateAccountConsentsForm.styles';

const VistanaVacationPolicy = dynamic(() =>
  import('../../molecules/VistanaVacationPolicy').then(mod => mod.VistanaVacationPolicy)
);

// Use named rather than default exports.
export const CreateAccountConsentsForm: FC<CreateAccountConsentTypes> = pageProps => {
  const { model, isDTT } = pageProps;
  const router = useRouter();
  const { query, pathname } = router;
  const promotionId = query ? (query['promotion'] as string) : '';
  const clientId = query ? query['clientId'] : '';
  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;

  const {
    formData,
    joinNowEnabled,
    consents,
    formValidation,
    setErrorKey,
    remembermeCheckBox,
    transactionDetails,
    tripId,
    startDate,
    validSweepEnrollObject,
    goBackLabel,
    goBackUrl,
    setGoBackUrl,
    isValidPartnerPortal,
    isEnrollEAA,
    prefillObj,
    setResetTimer,
  } = useCreateAccountStore();
  const { sweepEnrollmentSourceCode, sweepRedirectUrl } = validSweepEnrollObject;
  const { joinNowBtnClicked, setJoinNowBtnClicked } = useCreateAccountStore();
  const { setBannerMsgs } = useBannerMessagesStore();
  const [url, setUrl] = useState<string | null>(null);
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const countryCode = formData?.country?.id;
  const pageContext = useContext(PageContext);
  const localeCountryCode = pageContext?.currentLocale;
  const isEAA = pageContext?.isEAASignIn;
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const publicKey = pageContext?.rsaPublicKey;
  const isChinaLocale = checkChinaLocale(pageContext?.currentLocale || '');
  const isJoinPromotion = PROMOTIONS_CONSTANT.JOIN_PROMOTION_URL.includes(pathname);
  const {
    ACCEPT_LANGUAGE: currentLocale,
    IS_PASSWORD_ENCRYPT: enableEncryption,
    SKIP_DECODE_URL_FOR_PATH: skipDecodeForPaths,
    TRACKING_PAGE_NAME: pageName,
  } = clientEnvVars;
  const { PROMOTION_ENROLLMENT, SWEEPSTAKES } = TRACKING_CONSTANTS;
  const trackingPageName = promotionId ? PROMOTION_ENROLLMENT : clientId ? SWEEPSTAKES : pageName || '';
  const { setMemberDetailsUxl, setPromoRegistered, setPromoInvalid, setIsEAAEnrollFailed } = useAccountPersistentStore(
    state => state
  );

  const handleClick = () => {
    setJoinNowBtnClicked(true);
  };
  // API call will be made only after the "joinNow" button is clicked and form validation is successful.
  useEffect(() => {
    if (!joinNowBtnClicked) {
      handleSubmit();
    }
  }, [joinNowBtnClicked]);

  const handleSubmit = async () => {
    if (!formValidation) return;
    const urlParams = new URLSearchParams(getCurrentUrlParams());

    // check if enrollmentSourceCode is present in queryParam
    const enrollmentSourceCodeParam = urlParams.get('enrollmentSourceCode');

    // first preference is given to eaa Prefill enrollment source code
    // then check if enrollemnentSourceCode is present in param or promotion id is present or else get enrollment source code from enrollement spurce code list else assgin default value i.e. MRMB
    let enrollmentSourceCodeLocale = prefillObj?.enrollmentSourceCode
      ? prefillObj?.enrollmentSourceCode
      : enrollmentSourceCodeParam ??
        sweepEnrollmentSourceCode ??
        (getEnrollmentSourceCodeFromCountry(currentLocale, model?.profileEnrollmentSourceCodeList) ||
          ENROLLMENT_CONSTANTS?.MRMB);

    // check if preferredLanguage is present in enrollment preferred language code list else assgin default value i.e. blank space
    const preferredLanguageCode = getEnrollmentPreferredLanguageCode(
      localeCountryCode,
      model?.enrollmentPreferredLanguageCodesList
    );

    if (isJoinPromotion && promotionId && !enrollmentSourceCodeParam) {
      enrollmentSourceCodeLocale = promotionId;
    }
    const redirectUrl = sweepRedirectUrl
      ? sweepRedirectUrl
      : isJoinPromotion
      ? PROMOTIONS_CONSTANT?.JOIN_CONFIRMATION_PROMOTION_URL.replace('{0}', promotionId)
      : urlParams.get('isRfpFlow')
      ? RFP_URL
      : urlParams.get('returnTo') || (urlParams.get('returnToUrl') ?? PROFILE_REDIRECTION_URL);
    const reqBody: createAccountReqBody = {
      userDetails: {
        firstName: isChinaLocale ? formData?.pinyinEnglishName : formData?.firstName,
        lastName: isChinaLocale ? formData?.pinyinEnglishSurname : formData?.lastName,
        countryCode: formData?.country?.id,
        postalCode: formData?.zipcode?.trim(),
        emailAddress: formData?.email,
        transactionId: transactionDetails,
        phoneNumber: formData?.mobilePhone,
        phoneNumberCountryCode: formData?.mobilePhone ? formData?.mobilePhoneCountryId?.id : '',
        phoneNumberType: formData?.mobilePhone ? constants.MOBILE_PHOHE_CODE : '',
        otp: formData?.verificationCode,
        pwrd: encryptPassword(formData.pwrd, publicKey, enableEncryption),
        confirmPwrd: encryptPassword(formData.confirmPwrd, publicKey, enableEncryption),
        rememberMe: isEAA ? false : remembermeCheckBox,
        localizeFirstName: isChinaLocale ? formData?.firstName : '',
        localizeLastName: isChinaLocale ? formData?.lastName : '',
        ...(preferredLanguageCode && { preferredLanguage: preferredLanguageCode }),
      },
      userConsents: consents
        .filter(item => item.ctype === 'userConsents' && item.optin === true)
        .map(item => ({ code: item.type, optIn: item.optin })),
      communicationPreferences: consents
        .filter(item => {
          if (countryCode === constants.USA_COUNTRY_CODE) {
            return item.ctype === 'communicationPreferences' && item.optin === true;
          } else {
            return item.ctype === 'communicationPreferences';
          }
        })
        .map(item => ({ type: item.type, optIn: item.optin })),
      ...(isJoinPromotion && {
        promotionIds: [promotionId ? promotionId : ''],
      }) /**join now id is getting undefined 93179 */,
      returnUrl: isEnrollEAA ? prefillObj?.partnerUrl || '' : redirectUrl,
      tripId: tripId ?? '',
      startDate: startDate ?? '',
      chinaFlow: pageContext?.isMobileAuthFeatureEnabled || isChinaLocale,
      ...(enrollmentSourceCodeLocale && {
        enrollmentSource: enrollmentSourceCodeLocale,
      }),
      pageModuleName: trackingPageName,
      ...(isEnrollEAA && {
        clientId: urlParams.get('client_id') || '',
        queryParams: prefillObj?.queryParams,
      }),
      partnerExperience: prefillObj?.partnerExperience,
    };
    setSubmitInProgress(true);
    const enrollUrl = isDTT || isEnrollEAA ? ENROLL_ENDPOINT_DTT : ENROLL_ENDPOINT;
    try {
      const response = await axios.post(addSubDirectoryPrefixForNonEaa(enrollUrl, isEnrollEAA), reqBody, {
        headers: {
          'Content-Type': 'application/json',
          Cookie: 'sessionID=' + sessionData?.sessionToken,
          isEAA: isEAA ?? '',
        },
        ...(!!sweepEnrollmentSourceCode && {
          params: {
            clientId: clientId,
          },
        }),
      });
      if (response) {
        if (response?.data?.customerDetails) {
          clearSessionAndCookie();
          setMemberDetailsUxl?.(response?.data?.customerDetails);
        }
        if (response?.data?.status?.toUpperCase() === constants.STATUS_SUCCESS) {
          sessionStorage?.setItem(constants.MR_ENROLLED, 'true');
          const { nextStateURI } = response.data;
          if (isJoinPromotion && nextStateURI) {
            if (nextStateURI.includes(PROMOTIONS_CONSTANT.PROMOTION_UNAVAILABLE_URL)) {
              setPromoInvalid?.(true);
            } else {
              setBannerMsgs(BANNER_MSGS_KEYS.NEW_MEMBER_NUMBER_MSG);
              setPromoRegistered?.(promotionId);
            }
          }
          const returnUrl = nextStateURI || (!isEAA ? addSubDirectoryPrefix(PROFILE_REDIRECTION_URL) : '');
          handleRedirect(returnUrl, false, !skipDecode(returnUrl, skipDecodeForPaths));
        } else if (
          response.request?.responseURL &&
          !response.request?.responseURL.includes(isDTT || isEnrollEAA ? ENROLL_ENDPOINT_DTT : ENROLL_ENDPOINT)
        ) {
          window.location.href = response.request?.responseURL;
        }
        setSubmitInProgress(false);
      }
    } catch (error) {
      if (error) {
        const errorBody = (error as CustomError)?.response?.data?.phoenixErrorMessages?.errorMessages;
        let errorMessagesArray: string[] = [];
        if (
          (error as CustomError)?.response?.data?.isRedirect &&
          !(errorBody?.[0] === ONE_CLICK_JOIN_ERROR?.EMAIL_DUPLICATE_ERROR)
        ) {
          // for EAA enroll
          // used to show auto sign failed error banner in login page,
          // here user may get redirected to error page, there we are just clearing the state
          isEnrollEAA && setIsEAAEnrollFailed?.(true);

          handleRedirect((error as CustomError)?.response?.data?.nextStateURI, false);
        } else {
          if (errorBody?.length) {
            errorMessagesArray = [...errorBody];
          } else {
            errorMessagesArray = [API_ERROR_KEY];
          }
          updateDatalayerOnFailure((error as CustomError)?.response?.headers?.['logineventdetails'] || '');
        }
        setErrorKey(errorMessagesArray);
      }
      setSubmitInProgress(false);
      setResetTimer(true);
    }
  };

  const updateDatalayerOnFailure = (loginEventDetails: string) => {
    const dataLayerProps: { [key: string]: string } = {};
    if (loginEventDetails) {
      dataLayerProps['loginEventDetails'] = loginEventDetails;
    }
    if (sweepEnrollmentSourceCode) {
      dataLayerProps['sweepStakeEnrollment'] = FAILURE_CODE;
      dataLayerProps['partner_name'] = typeof clientId === 'string' ? clientId : clientId?.[0] || '';
    }
    if (loginEventDetails || !!sweepEnrollmentSourceCode) {
      updateDataLayerProperties(dataLayerProps, DATALAYER_PAGE_EVENT.LOGIN_FAILURE);
    }
  };

  useEffect(() => {
    const clickableModalLinks = document.querySelectorAll('a[target="_parent"]');

    const handleLinkClick = (event: MouseEvent) => {
      event.preventDefault();
      const linkHref = (event.currentTarget as HTMLAnchorElement)?.getAttribute('href');
      if (linkHref) {
        setUrl(linkHref);
        setIsOpen(true);
      }
    };

    clickableModalLinks.forEach(link => {
      link?.addEventListener('click', handleLinkClick as EventListener);
    });
  }, [countryCode]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Prevent background scrolling
    } else {
      document.body.style.overflow = 'auto'; // Restore background scrolling
    }
  }, [isOpen]);

  useEffect(() => {
    const urlParams = new URLSearchParams(getCurrentUrlParams());
    const updatedUrl = decodeURIComponent(urlParams.get('returnToUrl') ?? '');

    setGoBackUrl(updatedUrl ?? '');
  }, []);

  const defaultConsentText = useMemo(() => {
    const modifiedUrl = model?.defaultConsent || '';
    return isEnrollEAA
      ? addClassToBlankTargetLinks(modifiedUrl, '_parent', isChinaLocale ? MARRIOTT_URL_CHINA : MARRIOTT_URL)
      : addSubDirectoryPrefix(modifiedUrl);
  }, [isEnrollEAA]);

  const renderConsentForm = () => {
    if (!countryCode) return null;
    const selectedModel = model?.countryCodesList?.find(entry =>
      UNIQUE_CONSENT_TYPE_COUNTRY_SET?.includes(countryCode)
        ? entry?.countryCode === countryCode
        : CONSENT_TYPE_ONE_COUNTRY_SET?.includes(countryCode)
        ? entry?.countryCode === 'defaultone'
        : entry?.countryCode === 'defaulttwo'
    );
    return (
      <div>
        <DefaultConsentForm
          model={selectedModel}
          countryCode={countryCode}
          errorMessagePersonal={model?.errorMessagePersonal}
          errorMessagePromotions={model?.errorMessagePromotions}
          isEnrollEAA={isEnrollEAA}
        />
        <div className="px-0 mx-auto">
          {!UNIQUE_CONSENT_TYPE_COUNTRY_SET.filter(countryCode => countryCode !== 'US').includes(countryCode) && (
            <RichText
              text={defaultConsentText}
              componentId="default-consent"
              customClass="default-consent__description t-font-s"
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <StyledCreateAccountConsentsForm
      className="px-3 px-sm-0"
      data-testid="createaccountconsentform"
      data-component-name="o-account-createaccountconsentform"
    >
      <div
        className={clsx(
          'create-account-consents-form-container mb-4',
          countryCode &&
            countryCode !== 'US' &&
            (UNIQUE_CONSENT_TYPE_COUNTRY_SET.includes(countryCode) || countryCode === constants.JAPAN_COUNTRY_CODE)
            ? 'spacing'
            : ''
        )}
      >
        <div
          className={clsx(
            'create-account-consents-form-wrapper container',
            !isEnrollEAA && 'create-account-consents-form-wrapper__border'
          )}
        >
          {isOpen && <VistanaVacationPolicy handleClose={() => setIsOpen(false)} modalURL={url || ''} />}
          {renderConsentForm()}
          <div className="mx-auto d-flex align-items-center flex-column flex-md-row pb-5 pt-3 px-0 space-bottom submit_btn-wrapper">
            <Button
              className={clsx(
                `m-button-m m-button-primary custom-btn submit_btn px-4 py-2 ${
                  (!joinNowEnabled || submitInProgress) && 'disabled'
                } create-account-submit-mobile`
              )}
              type={Types.ButtonTypeVariation.Submit}
              buttonCopy={isEnrollEAA ? model?.joinNowLabel : model?.joinTodayLabel}
              callback={handleClick}
              testId={'joinCta'}
              isDisabled={!joinNowEnabled || submitInProgress}
              custom_click_track_value={sweepEnrollmentSourceCode ? SWEEPSTAKE_ENROLLMENT_CLICK_TRACK : ''}
            ></Button>
            {!isEnrollEAA && (
              <Link
                text={isValidPartnerPortal ? goBackLabel : model?.cancelLabel}
                linkClassName="t-font-s mt-4 mt-md-0 mx-md-5 link-underline"
                linkHref={isValidPartnerPortal ? goBackUrl : model?.cancelCtaPath || ''}
                target="_self"
                linkType="internal"
              />
            )}
          </div>
          {isEnrollEAA && (
            <div className="mx-auto d-flex align-items-center flex-column flex-md-row space-bottom copyright-wrapper">
              <Text
                customClass="t-font-xs"
                copyText={model?.copyrightStatement?.replace('{0}', String(moment().year()))}
                fontSize={Types.size.extraSmall}
                element={Types.tags.paragraph}
              />
            </div>
          )}
        </div>
      </div>
    </StyledCreateAccountConsentsForm>
  );
};

export const CreateAccountConsentsFormConfig = {
  emptyLabel: 'createaccountconsentform',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/createaccountconsentform`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CreateAccountConsentsFormEditable = (props: any) => {
  return props?.cqPath?.includes('datapagecomponent') ? (
    <CreateAccountConsentsForm {...props} />
  ) : (
    <EditableComponent config={CreateAccountConsentsFormConfig} {...props}>
      <CreateAccountConsentsForm {...props} />
    </EditableComponent>
  );
};

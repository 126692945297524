/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Imports for external libraries go here.
import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';
import dynamic from 'next/dynamic';
import axios from 'axios';
import { inspect } from 'util';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { PageContext } from '../../modules/context/PageContext';
import {
  apiLogger,
  generateApolloClientHeaders,
  addSubDirectoryPrefix,
  getCountryAndLangCode,
  shouldSwapName,
  nameFieldRegex,
  scrollIntoViewClass,
  checkChinaLocale,
  isChinese,
} from '../../modules/utils';
import { StyledMissingStayRequestForm } from './MissingStayRequestForm.styles';
import { PrintableMissingStayRequestForm } from './PrintableMissingStayRequestForm';
import { StepOne } from './StepOne/ReportMissingStayStepOne';
import { StepTwo } from './StepTwo/ReportMissingStayStepTwo';
import { StepThree } from './StepThree/ReportMissingStayStepThree';
import { phoenixAccountGetMemberStatusDetails } from '../../modules/graph';
import {
  nextMissingStayFormSubmitURL,
  nextMissingStayFormConfirmationURL,
  constants,
} from '../../modules/utils/constants/constants';
import { MissingStayRequestErrorStates } from './MissingStayRequestForm.types';

// Use named rather than default exports.
export const MissingStayRequestForm = (pageProps: any) => {
  const { IS_LOCAL_DEV } = process.env;
  const authorModel = pageProps?.model;
  const isAuthorMode = pageProps?.isAuthorMode;
  const pageContext = useContext(PageContext);
  const currentLocale = pageContext?.currentLocale ?? 'en-us';
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const localeBasedNameSwapConfig = pageContext?.localeBasedNameSwapConfig;
  const [reverseNamefield, setReverseNamefield] = useState(false);
  const { countryCode } = getCountryAndLangCode(currentLocale);
  const localeCountryCode = countryCode || constants.USA_COUNTRY_CODE;
  const [formSubmitTriggered, setFormSubmitTriggered] = useState(false);
  const [isPrint, setIsPrint] = useState<boolean | undefined>();
  const [formFields, setFormFields] = useState({
    //Step1
    firstName: sessionData?.firstName,
    lastName: sessionData?.lastName,
    emailAddress: '',
    phoneNumber: '',
    rewardsNumber: sessionData?.rewardsId,
    thirdPartyBooking: null,
    folioStayAndPayApplicable: false,
    folioStayAndPay: '',
    //Step2
    checkInDate: '',
    checkOutDate: '',
    confirmationNumber: '',
    propertyName: '',
    propertyCode: '',
    noBillSelection: false,
    digitalBillSelection: false,
    paperBillSelection: false,
    //Step3
    scanHotelCountry: '',
    scanHotelState: '',
    scanHotelCity: '',
    faxOrMailSelection: false,
    scannedCopySelection: false,
    documentIds: null,
    comments: '',
    //Step3 end
    labels: {
      subjectLabel: authorModel?.subject,
      rewardsNumberLabel: authorModel?.number,
      commentsLabel: authorModel?.commentsTitle,
      confirmationNumberLabel: authorModel?.confirmationNumberLabel,
      firstNameLabel: authorModel?.firstNameFieldLabel,
      lastNameLabel: authorModel?.lastNameFieldLabel,
      phoneNumberLabel: authorModel?.phoneNumberFieldLabel,
      emailAddressLabel: authorModel?.emailAddressFieldLabel,
      checkInDateLabel: authorModel?.checkInDateLabel,
      checkOutDateLabel: authorModel?.checkOutDateLabel,
      hotelNameLabel: authorModel?.hotelNameLabel,
      propertyCodeLabel: authorModel?.code,
    },
  });
  const defaultErrorStates = {
    firstName: '',
    firstNameRegExp: '',
    firstNameBannerError: '',
    lastName: '',
    lastNameRegExp: '',
    lastNameBannerError: '',
    phoneNumber: '',
    propertyName: '',
    checkOutEnteredDate: '',
    checkInEnteredDate: '',
    email: '',
    thirdPartyBooking: '',
    scanHotelCountry: '',
    scanHotelCity: '',
    emailSent: true,
    folioStayAndPay: '',
    noBill: '',
    attachment: '',
    comments: '',
    confirmationNumber: '',
  };
  const [errorStates, setErrorStates] = useState<MissingStayRequestErrorStates>(defaultErrorStates);
  const dataLoaded = useRef<boolean>(false);
  const [_classContainer, setClassContainer] = useState('');
  const [isUxlFailure, setIsUxlFailure] = useState(false);

  const classAdder = (ele: HTMLElement) => {
    if (isPrint) {
      ele.style.setProperty('display', 'none', 'important');
    } else {
      ele.style.display = 'block';
    }
  };
  //check if mandatory fields are empty
  const isMandatoryFieldEmpty = () => {
    // for paper bill hotel city field is required for both of the step 3 options
    if (formFields?.paperBillSelection) {
      return !(
        formFields?.phoneNumber?.trim() &&
        formFields?.checkInDate?.trim() &&
        formFields?.checkOutDate?.trim() &&
        formFields?.propertyName?.trim() &&
        formFields?.propertyCode?.trim() &&
        formFields?.scanHotelCity?.trim()
      );
    } else if (formFields?.digitalBillSelection) {
      // for digital copy hotel city field is not required
      return !(
        formFields?.phoneNumber?.trim() &&
        formFields?.checkInDate?.trim() &&
        formFields?.checkOutDate?.trim() &&
        formFields?.propertyName?.trim() &&
        formFields?.propertyCode?.trim()
      );
    } else {
      // for no bill selection no validation is required as button will always be disabled
      return false;
    }
  };

  useEffect(() => {
    if (isPrint) {
      setClassContainer('d-none');
    } else {
      setClassContainer('');
    }
    const headerDiv = document.querySelector('.header') as HTMLDivElement;
    if (headerDiv) {
      classAdder(headerDiv);
    }

    const memberDiv = document.getElementById('memberStatusContainer') as HTMLDivElement;
    if (memberDiv) {
      classAdder(memberDiv);
    }

    const accountNavBarDiv = document.getElementById('accountNavbarContainer') as HTMLDivElement;
    if (accountNavBarDiv) {
      classAdder(accountNavBarDiv);
    }

    const missingStayRequestDiv = document.getElementById('missingFormContainer') as HTMLDivElement;
    if (missingStayRequestDiv) {
      classAdder(missingStayRequestDiv);
    }

    const extendedFooterDiv = document.getElementById('extendedFooterContainer') as HTMLDivElement;
    if (extendedFooterDiv) {
      classAdder(extendedFooterDiv);
    }

    //TO DO: need to update the classname.
    const globalFooterDiv = document.querySelector('.section') as HTMLDivElement;
    if (globalFooterDiv) {
      classAdder(globalFooterDiv);
    }
    if (isPrint) {
      window.print();
    }
  }, [isPrint]);

  useEffect(() => {
    const localeSwapCheck = shouldSwapName(localeBasedNameSwapConfig, localeCountryCode);
    if (localeSwapCheck) {
      setReverseNamefield(true);
    } else {
      setReverseNamefield(false);
    }
  }, []);

  const textFieldValidation = (value: string) => {
    return checkChinaLocale(currentLocale || '')
      ? !nameFieldRegex.test(value) && !isChinese(value)
      : !nameFieldRegex.test(value);
  };

  const checkErrorOnSubmit = () => {
    const errorList: MissingStayRequestErrorStates = {};

    if (formFields?.firstName) {
      if (textFieldValidation(formFields?.firstName?.trim())) {
        errorList.firstNameBannerError = authorModel?.firstNameBannerError;
      }
    }

    if (formFields?.lastName) {
      if (textFieldValidation(formFields?.lastName?.trim())) {
        errorList.lastNameBannerError = authorModel?.lastNameBannerError;
      }
    }

    setErrorStates({ ...errorList });
    const isValid = Object.values(errorList).length === 0;
    if (!isValid) {
      scrollIntoViewClass('form__formheader');
    }
    return isValid;
  };
  const trimFormFields = () => {
    return {
      ...formFields,
      firstName: formFields?.firstName?.trim(),
      lastName: formFields?.lastName?.trim(),
    };
  };
  const skipQuery =
    useMemo(() => {
      return !pageContext?.sessionData && !isAuthorMode;
    }, [pageContext, isAuthorMode]) ||
    dataLoaded.current ||
    isAuthorMode;

  useQuery(phoenixAccountGetMemberStatusDetails, {
    variables: {
      customerId: sessionData?.consumerID,
    },
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
    skip: skipQuery,
    onCompleted: (data: any) => {
      dataLoaded.current = true;
      setFormFields({
        ...formFields,
        emailAddress: data?.customer?.contactInformation?.emails[0]?.address ?? '',
        phoneNumber: data?.customer?.contactInformation?.phones[0]?.number ?? '',
      });
      apiLogger(
        `[MissingStayRequestForm] getMemberStatusDetails - sessionId: ${sessionData?.sessionToken}: ${inspect(data)}`
      );
    },
    onError: error => {
      apiLogger(
        `[MissingStayRequestForm] getMemberStatusDetails - sessionId: ${sessionData?.sessionToken} - error: ${inspect(
          error
        )}`
      );
    },
  });

  const formSubmit = async () => {
    setIsUxlFailure(false);
    setFormSubmitTriggered(true);
    if (checkErrorOnSubmit()) {
      const trimmedFormFields = trimFormFields();
      try {
        const response = await axios.post(
          nextMissingStayFormSubmitURL,
          { missingStayForm: trimmedFormFields },
          {
            headers: {
              withCredentials: true,
              'Content-Type': 'application/json',
              Cookie: 'sessionID=' + sessionData?.sessionToken,
            },
          }
        );
        if (response.status === 200) {
          window.location.replace(addSubDirectoryPrefix(nextMissingStayFormConfirmationURL));
        }
        setFormSubmitTriggered(false);
        return response;
      } catch (error: any) {
        if (error?.response?.data?.errorFields) {
          const tempErrorState: any = defaultErrorStates;
          Object.keys(error?.response?.data?.errorFields)?.map(key => {
            tempErrorState[key] = true;
          });
          setErrorStates({ ...tempErrorState, emailSent: false });
          setIsUxlFailure(false);
        } else {
          setIsUxlFailure(true);
        }
        scrollIntoViewClass('form__formheader');
        setFormSubmitTriggered(false);
        return error;
      }
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isPrint && (
        <PrintableMissingStayRequestForm
          isOpen={isPrint}
          onClose={() => setIsPrint(false)}
          labels={authorModel}
          values={formFields}
        />
      )}
      <StyledMissingStayRequestForm
        data-testid="missingstayrequestform"
        data-component-name="o-account-missingstayrequestform"
      >
        <div className="container" id="missingFormContainer">
          <div className="form color-scheme7 p-0">
            <h1 className="form__formheader t-subtitle-xl px-4 px-md-5 py-4 mb-0" data-testid="top-header">
              {authorModel?.formHeader}
            </h1>
            <div className="form__line--first"></div>
            <StepOne
              authorModelData={authorModel}
              formFields={formFields}
              setFormFields={setFormFields}
              errorStates={errorStates}
              setErrorStates={setErrorStates}
              fieldNameReverse={reverseNamefield}
              isUxlFailure={isUxlFailure}
              setIsUxlFailure={setIsUxlFailure}
            />

            <div
              className={clsx(
                'form__line',
                'mt-5',
                'mb-5',
                'mx-4',
                'mx-md-5',
                formFields?.thirdPartyBooking !== false ? 'blur' : ''
              )}
            ></div>
            <StepTwo
              authorModelData={authorModel}
              formFields={formFields}
              setFormFields={setFormFields}
              errorStates={errorStates}
            />

            <div
              className={clsx(
                'form__line',
                'mt-5',
                'mb-4',
                'mx-4',
                'mx-md-5',
                formFields?.thirdPartyBooking !== false ? 'blur' : ''
              )}
            ></div>
            <StepThree
              authorModelData={authorModel}
              formFields={formFields}
              setFormFields={setFormFields}
              errorStates={errorStates}
              setErrorStates={setErrorStates}
              scanHotelCity={formFields.scanHotelCity}
              scanHotelState={formFields.scanHotelState}
              scanHotelCountry={formFields.scanHotelCountry}
              currentLocale={currentLocale}
            />
            <div
              className={clsx(
                'form__line--last',
                'mt-5',
                'mb-5',
                formFields?.thirdPartyBooking !== false || formFields?.noBillSelection ? 'blur' : ''
              )}
            ></div>

            <div className="form__footer d-flex flex-row-reverse justify-content-center justify-content-lg-start px-5 pb-4">
              {!formFields?.faxOrMailSelection && (
                <button
                  className={clsx(
                    'form__footer--submit',
                    'btn',
                    'm-button-m',
                    'm-button-primary',
                    'px-5',
                    formFields?.thirdPartyBooking !== false ||
                      formFields?.noBillSelection ||
                      (!formFields?.paperBillSelection && !formFields?.digitalBillSelection)
                      ? 'disabled'
                      : '',
                    (formFields?.paperBillSelection || formFields?.digitalBillSelection) &&
                      formFields?.documentIds === null
                      ? 'disabled'
                      : '',
                    isMandatoryFieldEmpty() && 'disabled'
                  )}
                  data-testid="submitBtn"
                  onClick={() => formSubmit()}
                  disabled={formFields?.thirdPartyBooking || formSubmitTriggered || isMandatoryFieldEmpty()}
                >
                  {authorModel?.submitCtaButtonLabel}
                </button>
              )}
              {formFields?.faxOrMailSelection && (
                <button
                  className={clsx(
                    'print',
                    'btn',
                    'm-button-m',
                    'm-button-primary',
                    formFields?.thirdPartyBooking || isMandatoryFieldEmpty() ? 'disabled' : ''
                  )}
                  data-testid="generatePrintBtn"
                  disabled={formFields?.thirdPartyBooking || isMandatoryFieldEmpty()}
                  onClick={() => setIsPrint(true)}
                >
                  {authorModel?.generatePrintableFormLabel}
                </button>
              )}
            </div>
          </div>
        </div>
      </StyledMissingStayRequestForm>
    </>
  );
};

export const MissingStayRequestFormConfig = {
  emptyLabel: 'missingstayrequestform',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/missingstayrequestform`,
};

export const MissingStayRequestFormEditable = (props: any) => (
  <EditableComponent config={MissingStayRequestFormConfig} {...props}>
    <MissingStayRequestForm {...props} />
  </EditableComponent>
);

export const constants = {
  ROOM_FEATURES_CODES: ['CR', 'RB', 'EP', 'EF', 'FP', 'FF'],
  STAY_CODES: ['EC'],
  BATHROOM_CODES: ['ET'],
  HOUSEKEEPING_OPT_IN: 'HA',
  HOUSEKEEPING_CODES: ['HD', 'HA'],
  HOUSEKEEPING_OPT_OUT: 'HD',
  MARRIOTT_BRAND_CODE: 'MC',
  GROUP_CODE_LENGHT: 10,
  GROUP_CODE_MIN_LENGHT: 3,

  // Housekeeping codes
  HK_EXCLUDED_BRAND_LIST: ['ER', 'MV', 'RZ', 'EB', 'WH', 'LC', 'XR', 'JW', 'BG'],

  // brands that need to redirected to the landing page instead of the reviews page
  HWSLandingPageRedirectionBrands: ['RZ', 'MV', 'DS', 'BG'],

  // Room Preferences Codes
  PREFERENCES_EXCEPTION_CODE: ['S5', 'D7', 'D9', 'N8', 'U2', 'W5', 'UR', 'M2'],
  ROOM_LOCATIONS_CODES: ['H1', 'L2', 'NE'],
  FLOOR_CODES: ['L2', 'H1'],

  ADDITIONAL_RF: 'RF',

  // Payment Codes
  PLUS_PAYMENT_METHOD_CODE_TYPE: 'SpecialIndustryProgram',
  PAYMENT_METHOD_CODE_TYPE_DEPOSITE: ['Cash', 'PrePay', 'Deposit'],
  PAYMENT_METHOD_CODE_TYPE_GUARANTEE: [
    'DirectBill',
    'Voucher',
    'CreditCard',
    'TravelAgencyIATANumber',
    'FrequentGuestNumber',
    'SpecialIndustryProgram',
    'CompanyNameOrAddress',
    'CorporateIDOrCDNumber',
    'OtherInformation',
    'PaymentManager',
  ],
  DEFAULT_PMT: 'CreditCard',

  HOTEL_ICON_CODES: {
    HOTEL_SERVICES: 'SA',
    HOTEL_HIGHLIGHTS: 'HH',
    PARKING: 'PT',
    HOUSEKEEPING: '10',
  },

  // New Error Framework
  ACRS_ERROR_CODES: {
    E_ARRIVAL_FORMAT: 'E_ARRIVAL_FORMAT',
    E_BAD_CONF_NO: 'E_BAD_CONF_NO',
    E_ALREADY_CANCELLED: 'E_ALREADY_CANCELLED',
    E_CHANGED_HOTEL: 'E_CHANGED_HOTEL',
    E_GNR_DATE_FORMAT: 'E_GNR_DATE_FORMAT',
    E_MOD_DISALLOW: 'E_MOD_DISALLOW',
    E_BAD_HSSMMP_ID: 'E_BAD_HSSMMP_ID',
    E_BAD_IATAN_VER: 'E_BAD_IATAN_VER',
    E_TOO_MANY_NIGHTS: 'E_TOO_MANY_NIGHTS',
    E_BAD_IATA: 'E_BAD_IATA',
    E_NO_CERTS_LEFT: 'E_NO_CERTS_LEFT',
    E_MAX_BOOKINGS: 'E_MAX_BOOKINGS',
    E_MAX_BOOK_ARRIVAL: 'E_MAX_BOOK_ARRIVAL',
    E_DOUBLE_CLICK: 'E_DOUBLE_CLICK',
    E_REPEATED_HOTEL: 'E_REPEATED_HOTEL',
    E_FRAUD_ALERT: 'E_FRAUD_ALERT',
    profileNotFoundInMarshaErrorText: 'profileNotFoundInMarshaErrorText',
    travelAgentUnauthorizedErrorText: 'travelAgentUnauthorizedErrorText',
    ACS042: 'invalidTravelDatesErrorText',
    RCM042: 'invalidTravelDatesErrorText',
    RCM082: 'invalidPmtIdErrorText',
    invalidPrefPlusErrorText: 'invalidPrefPlusErrorText',
    RCM066: 'invalidCreditCardErrorText',
    RCM071: 'expiredCardErrorText',
    RCM068: 'expiredCardErrorText',
    ASP068: 'expiredCardErrorText',
    RCM064: 'invalidMaxGuestErrorText',
    ASP064: 'invalidMaxGuestErrorText',
    maglevDomainRejErrorText: 'maglevDomainRejErrorText',
    invalidMaglevTripsErrorText: 'invalidMaglevTripsErrorText',
    invalidMaglevErrorText: 'invalidMaglevErrorText',
    invalidGuestAccErrorText: 'invalidGuestAccErrorText',
    invalidTravelAccErrorText: 'invalidTravelAccErrorText',
    'invalid.cart.id': 'invalid.cart.id',
    AP10077: 'AP10077',
    AP10071: 'AP10071',
    redemptionInsufficientPoints: 'redemptionInsufficientPoints',
    'ORD-RDS-RCM053': 'ORD-RDS-RCM053',
    AP10076: 'AP10076',
    AP10078: 'AP10078',
    enrollmentFailure: 'enrollmentFailure',
    duplicateEmail: 'duplicateEmail',
    duplicatePhone: 'duplicatePhone',
    matchKeyError: 'matchKeyError',
    'mrprogram.matchkey.error': 'mrprogram.matchkey.error',
    'conf.res.link.failure.enroll.matchkeyerror.msg': 'conf.res.link.failure.enroll.matchkeyerror.msg',
    bookingError: 'bookingError',
    graphQlError: 'graphQlError',
    redemptionerror: 'redemptionerror',
    isadultonlyerror: 'isadultonlyerror',
    restrictModification: 'restrictModification',
    userErrorCode: 'userErrorCode',
    profileNotFound: 'profileNotFound',
    MAX_GUEST_COUNT_ERROR: 'guestCountError',
    AVAILIBILITY_ERROR: 'availabilityError',
    WARNING: 'warning',
    ERROR: 'ERROR',
    'rooms.guests': 'rooms.guests',
    ORD_NOT_FOUND: 'ORD-SYS-9009',
  },
};
